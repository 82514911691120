// React
import React, { useState} from 'react';

// Design
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';


// Internal
import ChangePauseDialog from './changePasswordDialog';
import createAccess from '../../utils/functions/createAccess';
import API from '../../utils/api';
import { Box, DialogContent } from '@mui/material';
import { setNotificationBar } from '../../store/app/slice';

// Third-party
import { useDispatch } from 'react-redux';

export default function ProfileSupervisorDialog({ 
  open,
  setOpen,
  user
}) {
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const dispatch = useDispatch();
  

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePassword = (newPassword) => {
    let access_click2chat = createAccess(user);

    API.put.updatePassword(access_click2chat, newPassword)
      .then((response) => {
          if (response.status === 202) {
            dispatch(setNotificationBar({status: true, message: 'Senha alterada' , type: 'success'}))
          } 
          else {
            let err = new Error('not possible');
            throw err;
          }
      })
      .catch((err) => {
        dispatch(setNotificationBar({status: true, message: 'Erro ao tentar alterar a senha' , type: 'error'}))
      })

    setChangePasswordOpen(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Gerenciamento de Perfil
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box
            display="flex"
            sx={{
              height: '100%',
              width: '100%',
            }}
          >
            <Grid
              container
              justifyContent='space-evenly'
            >
              <Grid item>
                <Button 
                  variant="outlined"
                  onClick={() => setChangePasswordOpen(true)}
                >
                  Alterar Senha
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      {
        changePasswordOpen &&
        <ChangePauseDialog
          open={changePasswordOpen}
          setOpen={setChangePasswordOpen}
          handleChangePassword={handleChangePassword}
        />
      }
    </div>
  );
}

