// React
import React, { useState } from 'react';

// Design
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { 
  TextField, 
  Toolbar, 
  Typography, 
  Dialog, 
  IconButton, 
  AppBar,
  Box,
  Tooltip,
} from '@mui/material';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';


// Internal
import API from '../../utils/api';
import createAccess from '../../utils/functions/createAccess';
import { 
  appUser, 
} from '../../store/app/slice';

// Third-Party
import { useSelector } from 'react-redux';


const useStyles = makeStyles((theme) => ({
  appBar: {
    // position: 'absolute',
    marginBottom: '70px'
  },
  title: {
    // marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const columns = [
  // { field: 'id', headerName: 'ID', width: 90, sortable: false },
  { field: 'date', headerName: 'Date', width: 250, sortable: false },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    editable: false,
    sortable: false
  },
  {
    field: 'username',
    headerName: 'Usuário',
    width: 300,
    editable: false,
    sortable: false
  },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


export default function ClockPunchDialog({ 
  open, 
  setOpen,
  username,
  operatorId,
}) {

  const user = useSelector(appUser);

 
  let cD = new Date();
  let _hour = cD.getHours();
  cD.setHours(_hour - 3);
  const classes = useStyles();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [statusList, setStatusList] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeDateStart = (newValue) => {
    setStartDate(newValue);
  };

  const handleChangeDateEnd = (newValue) => {
    setEndDate(newValue);
  };

  // eslint-disable-next-line
  const getReport = () => {
    let dates = {
      startDate: (new Date(startDate)).setHours(0, 0, 0, 0),
      endDate: (new Date(endDate)).setHours(23, 59, 59, 59)
    }
    let access_click2chat = createAccess(user);
    let id = operatorId;
    const options = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric',
      hour12: false,
      timeZone: 'America/Sao_Paulo'
    };

    API.get.clockPunchOperatorSupervision(access_click2chat, id, JSON.stringify(dates))
        .then((response) => {
          if (response.status === 200) {
            let STATUS = {
              'login': 'Login',
              'logoff': 'Logout',
              'entered pause': 'Entrou em Pausa',
              'leave pause': 'Saiu da Pausa'
            }
            let _statusList = response.data.info.map((el, count) => {
              let allStatus = el;
              el.id = count;
              el.status = STATUS[el.status];
              el.username = username;
              el.date = Intl.DateTimeFormat('pt-BR',  options).format(new Date(el.date));
              return allStatus
            })
            setStatusList(_statusList)
          }
          else {
            setStatusList([])
          }
        })
        .finally(() => {
            // setOperatorEditDialogShow(false);
        })
  }

  console.log({statusList})
  return (
    <div>
      <Dialog 
        open={open} 
        onClose={handleClose} 
        // TransitionComponent={Transition}
        fullScreen
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Horários - {username}
            </Typography>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: 'flex',
            height: 'calc(100% - 70px)',
            width: '100%',
            marginTop: '120px',
            flexDirection: 'column'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              height: 'calc(100hv - 70px)',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Início"
                inputFormat="dd/MM/yyyy"
                value={startDate}
                onChange={handleChangeDateStart}
                renderInput={(params) => <TextField {...params} />}
              />
              <DesktopDatePicker
                label="Fim"
                inputFormat="dd/MM/yyyy"
                value={endDate}
                onChange={handleChangeDateEnd}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Tooltip title="Procurar">
              <span>
                <IconButton 
                    size="small"
                    // disabled={filter.length < 3}
                    onClick={getReport}
                >
                    <SearchIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: 'flex',
              overflowY: 'auto',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignContent: 'center'
            }}
          >
            <div style={{ height: '100%', width: '50%' }}>
              <DataGrid
                rows={statusList}
                columns={columns}
                pageSize={30}
                rowsPerPageOptions={[30]}
                // checkboxSelection
                disableSelectionOnClick
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </div>
          </Box>
        </Box>


    </Dialog>
  </div>
  );
}