// React
import React, { useEffect, useState, useRef } from "react";

// Design
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

// Internal
import API from "../../utils/api";
import createAccess from "../../utils/functions/createAccess";

// Third-party

// const columns = [
//   // { id: 'blocked', label: 'Status', minWidth: 100 },
//   // { id: 'username', label: 'Username', minWidth: 100 },
//   // { id: 'online', label: 'Online', minWidth: 100 },
//   // { id: 'team', label: 'Time', minWidth: 100 },
//   // { id: 'line', label: 'Na Fila?', minWidth: 100 },
//   // { id: 'line_count', label: 'Fila', minWidth: 100 },
//   // { id: 'active_chats_count', label: 'Chats Abertos', minWidth: 100 },
//   // { id: 'scheduled_count', label: 'Agenda', minWidth: 100 },
//   // { id: 'effectiveness', label: 'Efetividade', minWidth: 50},
// ];

const columns = [
  // { field: 'id', headerName: 'ID', width: 90, sortable: false },
  // { field: 'date', headerName: 'Date', width: 250, sortable: false },
  {
    field: "blocked",
    headerName: "Status",
    width: 80,
    editable: false,
    sortable: true,
  },
  {
    field: "username",
    headerName: "Usuário",
    width: 200,
    editable: false,
    sortable: true,
  },
  {
    field: "full_name",
    headerName: "Nome Completo",
    width: 200,
    editable: false,
    sortable: true,
  },
  {
    field: "online",
    headerName: "Online",
    width: 100,
    editable: false,
    sortable: true,
  },
  {
    field: "_team_name",
    headerName: "Time",
    width: 200,
    editable: false,
    sortable: true,
  },
  {
    field: "line",
    headerName: "Na Fila?",
    width: 80,
    editable: false,
    sortable: true,
  },
  {
    field: "paused",
    headerName: "Em Pausa?",
    width: 125,
    editable: false,
    sortable: true,
  },
  {
    field: "line_count",
    headerName: "Fila de Entrada",
    width: 125,
    editable: false,
    sortable: true,
  },
  {
    field: "active_chats_count",
    headerName: "Chats Abertos",
    width: 120,
    editable: false,
    sortable: true,
  },
  {
    field: "closed_chats_today",
    headerName: "Chats Encerrados",
    width: 140,
    editable: false,
    sortable: true,
  },
  {
    field: "total_started_chats_today",
    headerName: "Chats Iniciados",
    width: 140,
    editable: false,
    sortable: true,
  },
  {
    field: "response_time",
    headerName: "TMR",
    width: 80,
    editable: false,
    sortable: true,
  },
  {
    field: "message_size",
    headerName: "Tam. Méd. das Msgs.",
    width: 150,
    editable: false,
    sortable: true,
  },
  // {
  //   field: "scheduled_count",
  //   headerName: "Agenda",
  //   width: 80,
  //   editable: false,
  //   sortable: true,
  // },
  {
    field: "effectiveness",
    headerName: "Efetividade",
    width: 100,
    editable: false,
    sortable: true,
  },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function StatusOperatorsTable({ handleSelectOperator, user }) {
  const [operatorsListRequest, _setOperatorsListRequest] = useState([]);
  const operatorsListRequestRef = useRef([]);
  const setOperatorsListRequest = (newValue) => {
    let sortedArray = newValue || [];
    sortedArray.sort((a, b) => b.effectiveness_value - a.effectiveness_value);
    _setOperatorsListRequest(sortedArray);
    operatorsListRequestRef.current = sortedArray;
  };
  // const evaluation = {
  //   'bad':
  //   <Tooltip title="Ruim">
  //     <Typography variant="h5">
  //       😞
  //     </Typography>
  //   </Tooltip>,
  //   'mean':
  //   <Tooltip title="Razoável">
  //     <Typography variant="h5">
  //       😐
  //     </Typography>
  //   </Tooltip>,
  //   'good':
  //   <Tooltip title="Bom">
  //     <Typography variant="h5">
  //       🙂
  //     </Typography>
  //   </Tooltip>,
  //   'excellent':
  //   <Tooltip title="Excelente">
  //     <Typography variant="h5">
  //       😄
  //     </Typography>
  //   </Tooltip>,
  // }
  const evaluation = {
    bad: "😞",
    mean: "😐",
    good: "🙂",
    excellent: "😄",
  };

  const onOff = {
    true: "🟢",
    false: "🔴",
  };

  const timeToUpdate = 10000;

  const handleGetInfo = () => {
    let access_click2chat = createAccess(user);
    console.log("access_click2chat", access_click2chat);
    API.get
      .operatorsStatus(access_click2chat)
      .then((response) => {
        console.log({ response });
        if (response.status === 200) {
          let _statusList = response.data.info.map((el, count) => {
            let allStatus = el;
            el._blocked = el.blocked;
            el._online = el.online;
            el._line = el.line;
            el.blocked = onOff[String(el.blocked)];
            el.online = onOff[String(el.online)];
            el.line = onOff[String(el.line)];
            el.id = el.username;
            el._team_name =
              el.team_name === "default" ? "Padrão" : el.team_name;
            el.effectiveness = evaluation[el.effectiveness];
            el.closed_chats_today = el.closed_chats_today || 0;
            el.total_received_chats_today = el.total_started_chats_today || 0;
            el.paused = onOff[String(el.paused)];
            el.response_time = el.response_time || "00:00:00";
            el.message_size = el.message_size || 0;
            el.message_size = parseInt(el.message_size);
            return allStatus;
          });
          setOperatorsListRequest(_statusList);
        } else {
          //   setChatsListRequest([])
        }
      })
      .catch((error) => {
        console.log({ error });
      })
      .finally(() => {
        // setOperatorEditDialogShow(false);
        console.log("finally");
      });
  };

  useEffect(() => {
    console.log("oxe");
    handleGetInfo();
    const interval = setInterval(() => {
      handleGetInfo();
    }, timeToUpdate);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [user]);
  console.log({ operatorsListRequest });

  return (
    <>
      <div style={{ height: "50vh", width: "100%", cursor: "pointer" }}>
        <DataGrid
          rows={operatorsListRequest}
          columns={columns}
          pageSize={30}
          rowsPerPageOptions={[30]}
          // checkboxSelection
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
          }}
          onCellClick={handleSelectOperator}
        />
      </div>
    </>
  );
}
