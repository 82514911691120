// React
import React, { useEffect, useState } from 'react';

// Design
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Tooltip } from '@mui/material';

// Internal
import AddNoteDialog from './addNoteDialog';
import EditNoteDialog from './editNoteDialog';
import { deleteNote, appUser, appOperation } from '../../store/app/slice';

// Third-Party
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    width: 800,
  },
  container: {
    maxHeight: 600,
  },
}));


const columns = [
  { id: 'title', label: 'Título', minWidth: 170 },
  { id: 'text', label: 'Texto', minWidth: 170 }
];

function StickyHeadTable({ notes, setSelectedNote, handleDeleteNote, operation}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {notes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                      const value = row[column.id];
                      const worker = row?.worker ?? 'operator';
                      let editionNotPossible = !operation?.operatorsCanEditNotes;
                      if (worker === 'supervisor') {
                        editionNotPossible = true;
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <Grid
                            container
                          >
                            <Grid
                              item
                              xs={9}
                            >
                              {column.format && typeof value === 'number' ? column.format(value) : value}
                            </Grid>
                            <Grid 
                              item
                            >
                            {column.id === 'text' && !editionNotPossible &&
                              <Grid
                                container
                              >
                                <Grid
                                  item
                                >
                                  <Tooltip title="Editar Nota" placement="bottom-end">
                                    <IconButton 
                                      size="medium"
                                      onClick={() => setSelectedNote(row['id'])}
                                      disabled={!row['id']}
                                    >
                                      <EditIcon fontSize="inherit" />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                <Grid
                                  item
                                >
                                  <Tooltip title="Apagar Nota" placement="right-end">
                                    <IconButton 
                                      size="medium"
                                      disabled={!row['id']}
                                      onClick={() => handleDeleteNote(row['id'])}
                                    >
                                      <DeleteIcon fontSize="inherit" />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            }
                            </Grid>
                          </Grid>

                        </TableCell>
                      );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        // rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={notes.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default function NotesDialog({
  open, 
  setOpen, 
  notes,
}) {
  
  const dispatch = useDispatch();
  const user = useSelector(appUser);
  const operation = useSelector(appOperation);
  const classes = useStyles();
  const [addNoteDialogShow, setAddNoteDialogShow] = useState(false);
  const [editNoteDialogShow, setEditNoteDialogShow] = useState(false);
  const [selectedNote, setSelectedNote] = useState('');
  

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteNote = (id) => {
    dispatch(deleteNote({
      access_click2chat: user,
      id: id,
    }))
  }

  useEffect(() => {
    setSelectedNote('');
  }, [open]);

  useEffect(() => {
    if (selectedNote) {
      setEditNoteDialogShow(true);
    } else {
      setEditNoteDialogShow(false);
    }
  }, [selectedNote])

  useEffect(() => {
    if(!editNoteDialogShow) {
      setSelectedNote('');
    }
  }, [editNoteDialogShow])

  return (
    <div>
      <Dialog open={open} maxWidth='xl' onClose={handleClose} >
          <Toolbar>
            {
              operation?.operatorsCanEditNotes &&
              <Tooltip title="Adicionar Nota" placement="bottom-end">         
                <IconButton 
                  size="medium"
                  onClick={() => setAddNoteDialogShow(true)} 
                  edge="start"
                >
                  <AddIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            }

            <Typography variant="h6" className={classes.title}>
              Editar Notas
            </Typography>
            <Tooltip title="Fechar Janela" placement="left-start">
              <IconButton edge="end" color="default" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        <StickyHeadTable 
          notes={notes}
          setSelectedNote={setSelectedNote}
          handleDeleteNote={handleDeleteNote}
          operation={operation}
        />
        
      </Dialog>
      {
        addNoteDialogShow && 
          <AddNoteDialog 
            open={addNoteDialogShow}
            setOpen={setAddNoteDialogShow}
          />
        }

      {
        editNoteDialogShow && selectedNote &&
          <EditNoteDialog 
            open={editNoteDialogShow}
            setOpen={setEditNoteDialogShow}
            note={notes.find(el => el.id === selectedNote)}
          />
        }
    </div>
  );
}

