// React
import React, { useEffect, useState } from "react";

// Design
import { makeStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import { Box } from "@mui/system";

// Internal
import API from "../../utils/api";
import { appUser } from "../../store/app/slice";
import createAccess from "../../utils/functions/createAccess";

// Third-Party
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

export default function NextOperatorDialog({
  open,
  setOpen,
  phone_number,
  _handlePutNextOperator,
  _handlePutNextSupervisor,
}) {
  const classes = useStyles();
  const user = useSelector(appUser);
  const [operatorsList, setOperatorsList] = useState([]);
  const [supervisionList, setSupervisionList] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  // /chats/operators/list
  const getOperatorsList = () => {
    if (user.worker === "operator") {
      API.get.operatorsList(createAccess(user)).then((response) => {
        if (response.status === 200) {
          let _oL = response.data.info.filter((el) => el.worker === "operator");
          let _sL = response.data.info.filter(
            (el) => el.worker === "supervisor"
          );
          setOperatorsList(_oL);
          setSupervisionList(_sL);
        } else {
          setOperatorsList([]);
        }
      });
    } else if (user.worker === "supervisor") {
      API.get
        .nextOperatorListSupervision(createAccess(user))
        .then((response) => {
          if (response.status === 200) {
            let _oL = response.data.info.filter(
              (el) => el.worker === "operator"
            );
            let _sL = response.data.info.filter(
              (el) => el.worker === "supervisor"
            );
            setOperatorsList(_oL);
            setSupervisionList(_sL);
          } else {
            setOperatorsList([]);
          }
        });
    }
  };

  const handlePutNextOperator = (operator_id) => {
    if (user.worker === "operator") {
      API.put
        .nextOperator(createAccess(user), operator_id, phone_number)
        .then(() => {})
        .finally(handleClose);
    } else if (user.worker === "supervisor") {
      _handlePutNextOperator(operator_id);
      handleClose();
    }
  };

  const handlePutNextSupervisor = (supervisor_id) => {
    if (user.worker === "operator") {
      API.put
        .nextSupervisor(createAccess(user), supervisor_id, phone_number)
        .then(() => {})
        .finally(handleClose);
    } else if (user.worker === "supervisor") {
      _handlePutNextSupervisor(supervisor_id);
      handleClose();
    }
  };

  useEffect(() => {
    if (open) {
      getOperatorsList();
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Encaminhar cliente para operador:
      </DialogTitle>
      {operatorsList.length > 0 ? (
        <List>
          {operatorsList.map((operator) => (
            <ListItem
              button
              key={operator.id}
              onClick={() => handlePutNextOperator(operator.id)}
            >
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={operator.username}
                secondary={operator?.team_name}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography>😅Parece que só tem você online.😅</Typography>
        </Box>
      )}
      <DialogTitle id="simple-dialog-title">
        Encaminhar cliente para supervisor:
      </DialogTitle>
      {supervisionList.length > 0 ? (
        <List>
          {supervisionList.map((supervisor) => (
            <ListItem
              button
              key={supervisor.id}
              onClick={() => handlePutNextSupervisor(supervisor.id)}
            >
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={supervisor.username} />
            </ListItem>
          ))}
        </List>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography>😅Parece que não tem supervisor online.😅</Typography>
        </Box>
      )}
    </Dialog>
  );
}
