// React
import React, { useState, useEffect } from "react";

// Design
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplayIcon from "@mui/icons-material/Replay";
import { Box } from "@mui/system";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { green, yellow, red } from "@mui/material/colors";

// Internal
import { appOperation } from "../../store/app/slice";

// Third-Party
import Calendar from "react-calendar";
import { useSelector } from "react-redux";
import "./scheduleDialogCalendar.css";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    // marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ScheduleDialog({
  open,
  setOpen,
  schedule,
  setOpenReminderDialog,
  handleResendReminder,
  handleDeleteReminder,
}) {
  const classes = useStyles();
  const [filteredScheduleSent, setFilteredScheduleSent] = useState([]);
  const [filteredScheduleNotSent, setFilteredScheduleNotSent] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [calendarDate, setCalendarDate] = useState(null);
  const [datesThatHaveReminders, setDatesThatHaveReminders] = useState([]);

  const manageStatus = (checked, exists) => {
    const _status = {
      "not-verified": (
        <Tooltip title="Não verificado">
          <IconButton size="small">
            <WarningIcon
              style={{
                color: yellow[500],
              }}
            />
          </IconButton>
        </Tooltip>
      ),
      verified: (
        <Tooltip title="Verificado">
          <IconButton size="small">
            <CheckCircleIcon
              style={{
                color: green[500],
              }}
            />
          </IconButton>
        </Tooltip>
      ),
      // 3: <DoneAllIcon color="primary" />,
      "does-not-exist": (
        <Tooltip title="Não existe">
          <IconButton size="small">
            <ErrorIcon
              style={{
                color: red[500],
                fontSize: "20px",
              }}
            />
          </IconButton>
        </Tooltip>
      ),
    };
    if (checked) {
      if (exists) return _status["verified"];
      else return _status["does-not-exist"];
    } else {
      return _status["not-verified"];
    }
  };

  const operation = useSelector(appOperation);

  const handleSelectDate = (value) => {
    setSelectedDate(value);
    setCalendarDate(value);
  };

  const formatDate = (_date) => {
    _date = String(_date)
      .replace(":00 GMT-0300 (Horário Padrão de Brasília)", "")
      .replace("Sun", "Domingo,")
      .replace("Mon", "Segunda-feira,")
      .replace("Tue", "Terça-feira,")
      .replace("Wed", "Quarta-feira,")
      .replace("Thu", "Quinta-feira,")
      .replace("Fri", "Sexta-feira,")
      .replace("Sat", "Sábado,")
      .replace("Jan", "Janeiro")
      .replace("Feb", "Fevereiro")
      .replace("Mar", "Março")
      .replace("Apr", "Abril")
      .replace("May", "Maio")
      .replace("Jun", "Junho")
      .replace("Jul", "Julho")
      .replace("Aug", "Agosto")
      .replace("Sep", "Setembro")
      .replace("Oct", "Outubro")
      .replace("Nov", "Novembro")
      .replace("Dec", "Dezembro");
    return _date;
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      setSelectedDate(String(new Date()));
      function translateXPaths(translation, path) {
        if (
          document.evaluate(
            path,
            document,
            null,
            XPathResult.FIRST_ORDERED_NODE_TYPE,
            null
          ).singleNodeValue
        ) {
          document.evaluate(
            path,
            document,
            null,
            XPathResult.FIRST_ORDERED_NODE_TYPE,
            null
          ).singleNodeValue.innerHTML = translation;
        }
        return;
      }

      function translateAndReplaceMonth(en, pt) {
        const path =
          "/html/body/div[5]/div[3]/div/div/div[1]/div/div[1]/button[3]/span";
        if (
          document.evaluate(
            path,
            document,
            null,
            XPathResult.FIRST_ORDERED_NODE_TYPE,
            null
          ).singleNodeValue
        ) {
          document.evaluate(
            path,
            document,
            null,
            XPathResult.FIRST_ORDERED_NODE_TYPE,
            null
          ).singleNodeValue.innerHTML = document
            .evaluate(
              path,
              document,
              null,
              XPathResult.FIRST_ORDERED_NODE_TYPE,
              null
            )
            .singleNodeValue.innerHTML.replace(en, pt);
        }
        return;
      }
      const WEEKDAYS = {
        Seg: "/html/body/div[5]/div[3]/div/div/div[1]/div/div[2]/div/div/div/div[1]/div[1]/abbr",
        Ter: "/html/body/div[5]/div[3]/div/div/div[1]/div/div[2]/div/div/div/div[1]/div[2]/abbr",
        Qua: "/html/body/div[5]/div[3]/div/div/div[1]/div/div[2]/div/div/div/div[1]/div[3]/abbr",
        Qui: "/html/body/div[5]/div[3]/div/div/div[1]/div/div[2]/div/div/div/div[1]/div[4]/abbr",
        Sex: "/html/body/div[5]/div[3]/div/div/div[1]/div/div[2]/div/div/div/div[1]/div[5]/abbr",
        Sáb: "/html/body/div[5]/div[3]/div/div/div[1]/div/div[2]/div/div/div/div[1]/div[6]/abbr",
        Dom: "/html/body/div[5]/div[3]/div/div/div[1]/div/div[2]/div/div/div/div[1]/div[7]/abbr",
      };
      const MONTHS = {
        Janeiro: "January",
        Fevereiro: "February",
        Março: "March",
        Abril: "April",
        Maio: "May",
        Junho: "June",
        Julho: "July",
        Agosto: "August",
        Setembro: "September",
        Outubro: "October",
        Novembro: "November",
        Dezembro: "December",
      };

      Object.entries(WEEKDAYS).forEach(([translation, path]) => {
        translateXPaths(translation, path);
      });

      Object.entries(MONTHS).forEach(([pt, en]) => {
        translateAndReplaceMonth(en, pt);
      });
      // console.log(getElementByXpath());

      let dates = schedule
        .filter((el) => !el.sent)
        .map((el) => {
          return new Date(el.date);
        });
      // format date to only get yyyy-mm-dd, respecting the timezone
      dates = dates.map((el) => {
        // reset to 0
        el.setHours(0, 0, 0, 0);
        return String(el);
      });

      let uniqueDatesString = dates.filter((v, i, a) => a.indexOf(v) === i);
      uniqueDatesString = uniqueDatesString.map((el) => {
        return new Date(el);
      });
      setDatesThatHaveReminders(uniqueDatesString);
    }
  }, [open, schedule]);

  useEffect(() => {
    let filterSent = schedule.filter((el) => {
      if (
        new Date(selectedDate).getDate() === new Date(el.date).getDate() &&
        el.sent
      ) {
        return el;
      } else {
        return false;
      }
    });
    let filterNotSent = schedule.filter((el) => {
      if (
        new Date(selectedDate).getDate() === new Date(el.date).getDate() &&
        !el.sent
      ) {
        return el;
      } else {
        return false;
      }
    });
    setFilteredScheduleSent(filterSent);
    setFilteredScheduleNotSent(filterNotSent);
    // console.log({ filterNotSent })
    // eslint-disable-next-line
  }, [selectedDate]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        fullScreen
      >
        <AppBar className={classes.appBar}>
          <Toolbar
            sx={{
              backgroundColor: "#505050",
            }}
          >
            <Tooltip
              title="Fazer Agendamento"
              placement="right-end"
              style={{ zIndex: 99999999 }}
            >
              <IconButton
                size="medium"
                disabled={
                  operation?.blockActive ??
                  operation?.maxOpenedChats >=
                    operation?.chatsLists?.all?.length ??
                  false
                }
                onClick={() => setOpenReminderDialog(true)}
                edge="start"
              >
                <AddIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" className={classes.title}>
              Agendamentos
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: "flex",
            height: "calc(100% - 70px)",
            width: "100%",
            marginTop: "70px",
            backgroundColor: "#BDBDBD",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "500px",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <Calendar
              onChange={(value) => handleSelectDate(value)}
              value={calendarDate}
            />
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "50px",
                // backgroundColor: "black",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>Dias com agendamentos:</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                // backgroundColor: "black",
                flexGrow: 1,
                flexDirection: "column",
                // justifyContent: "center",
                alignItems: "center",
                overflowY: "auto",
              }}
            >
              {datesThatHaveReminders.map((el, index) => {
                // console.log({ el, s: el.toDateString() });
                return (
                  <Box
                    key={`date-${index}`}
                    sx={{
                      display: "flex",
                      width: "70%",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor:
                        String(selectedDate) === String(new Date(el))
                          ? "#006edc"
                          : "white",
                      color:
                        String(selectedDate) === String(new Date(el))
                          ? "white"
                          : "black",
                      padding: "5px",
                      margin: "5px",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSelectDate(new Date(el))}
                  >
                    {formatDate(el.toDateString())}
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
            }}
          >
            <Typography>Pendentes</Typography>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                overflowY: "scroll",
                height: "100%",
              }}
            >
              {filteredScheduleNotSent.length > 0 && (
                <List>
                  {filteredScheduleNotSent.map((el, index) => (
                    <ListItem key={`schedule-${index}`}>
                      {el?.files?.length > 0 ? (
                        <Tooltip
                          title={el?.files?.length}
                          placement="bottom-end"
                        >
                          <AttachmentIcon />
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      <ListItemText
                        primary={`${formatDate(
                          new Date(el.date)
                        )} - ${el.phone_number.replace("@c.us", "")}`}
                        secondary={`${el.msg}`}
                      />
                      {el.whatsapp_checked && !el.whatsapp_exists ? null : (
                        <Tooltip
                          title="Apagar Agendamento"
                          placement="bottom-end"
                        >
                          <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => handleDeleteReminder(String(el._id))}
                            aria-label="close"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {manageStatus(el.whatsapp_checked, el.whatsapp_exists)}
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              flexGrow: 2,
              width: "100%",
            }}
          >
            <Typography>Enviados</Typography>
            <Box
              sx={{
                display: "flex",
                overflowY: "scroll",
                width: "100%",
                height: "100%",
              }}
            >
              {filteredScheduleSent.length > 0 && (
                <List>
                  {filteredScheduleSent.map((el, index) => (
                    <ListItem key={`schedule-${index}`}>
                      {el?.files?.length > 0 ? (
                        <Tooltip
                          title={el?.files?.length}
                          placement="bottom-end"
                        >
                          <AttachmentIcon />
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      <ListItemText
                        primary={`${formatDate(
                          new Date(el.date)
                        )} - ${el.phone_number.replace("@c.us", "")}`}
                        secondary={`${el.msg}`}
                      />
                      <Tooltip
                        title="Reenviar Agendamento"
                        placement="left-start"
                      >
                        <IconButton
                          edge="end"
                          color="inherit"
                          onClick={() => handleResendReminder(String(el._id))}
                          aria-label="close"
                        >
                          <ReplayIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
