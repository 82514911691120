// React
import React from 'react';

// Design
import { Card, CardContent, IconButton } from '@mui/material';
import { Tooltip, Typography } from '@mui/material';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ErrorIcon from '@mui/icons-material/Error';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ReplayIcon from '@mui/icons-material/Replay';
import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { Box } from '@mui/material';
import HeadsetIcon from '@mui/icons-material/Headset';
// Internal
// import downloadURI from '../../utils/functions/downloadURI';

// Third-party

const manageStatus = (status) => {
  const _status = {
    line: (
      <Tooltip title="Fila">
        <DirectionsRunIcon />
      </Tooltip>
    ),
    error: (
      <Tooltip title="Não Enviado">
        <ErrorIcon color="error" />
      </Tooltip>
    ),
    browser: (
      <Tooltip title="Está no browser">
        <OpenInBrowserIcon />
      </Tooltip>
    ),
    whisper: (
      <Tooltip title="Sussurro">
        <HeadsetIcon />
      </Tooltip>
    ),
    0: (
      <Tooltip title="Não Enviado">
        <QueryBuilderIcon />
      </Tooltip>
    ),
    1: (
      <Tooltip title="Enviado">
        <CheckIcon />
      </Tooltip>
    ),
    2: (
      <Tooltip title="Recebido">
        <DoneAllIcon />
      </Tooltip>
    ),
    // 3: <DoneAllIcon color="primary" />,
    3: (
      <Tooltip title="Visualizado">
        <DoneAllIcon
          style={{
            color: '#34B7F1',
          }}
        />
      </Tooltip>
    ),
    4: (
      <Tooltip title="Aberto">
        <DoneAllIcon
          style={{
            color: '#34B7F1',
          }}
        />
      </Tooltip>
    ),
  };
  if (!Object.keys(_status).includes(String(status))) return _status[0];
  return _status[status];
};

const options = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
  timeZone: 'America/Sao_Paulo',
};

export default function MessageCard({
  message,
  user,
  index,
  chatId,
  phoneNumber,
  handleSendMessage,
  handleSendFile,
  handleLazyDownload,
  handleLazyOpen,
  files,
  worker,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        // justifyContent: message?.fromMe ? 'flex-end' : 'flex-start',
        justifyContent:
          message.fromMe & (message?.type !== 'whisper')
            ? 'flex-end'
            : message.fromMe & (message?.type === 'whisper')
            ? 'center'
            : 'flex-start',
        paddingRight:
          message.fromMe & (message?.type !== 'whisper')
            ? '0.313rem'
            : message.fromMe & (message?.type === 'whisper')
            ? '0.313rem'
            : '0',
        // paddingRight: message?.fromMe ? '0.313rem' : '0',
        marginTop: '0.5vh',
      }}
    >
      <Card
        key={index}
        style={{
          width: '40rem',
          backgroundColor:
            message.fromMe & (message?.type !== 'whisper')
              ? '#075E54'
              : message.fromMe & (message?.type === 'whisper')
              ? '#730039'
              : '#283b3d',
          marginLeft:
            message.fromMe & (message?.type !== 'whisper')
              ? '0'
              : message.fromMe & (message?.type === 'whisper')
              ? '0.313rem'
              : '0.313rem',
          // marginLeft: message.fromMe ? '0' : '0.313rem',
        }}
      >
        <Box
          display="flex"
          sx={{
            // flexDirection: 'column',
            width: '100%',
            height: '1rem',
            justifyContent: 'flex-start',
          }}
        >
          <Box display="flex" sx={{ marginLeft: '.5rem', marginTop: '.25rem' }}>
            <Typography variant="caption" color="white">
              {message.fromMe ? message.operator_id : user}
            </Typography>
          </Box>
          {message?._data?.quotedMsg?.body && (
            <Box
              display="flex"
              sx={{
                // flexDirection: 'column',
                // width: '80%',
                height: '1rem',
                flexGrow: 1,
                justifyContent: 'flex-end',
              }}
            >
              <Tooltip title={message?._data?.quotedMsg?.body}>
                <Typography variant="caption">
                  <FormatQuoteIcon />
                </Typography>
              </Tooltip>
            </Box>
          )}
        </Box>

        <CardContent>
          {message.hasMedia &&
          (message?.media_id || message?.metadata?.media_id) &&
          worker === 'supervisor' ? (
            <Typography>
              {`${
                message?.body?.filename ??
                message?._data?.filename ??
                message?.body
              }`}
              <Tooltip
                title={`Baixar ${
                  message?.body?.filename ??
                  message?._data?.filename ??
                  message?.body
                }`}
              >
                <IconButton
                  onClick={() =>
                    handleLazyDownload(
                      message?.media_id ?? message?.metadata?.media_id,
                      message.mediaKey
                    )
                  }
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={`Abrir ${
                  message?.body?.filename ??
                  message?._data?.filename ??
                  message?.body
                }`}
              >
                <IconButton
                  onClick={() =>
                    handleLazyOpen(
                      message?.media_id ?? message?.metadata?.media_id,
                      message.mediaKey
                    )
                  }
                >
                  <OpenInNewIcon />
                </IconButton>
              </Tooltip>
            </Typography>
          ) : message.hasMedia &&
            (message?.media_id || message?.metadata?.media_id) ? (
            <Typography color="#D3D3D3">
              {`${
                message?.body?.filename ??
                message?._data?.filename ??
                message?.body
              }`}
              <Tooltip
                title={`Baixar ${
                  message?.body?.filename ??
                  message?._data?.filename ??
                  message?.body
                }`}
              >
                <IconButton
                  onClick={() =>
                    handleLazyDownload(
                      message?.media_id ?? message?.metadata?.media_id,
                      message.mediaKey
                    )
                  }
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={`Abrir ${
                  message?.body?.filename ??
                  message?._data?.filename ??
                  message?.body
                }`}
              >
                <IconButton
                  onClick={() =>
                    handleLazyOpen(
                      message?.media_id ?? message?.metadata?.media_id,
                      message.mediaKey
                    )
                  }
                >
                  <OpenInNewIcon />
                </IconButton>
              </Tooltip>
            </Typography>
          ) : (
            <span>
              <Typography color="#D3D3D3">
                {message?.body?.filename ??
                  message?._data?.filename ??
                  message?.body}
              </Typography>
            </span>
          )}
          <Box
            display="flex"
            sx={{
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <Typography variant="caption" color="#D3D3D3">
              {Intl.DateTimeFormat('pt-BR', options).format(
                new Date(message?.datestamp)
              )}
              |{phoneNumber}
              {manageStatus(message.ack)}
              {message.ack === 'error' && message.fromMe ? (
                ![
                  'file',
                  'image',
                  'document',
                  'audio',
                  'voice',
                  'video',
                  'sticker',
                  'ptt',
                ].includes(message.type) ? (
                  <Tooltip
                    title="Reenviar Mensagem"
                    style={{ cursor: 'pointer' }}
                  >
                    <ReplayIcon
                      onClick={() => handleSendMessage(chatId, message.body)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Reenviar Arquivo"
                    style={{ cursor: 'pointer' }}
                  >
                    <ReplayIcon
                      onClick={() =>
                        handleSendFile({
                          chat_id: chatId,
                          filename: '',
                          resend: true,
                          file_id: message.media_id,
                        })
                      }
                    />
                  </Tooltip>
                )
              ) : (
                ''
              )}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
