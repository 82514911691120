// React
import React, { useState } from 'react';

// Design

// Internal
import NotificationBar from '../../components/Feedback/notification';
import SupervisionBar from './Components/supervisionBar';
import ProfileSupervisorDialog from '../../components/Dialogs/profileSupervisorDialog';
import { 
  appUser, 
} from '../../store/app/slice';

// Third-party
import { useSelector } from 'react-redux';

export default function Supervision() {
  const [profileSupervisorDialogOpen, setProfileSupervisorDialogOpen] = useState(false);
  const user = useSelector(appUser);

  return (
    <>
      <NotificationBar />
      <SupervisionBar
        setProfileSupervisorDialogOpen={setProfileSupervisorDialogOpen}
      />
      {
        profileSupervisorDialogOpen && 
        <ProfileSupervisorDialog
            open={profileSupervisorDialogOpen}
            setOpen={setProfileSupervisorDialogOpen}
            user={user}
        />
      }
    </>
  );
}
