// React
import React from 'react';

// Design
import { Box } from '@mui/material';

// Internal
import ChatCard from '../../../../../components/Cards/chatCard';
import { appSupervision } from "../../../../../store/app/slice";

// Third-party
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useSelector } from 'react-redux';

export default function FilteredList({
    selectedChat,
    setSelectedChat,
}) {

  const _appSupervision = useSelector(appSupervision);

    
  const Row = ({ index, style }) => (
    <div style={style} key={`interaction_list-${index}`}>
      <ChatCard
        index={index}
        selectedChatID={selectedChat && selectedChat.id}
        listToUse={_appSupervision?.filteredChatsList}
        setSelectedChat={setSelectedChat}
      />
    </div>
  );

  return (
      <main style={{height: '100%'}}>
            {
              _appSupervision?.filteredChatsList?.length > 0 && _appSupervision?.filterType && _appSupervision?.filterKeyWord ? 
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    className="List"
                    height={height}
                    itemCount={_appSupervision?.filteredChatsList?.length}
                    itemSize={110}
                    width={width}
                  >
                    {Row}
                  </List>
                )}
              </AutoSizer>
              :
              <Box
                display="flex"
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                Nenhuma conversa encontrada
              </Box>
            }
      </main>
  )
}
