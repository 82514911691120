// React
import React, { useEffect, useState, useRef } from 'react';

// Design
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import green from '@mui/material/colors/green';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';


// Internal
import CloseReasonDialogList from '../Dialogs/closeReasonDialogList';
import API from '../../utils/api';
import createAccess from '../../utils/functions/createAccess';

// Third-party

const columns = [
  { id: 'reason', label: 'Tabulação', minWidth: 100 },
  { id: 'qtt', label: 'Quantidade', minWidth: 100 }
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    height: '20rem',
  },
});

export default function ClosedReasonsTable({ user }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  // eslint-disable-next-line
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [closeReasonDialogShow, setCloseReasonDialogShow] = useState(false);
  const [closedReasonListRequest, _setClosedReasonListRequest] = useState([]);
  const closedReasonListRequestRef = useRef([]);
  const setClosedReasonListRequest = (newValue) => {
    let sortedArray = newValue || [];
    sortedArray.sort((a, b) => b.qtt - a.qtt);
    _setClosedReasonListRequest(sortedArray);
    closedReasonListRequestRef.current = sortedArray;
  }

  const [totalCount, _setTotalCount] = useState([]);
  const totalCountRef = useRef([]);
  const setTotalCount = (newValue) => {
    _setTotalCount(newValue);
    totalCountRef.current = newValue;
  }

  const timeToUpdate = 60000 * 1 * 3;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleGetInfo = (totalCount) => {
    let access_click2chat = createAccess(user);
    let filterLess = new Date();
    let filter = `${filterLess.getFullYear()}-${String(filterLess.getMonth()+1).length === 1 ? '0': ''}${filterLess.getMonth()+1}-${String(filterLess.getDate()).length === 1 ? '0': ''}${filterLess.getDate()}`

    API.get.closedReasonsConsolidation(access_click2chat, filter)
      .then((response) => {
            if (response.status === 200) {
                let newClosedReasonList = [];
                let setup = {}
                let _totalCount = 0;
                response.data.info.closed_reasons_list.forEach((el) => {
                  setup[el.id] = {
                    qtt: 0,
                    reason: el.reason
                  }
                })
                
                let temp = response.data.info.list_closed;
                Object.keys(temp).forEach(el => {
                  setup[el].qtt += temp[el];
                  _totalCount += temp[el];
                })
                newClosedReasonList = Object.keys(setup).map(el => {
                  return {
                    reason: setup[el].reason,
                    qtt: setup[el].qtt
                  }
                })
                if (_totalCount !== totalCountRef.current) {
                  setTotalCount(_totalCount);
                  setClosedReasonListRequest(newClosedReasonList);
                }
            }
            else {

            }
      })
  }

  useEffect(() => {
    handleGetInfo(totalCount)
    const interval =  setInterval(() => {
                                        handleGetInfo(totalCountRef.current);
                                      }, timeToUpdate);

    return () => {
      clearInterval(interval);
    };
  // eslint-disable-next-line
  }, []);


  return (
    <>
      <TableContainer className={classes.container}>
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                  {column.id === 'reason' ? 
                    <IconButton
                      onClick={() => setCloseReasonDialogShow(!closeReasonDialogShow)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    : ''
                  }
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {closedReasonListRequest.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      typeof value === 'boolean' ?
                        <TableCell key={column.id} align={column.align}>
                          {value ? <FiberManualRecordIcon style={{color: green[500]}}/> : <FiberManualRecordIcon color="error" />}
                        </TableCell>
                      :
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={closedReasonListRequest.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />

      {
        closeReasonDialogShow &&
        <CloseReasonDialogList
          open={closeReasonDialogShow}
          setOpen={setCloseReasonDialogShow}
          user={user}
        />
      }
    </>
  );
}