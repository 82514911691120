// React
import React from "react";

// Design
import { Box, Typography } from "@mui/material";

// Internal
import ChatCard from "../../../components/Cards/chatCard";

// Third-party
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

export default function ChatsList({
  chatsList,
  selectedChat,
  setSelectedChat,
}) {
  const Row = ({ index, style }) => {
    return (
      <div style={style} key={`interaction_list-${index}`}>
        <ChatCard
          index={index}
          selectedChatID={selectedChat && selectedChat.id}
          listToUse={chatsList}
          setSelectedChat={setSelectedChat}
          picture={chatsList[index].profile_pic_url}
        />
        {/* {chatsList[index].phone_number} */}
      </div>
    );
  };

  return (
    <main
      style={{
        height: "inherit",
        // backgroundColor: '#505050'
        backgroundImage: 'url("/images/background-whatsapp-7.jpg")',
      }}
    >
      {chatsList.length > 0 ? (
        // false ?
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="List"
              height={height}
              itemCount={chatsList.length}
              itemSize={110}
              width={width}
            >
              {Row}
            </List>
          )}
        </AutoSizer>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography color="#d3d3d3">Nenhuma conversa encontrada</Typography>
        </Box>
      )}
    </main>
  );
}
