// React
import React, { useState } from 'react';

// Design
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';

// Internal
import EmojiDialog from '../Interactions/emojiDialog';
import { putNote, appUser } from '../../store/app/slice';

// Third-party
import { useSelector, useDispatch } from 'react-redux';


export default function EditNoteDialog({open, setOpen, note}) {
  
  const dispatch = useDispatch();
  const user = useSelector(appUser);
  
  const [title, setTitle] = useState(note.title);
  const [text, setText] = useState(note.text);
  
  const [emojiDialogShow, setEmojiDialogShow] = useState(false);
  const [emojiFor, setEmojiFor] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const handlePutNote = () => {
    setOpen(false)
    dispatch(putNote({
      access_click2chat: user,
      title: title,
      text: text,
      id: note.id
    }))
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Adicionar Nota</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Título"
            type="name"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            required      
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="Emoticon" placement="left-end">
                    <IconButton 
                      onClick={() => {setEmojiFor('title');setEmojiDialogShow(true)}}
                    >
                      <EmojiEmotionsIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="dense"
            id="text"
            label="Texto"
            type="name"
            value={text}
            onChange={(e) => setText(e.target.value)}
            fullWidth
            multiline
            rows={10}
            required            
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="Emoticon" placement="left-end">
                    <IconButton 
                      onClick={() => {setEmojiFor('text');setEmojiDialogShow(true)}}
                    >
                      <EmojiEmotionsIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button 
            onClick={handlePutNote} 
            color="primary"
            disabled={!title || !text}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      {
        emojiDialogShow && 
        <EmojiDialog 
          setTextToSend={emojiFor === 'text' ? setText : setTitle}
          textToSend={emojiFor === 'text' ? text : title}
          open={emojiDialogShow}
          setOpen={setEmojiDialogShow}
        />
      }
    </div>
  );
}