// React
import React, { useEffect, useState, useRef } from "react";

// Design
import { Box, Typography } from "@mui/material";

// Internal
import API from "../../utils/api";
import createAccess from "../../utils/functions/createAccess";

// Third-party
import { ResponsivePie } from "@nivo/pie";

export default function CurrentStatusChatsGraph({ user }) {
  const timeToUpdate = 60000 * 1 * 1;
  // eslint-disable-next-line
  const [labels, setLabels] = useState([
    "Agendada",
    "Cliente",
    "Robô",
    "Operador",
    "Encaminhada",
    "Sussurro",
  ]);
  const [totalCount, _setTotalCount] = useState([]);
  const totalCountRef = useRef([]);
  const setTotalCount = (newValue) => {
    _setTotalCount(newValue);
    totalCountRef.current = newValue;
  };
  const [scheduledStatus, setScheduledStatus] = useState({
    id: "Agendada",
    label: "scheduled",
    value: 0,
    color: "#ffea00",
  });
  const [yesStatus, setYesStatus] = useState({
    id: "Cliente",
    label: "yes",
    value: 0,
    color: "#ff1744",
  });
  const [robotStatus, setRobotStatus] = useState({
    id: "Robô",
    label: "robot",
    value: 0,
    color: "#009688",
  });
  const [noStatus, setNoStatus] = useState({
    id: "Operador",
    label: "no",
    value: 0,
    color: "#2196f3",
  });
  const [forwardedStatus, setForwardedStatus] = useState({
    id: "Encaminhada",
    label: "forwarded",
    value: 0,
    color: "#9c27b0",
  });
  const [whisperStatus, setWhisperStatus] = useState({
    id: "Sussurro",
    label: "whisper",
    value: 0,
    color: "#730039",
  });

  const data = [
    scheduledStatus,
    yesStatus,
    robotStatus,
    noStatus,
    forwardedStatus,
    whisperStatus,
  ];

  const handleGetInfo = (totalCount) => {
    let access_click2chat = createAccess(user);

    API.get.currentStatusChats(access_click2chat).then((response) => {
      if (response.status === 200) {
        let _scheduledStatus = 0;
        let _yesStatus = 0;
        let _robotStatus = 0;
        let _noStatus = 0;
        let _forwardedStatus = 0;
        let _whisperStatus = 0;

        let _totalCount = 0;
        let temp = response.data.info;
        _scheduledStatus = temp.find((el) => el._id === "scheduled")
          ? temp.find((el) => el._id === "scheduled").count
          : 0;
        _yesStatus = temp.find((el) => el._id === "yes")
          ? temp.find((el) => el._id === "yes").count
          : 0;
        _robotStatus = temp.find((el) => el._id === "robot")
          ? temp.find((el) => el._id === "robot").count
          : 0;
        _noStatus = temp.find((el) => el._id === "no")
          ? temp.find((el) => el._id === "no").count
          : 0;
        _forwardedStatus = temp.find((el) => el._id === "forwarded")
          ? temp.find((el) => el._id === "forwarded").count
          : 0;
        _whisperStatus = temp.find((el) => el._id === "whisper")
          ? temp.find((el) => el._id === "whisper").count
          : 0;

        _totalCount =
          _scheduledStatus +
          _yesStatus +
          _robotStatus +
          _noStatus +
          _forwardedStatus +
          _whisperStatus;

        // if (_totalCount !== totalCountRef.current) {
        // setLabels(Object.keys(response.data.info.list_messages));
        setTotalCount(_totalCount);

        setScheduledStatus({ ...scheduledStatus, value: _scheduledStatus });
        setYesStatus({ ...yesStatus, value: _yesStatus });
        setRobotStatus({ ...robotStatus, value: _robotStatus });
        setNoStatus({ ...noStatus, value: _noStatus });
        setForwardedStatus({ ...forwardedStatus, value: _forwardedStatus });
        setWhisperStatus({ ...whisperStatus, value: _whisperStatus });
        // }
      } else {
        //   setChatsListRequest([])
      }
    });
  };

  useEffect(() => {
    handleGetInfo(totalCount);
    const interval = setInterval(() => {
      handleGetInfo(totalCountRef.current);
    }, timeToUpdate);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      display={"flex"}
      height={"450px"}
      width={"450px"}
      flexDirection={"column"}
    >
      <Typography variant={"h6"} textAlign={"center"}>
        Último status das conversas abertas
      </Typography>
      <ResponsivePie
        data={data}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
        colors={{ datum: "data.color" }}
        margin={{ top: 40, right: 80, bottom: 80, left: 100 }}
      />
    </Box>
  );
}
