// React
import React, { useState, useEffect } from 'react';

// Design
import { Box } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import { Tooltip, FormControl, InputAdornment, Input, Stack } from '@mui/material';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';

// Internal
import EmojiDialog from '../Interactions/emojiDialog';
// import { appOperation } from '../../store/app/slice';

// Third-party
// import { useSelector } from 'react-redux';

export default function SendWhisperBox({
    handleSendWhisper,
    chatId,
}) {
    const [textToSend, setTextToSend] = useState('');
    const [textToSendFlag, setTextToSendFlag] = useState(false);
    const [emojiDialogShow, setEmojiDialogShow] = useState(false);
    // const operation = useSelector(appOperation);


    const handleSendText = () => {
        // if (messageType === 'external') {
        //     handleSendMessage(chatId, textToSend, false, '', operation?.team?.teamId, operation?.team?.teamName);
        // }
        // else if (messageType === 'internal') {
        //     handleSendMessage(chatId, textToSend);
        // }
        handleSendWhisper(chatId, textToSend);
        setTextToSend('');
    };


    const handleEnterPress = (e) => {
        if (e.which === 13 && e.shiftKey) {

        }
        else if (e.which === 13 && !textToSendFlag) {
            e.preventDefault(); //Stops enter from creating a new line
            handleSendText();
        }
    };

    useEffect(() => {
        setTextToSendFlag(Array.from(textToSend).every(el => el === '\n'));
    }, [textToSend]);

    useEffect(() => {
        setTextToSend('');
    }, [chatId])

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    direction: 'column',
                    height: '8rem',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        width: '50%',
                        background: '#BDBDBD',
                        justifyContent: 'center',
                    }}
                >
                    <FormControl fullWidth>
                        <Input
                            required
                            autoFocus
                            multiline
                            rows={4}
                            // row={2}
                            id="input-with-icon-adornment-search"
                            startAdornment={
                                
                                <InputAdornment position="end">
                                    <Stack>
                                        <Tooltip title="Emojis" placement="top-end">
                                            <span>
                                                <IconButton
                                                    onClick={() => setEmojiDialogShow(true)}
                                                >
                                                    <span role="img" aria-label="emoji">😀</span>
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </Stack>
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="start">
                                    <Stack>
                                        <Tooltip title="Enviar Mensagem" placement="top-end">
                                            <span>
                                                <IconButton
                                                    onClick={() => handleSendText()}
                                                    disabled={textToSendFlag}
                                                >
                                                    <PermPhoneMsgIcon />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </Stack>
                                </InputAdornment>
                            }
                            value={textToSend}
                            onChange={(e) => setTextToSend(e.target.value)}
                            onKeyPress={(e) => handleEnterPress(e)}
                        />
                    </FormControl>
                </Box>
            </Box>
            {
                emojiDialogShow &&
                <EmojiDialog
                    setTextToSend={setTextToSend}
                    textToSend={textToSend}
                    open={emojiDialogShow}
                    setOpen={setEmojiDialogShow}
                />
            }
        </>
    );
}