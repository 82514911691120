// React
import React, { useEffect, useState } from "react";

// Design
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// Internal
import API from "../../utils/api";
import createAccess from "../../utils/functions/createAccess";

// Third-party

// const options = {
//   year: "numeric",
//   month: "numeric",
//   day: "numeric",
//   hour: "numeric",
//   minute: "numeric",
//   hour12: false,
//   timeZone: "America/Sao_Paulo",
// };

export default function FunnelMassiveGraph({ user }) {
  const timeToUpdate = 60000 * 1 * 3;
  const [massiveFunnel, setMassiveFunnel] = useState(null);
  const [massiveConsolidation, setMassiveConsolidation] = useState(null);
  const [massiveAnswers, setMassiveAnswers] = useState(0);

  // eslint-disable-next-line

  const handleGetInfo = () => {
    let access_click2chat = createAccess(user);
    let filterLess = new Date();
    let filter = `${filterLess.getFullYear()}-${
      String(filterLess.getMonth() + 1).length === 1 ? "0" : ""
    }${filterLess.getMonth() + 1}-${
      String(filterLess.getDate()).length === 1 ? "0" : ""
    }${filterLess.getDate()}`;

    API.get.dayCampaignsMassive(access_click2chat, filter).then((response) => {
      if (response.status === 200) {
        let funnel = response.data.info.consolidatedInfo;
        let consolidation =
          response.data.info?.campaignResultConsolidated ?? [];
        let answers = response.data.info?.massiveAnswers ?? 0;
        setMassiveFunnel(funnel);
        setMassiveConsolidation(consolidation);
        setMassiveAnswers(answers);
      } else {
        //   setChatsListRequest([])
      }
    });
  };

  useEffect(() => {
    handleGetInfo();
    const interval = setInterval(() => {
      handleGetInfo();
    }, timeToUpdate);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingLeft: "1rem",
      }}
    >
      <Typography textAlign={"center"} textTransform="uppercase">
        <strong>Funil Massivo (somatória de hoje)</strong>
      </Typography>
      {massiveFunnel && (
        <>
          <Typography>
            <strong>Total de campanhas:</strong> {massiveFunnel?.totalCampaigns}
          </Typography>
          <Typography>
            <strong>IDS:</strong>{" "}
            {massiveFunnel?.campaignsIds.map((el) => el).join(", ")}
          </Typography>
          <hr />
          <Typography>
            <strong>Quantidade de telefones:</strong> {massiveFunnel?.totalSize}
          </Typography>
          <Typography>
            <strong>Quantidade de mensagens enviadas:</strong>{" "}
            {massiveConsolidation?.find((el) => el._id === null)?.count ?? 0} (
            {(
              ((massiveConsolidation?.find((el) => el._id === null)?.count ??
                0) /
                massiveFunnel?.totalSize) *
              100
            ).toFixed(2)}
            %)
          </Typography>
          <Typography>
            <strong>Quantidade de mensagens recebidas:</strong>{" "}
            {massiveConsolidation?.find((el) => el._id === 2)?.count ?? 0} (
            {(
              ((massiveConsolidation?.find((el) => el._id === 2)?.count ?? 0) /
                (massiveConsolidation?.find((el) => el._id === null)?.count ??
                  0)) *
              100
            ).toFixed(2)}
            %)
          </Typography>
          <Typography>
            <strong>Quantidade de mensagens lidas:</strong>{" "}
            {massiveConsolidation?.find((el) => el._id === 3)?.count ?? 0} (
            {(
              ((massiveConsolidation?.find((el) => el._id === 3)?.count ?? 0) /
                (massiveConsolidation?.find((el) => el._id === 2)?.count ??
                  0)) *
              100
            ).toFixed(2)}
            %)
          </Typography>
          <Typography>
            <strong>Quantidade de mensagens respondidas:</strong>{" "}
            {massiveAnswers} (
            {(
              (massiveAnswers /
                (massiveConsolidation?.find((el) => el._id === 3)?.count ??
                  0)) *
              100
            ).toFixed(2)}
            %)
          </Typography>
          <hr />
          <Typography sx={{ color: "red" }}>
            <strong>Telefones com conversa aberta na hora do envio:</strong>{" "}
            {massiveFunnel?.openedChats ?? 0}
          </Typography>
          <Typography sx={{ color: "red" }}>
            <strong>Mensagens não enviadas:</strong>{" "}
            {massiveFunnel?.totalSize -
              (massiveFunnel?.openedChats ?? 0) -
              (massiveConsolidation?.find((el) => el._id === null)?.count ?? 0)}
          </Typography>
          <Typography sx={{ color: "red" }}>
            <strong>Mensagens não recebidas:</strong>{" "}
            {(massiveConsolidation?.find((el) => el._id === null)?.count ?? 0) -
              (massiveConsolidation?.find((el) => el._id === 2)?.count ?? 0)}
          </Typography>
        </>
      )}
    </Box>
  );
}
