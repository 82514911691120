// React
import * as React from 'react';

// Design
import { styled, alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Tooltip, Typography } from '@mui/material';
import AddAlertOutlinedIcon from '@mui/icons-material/AddAlertOutlined';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventIcon from '@mui/icons-material/Event';
import NotesIcon from '@mui/icons-material/Note';
import { Badge } from '@mui/material';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import { green } from '@mui/material/colors';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import StarIcon from '@mui/icons-material/Star';
import ForwardIcon from '@mui/icons-material/Forward';
import AndroidIcon from '@mui/icons-material/Android';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PauseIcon from '@mui/icons-material/Pause';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import HowToRegIcon from '@mui/icons-material/HowToReg';

// Internal
import { appUser, signOut, appOperation, setSelectedChatList, setSearching, appInternal } from '../../../store/app/slice';
import API from '../../../utils/api';
import createAccess from '../../../utils/functions/createAccess';
import InternalChatDialog from '../../../components/Dialogs/internalChatDialog';


// Thirdy-Party
import { useSelector, useDispatch } from 'react-redux';
import Headset from '@mui/icons-material/Headset';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));


const useStyles = makeStyles((theme) => ({
  customBadge: {
    backgroundColor: green[500],
    color: "white"
  }
}));

export default function OperationBar({
  setReminderDialogShow,
  setNotesDialogShow,
  setScheduleDialogShow,
  setPauseDialogShow,
  setProfileOperatorDialogOpen,
  setSearchForContactDialogShow,
  setContactsPerDateDialogShow,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const user = useSelector(appUser);
  const operation = useSelector(appOperation);
  const [usersInternalCommunication, setUsersInternalCommunication] = React.useState([]);
  const [internalChatDialogOpen, setInternalChatDialogOpen] = React.useState(false);

  const _appInternal = useSelector(appInternal);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleOpenProfile = () => {
    handleMenuClose()
    setProfileOperatorDialogOpen(true)
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };


  const handleInternalCommunicationOpen = () => {
    let access_click2chat = createAccess(user);
    API.get.internalCommunicationUsers(access_click2chat)
      .then((response) => {
        setUsersInternalCommunication(response?.data?.users ?? []);
      })
      .catch(console.log)
      .finally(() => {
        setInternalChatDialogOpen(true)

      })
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={handleOpenProfile}
      // disabled={true}
      >
        Perfil
      </MenuItem>
      <MenuItem onClick={() => dispatch(signOut(user))}>Sair</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Tooltip title="Agendar" placement="left-start">
          <span>
            <IconButton
              // disabled={currentStatusOperator !== 'start'}
              onClick={() => setReminderDialogShow(true)}
              disabled={operation?.blockActive ?? operation?.maxOpenedChats >= operation?.chatsLists?.all?.length ?? false}
            >
              <AddAlertOutlinedIcon />
            </IconButton>
          </span>
        </Tooltip>
        <p>Agendar</p>
      </MenuItem>
      <MenuItem>
        <Tooltip title="Procurar por Contato" placement="left-start">
          <span>
            <IconButton
              // disabled={currentStatusOperator !== 'start'}
              onClick={() => setSearchForContactDialogShow(true)}
            // disabled={true}
            >
              <EventAvailableIcon />
            </IconButton>
          </span>
        </Tooltip>
        <p>Procurar Por Contato</p>
      </MenuItem>
      <MenuItem>
        <Tooltip title="Procurar por Contato" placement="left-start">
          <span>
            <IconButton
              // disabled={currentStatusOperator !== 'start'}
              onClick={() => setSearchForContactDialogShow(true)}
            // disabled={true}
            >
              <EventAvailableIcon />
            </IconButton>
          </span>
        </Tooltip>
        <p>Procurar Por Contato Por Data</p>
      </MenuItem>
      <MenuItem>
        <Tooltip title="Agendamentos" placement="left-start">
          <span>
            <IconButton
              // disabled={currentStatusOperator !== 'start'}
              onClick={() => setContactsPerDateDialogShow(true)}
            // disabled={true}
            >
              <EventAvailableIcon />
            </IconButton>
          </span>
        </Tooltip>
        <p>Agendamentos</p>
      </MenuItem>
      <MenuItem>
        <Tooltip title="Notas" placement="left-start">
          <span>
            <IconButton
              // disabled={currentStatusOperator !== 'start'}
              onClick={() => setNotesDialogShow(true)}
            >
              <NotesIcon />
            </IconButton>
          </span>
        </Tooltip>
        <p>Notas</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Perfil</p>
      </MenuItem>
    </Menu>
  );

  const _setSearching = (text) => {
    dispatch(setSearching({
      status: text ? true : false,
      text: text.toLowerCase(),
    }))
  }
  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar sx={{ backgroundColor: '#505050' }}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Pesquisar..."
              inputProps={{ 'aria-label': 'search' }}
              value={operation.searchedWord}
              onChange={(e) => _setSearching(e.target.value)}
            />
          </Search>
          <Box sx={{ display: { xs: 'none', sm: 'flex', md: 'flex' } }}>
          
            <Tooltip title="Fila" placement="top-start">
              <span>
                <IconButton
                  disabled={(operation.selectedChatList === 'queue' || operation.referenceList === 'queue') || operation.chatsLists.queue.length === 0}
                  onClick={() => { dispatch(setSelectedChatList('queue')) }}
                  size="large"
                  color="inherit"
                >
                  <Badge badgeContent={operation.chatsLists && operation.chatsLists.queue.length} color="error">
                    <DirectionsRunIcon
                      fontSize={(operation.selectedChatList === 'queue' || operation.referenceList === 'queue') ? 'medium' : "small"}
                    />
                  </Badge>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Nova Interação" placement="top-start">
              <span>
                <IconButton
                  size="large"
                  color="inherit"
                  disabled={(operation.selectedChatList === 'new' || operation.referenceList === 'new') || operation.chatsLists.new.length === 0}
                  onClick={() => { dispatch(setSelectedChatList('new')) }}

                >
                  <Badge color="error" badgeContent={operation.chatsLists && operation.chatsLists.new.length}>
                    <NewReleasesIcon
                      fontSize={(operation.selectedChatList === 'new' || operation.referenceList === 'new') ? 'medium' : "small"}
                    />
                  </Badge>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Sussurro" placement="top-start">
              <span>
                <IconButton
                  disabled={(operation.selectedChatList === 'whisper' || operation.referenceList === 'whisper') || operation.chatsLists.whisper.length === 0}
                  onClick={() => { dispatch(setSelectedChatList('whisper')) }}
                  size="large"
                  color="inherit"
                >
                  <Badge badgeContent={operation.chatsLists && operation.chatsLists.whisper.length} color="warning">
                    <Headset
                      fontSize={(operation.selectedChatList === 'whisper' || operation.referenceList === 'whisper') ? 'medium' : "small"}
                    />
                  </Badge>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Robô" placement="top-start">
              <span>
                <IconButton
                  disabled={(operation.selectedChatList === 'bot' || operation.referenceList === 'bot') || operation.chatsLists.bot.length === 0}
                  onClick={() => { dispatch(setSelectedChatList('bot')) }}
                  size="large"
                  color="inherit"
                >
                  <Badge badgeContent={operation.chatsLists && operation.chatsLists.bot.length} color="warning">
                    <AndroidIcon
                      fontSize={(operation.selectedChatList === 'bot' || operation.referenceList === 'bot') ? 'medium' : "small"}
                    />
                  </Badge>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Encaminhadas para mim" placement="top-start">
              <span>
                <IconButton
                  disabled={(operation.selectedChatList === 'forward' || operation.referenceList === 'forward') || operation.chatsLists.forward.length === 0}
                  onClick={() => { dispatch(setSelectedChatList('forward')) }}
                  size="large"
                  color="inherit"
                >
                  <Badge badgeContent={operation.chatsLists && operation.chatsLists.forward.length} color="warning">
                    <ForwardIcon
                      fontSize={(operation.selectedChatList === 'forward' || operation.referenceList === 'forward') ? 'medium' : "small"}
                    />
                  </Badge>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Agendamentos" placement="top-start">
              <span>
                <IconButton
                  disabled={(operation.selectedChatList === 'schedule' || operation.referenceList === 'schedule') || operation.chatsLists.schedule.length === 0}
                  onClick={() => { dispatch(setSelectedChatList('schedule')) }}
                  size="large"
                  color="inherit"
                >
                  <Badge badgeContent={operation.chatsLists && operation.chatsLists.schedule.length} color="warning">
                    <EventAvailableIcon
                      fontSize={(operation.selectedChatList === 'schedule' || operation.referenceList === 'schedule') ? 'medium' : "small"}
                    />
                  </Badge>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Favoritos" placement="top-start">
              <span>
                <IconButton
                  disabled={(operation.selectedChatList === 'star' || operation.referenceList === 'star') || operation.chatsLists.star.length === 0}
                  onClick={() => { dispatch(setSelectedChatList('star')) }}
                  size="large"
                  color="inherit"
                >
                  <Badge
                    badgeContent={operation.chatsLists && operation.chatsLists.star.length} classes={{ badge: classes.customBadge }}>
                    <StarIcon
                      fontSize={(operation.selectedChatList === 'star' || operation.referenceList === 'star') ? 'medium' : "small"}
                    />
                  </Badge>
                </IconButton>
              </span>
            </Tooltip>
            
            <Tooltip title="Sem novas interações" placement="top-start">
              <span>
                <IconButton
                  onClick={() => { dispatch(setSelectedChatList('no')) }}
                  disabled={(operation.selectedChatList === 'no' || operation.referenceList === 'no') || operation.chatsLists.no.length === 0}
                  size="large"
                  color="inherit"
                >
                  <Badge badgeContent={operation.chatsLists && operation.chatsLists.no.length} classes={{ badge: classes.customBadge }}>
                    <AssignmentTurnedInIcon
                      fontSize={(operation.selectedChatList === 'no' || operation.referenceList === 'no') ? 'medium' : "small"}
                    />
                  </Badge>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Todas as Interações" placement="top-start">
              <span>
                <IconButton
                  onClick={() => { dispatch(setSelectedChatList('all')) }}
                  disabled={(operation.selectedChatList === 'all' || operation.referenceList === 'all') || operation.chatsLists.all.length === 0}
                  size="large"
                  color="inherit"
                >
                  <Badge badgeContent={operation.chatsLists && operation.chatsLists.all.length} color="info">
                    <AllInboxIcon
                      fontSize={(operation.selectedChatList === 'all' || operation.referenceList === 'all') ? 'medium' : "small"}
                    />
                  </Badge>
                </IconButton>
              </span>
            </Tooltip>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Box>
              <Typography>
                {user.username}
              </Typography>
              <Typography variant="caption">
                {user.company} {operation?.team?.teamName && operation?.team?.teamName === 'default' ? '(Padrão)' : `(${operation?.team?.teamName})`}
              </Typography>
            </Box>
            <Tooltip title="Iniciar Conversa/Agendar" placement="left-start">
              <span>
                <IconButton
                  // disabled={currentStatusOperator !== 'start'}
                  disabled={operation?.blockActive ?? operation?.maxOpenedChats >= operation?.chatsLists?.all?.length ?? false}
                  onClick={() => setReminderDialogShow(true)}
                  size="large"
                  color="inherit"
                >
                  <AddBoxOutlinedIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Contatos por data" placement="left-start">
              <span>
                <IconButton
                  // disabled={currentStatusOperator !== 'start'}
                  // disabled={operation?.blockActive ?? operation?.maxOpenedChats >= operation?.chatsLists?.all?.length ?? false}
                  onClick={() => setContactsPerDateDialogShow(true)}
                  size="large"
                  color="inherit"
                >
                  <HowToRegIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Procurar contato" placement="left-start">
              <span>
                <IconButton
                  // disabled={currentStatusOperator !== 'start'}
                  disabled={operation?.blockChatSearch ?? false}
                  onClick={() => setSearchForContactDialogShow(true)}
                  size="large"
                  color="inherit"
                >
                  <PermContactCalendarIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Agendamentos" placement="left-start">
              <span>
                <IconButton
                  onClick={() => setScheduleDialogShow(true)}
                  size="large"
                  color="inherit"
                >
                  <EventIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Gerenciar Notas" placement="left-start">
              <span>
                <IconButton
                  // disabled={currentStatusOperator !== 'start'}
                  onClick={() => setNotesDialogShow(true)}
                  size="large"
                  color="inherit"
                >
                  <NotesIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Pausa" placement="left-start">
              <span>
                <IconButton
                  // disabled={currentStatusOperator !== 'start'}
                  onClick={() => setPauseDialogShow(true)}
                  size="large"
                  color="inherit"
                >
                  <PauseIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title="Conversas Internas">
              <IconButton
                onClick={handleInternalCommunicationOpen}
                color="inherit"
                disabled={operation?.blockInternalChat ?? false}
              >
                <Badge color="primary" badgeContent={_appInternal?.newChatsCounting ?? 0}>
                  <ChatIcon />
                </Badge>
              </IconButton>
            </Tooltip>

            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {
        internalChatDialogOpen &&
        <InternalChatDialog
          open={internalChatDialogOpen}
          setOpen={setInternalChatDialogOpen}
          usersInternalCommunication={usersInternalCommunication}
        />
      }
    </Box>
  );
}