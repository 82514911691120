// React
import React from 'react';

// Design
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Internal

// Third-party

export default function PullFromLineDialog({ 
  open, 
  setOpen, 
  username, 
  handlePullFromLine,
}) {

  const handleClose = () => {
    setOpen(false);
  };

  const handlePullFromLineAndClose = () => {
    handlePullFromLine();
    setOpen(false);
  }


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Retirar o operador da fila?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {username}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Não Retirar
          </Button>
          <Button onClick={handlePullFromLineAndClose} color="secondary" autoFocus>
            Retirar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}