// React
import React, {useEffect} from "react";

// Design

// Internal

// Third-party



export default function Home () {

  useEffect(() => {
    // eslint-disable-next-line
    // redirect to HereWeCode
    window.location.href = "https://clickservices.com.br";
  }, [])

    return (
    <div>
      Redirecionando...
    </div>
    )
}