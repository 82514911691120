const openURI = (uri) => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = uri;
    link.rel= "noopener noreferrer";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export default openURI;