// React
import React, { useState } from "react";

// Design
import DirectionsRailwayIcon from "@mui/icons-material/DirectionsRailway";
import ForwardIcon from "@mui/icons-material/Forward";
import CloseIcon from "@mui/icons-material/Close";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import HeadsetOffIcon from "@mui/icons-material/HeadsetOff";
// import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import ContactMailIcon from "@mui/icons-material/ContactMail";

// Internal
import {
  appSupervision,
  appUser,
  setNotificationBar,
} from "../../../../../store/app/slice";
import API from "../../../../../utils/api";
import createAccess from "../../../../../utils/functions/createAccess";

// Third-party
import { useSelector, useDispatch } from "react-redux";

export default function FilteredChatMenu({
  setOpenPathDialog,
  setNextOperatorDialog,
  setCloseConfirmDialog,
  isChatOpen,
  currentVM,
  isChatBlocked,
  handleBlockChat,
  phoneNumber,
  setWhisperBox,
  whisperBox,
  setOpenInfoDialog,
}) {
  const dispatch = useDispatch();

  const _appSupervision = useSelector(appSupervision);
  const user = useSelector(appUser);

  const [selectedVMToChange, setSelectedVMToChange] = useState(
    "Nenhuma VM selecionada"
  );
  const [loadingVMChanging, setLoadingVMChanging] = useState(false);

  const handleVMChange = (e) => {
    let access_click2chat = createAccess(user);

    let vm = e.target.value;
    setSelectedVMToChange(vm);
    setLoadingVMChanging(true);
    API.put
      .changeVMChat({
        access_click2chat,
        vm,
        phone_number: phoneNumber,
      })
      .then((res) => {
        // console.log(res);
        dispatch(
          setNotificationBar({
            status: true,
            message: "VM alterada",
            type: "success",
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setNotificationBar({
            status: true,
            message: "Erro ao alterar VM",
            type: "error",
          })
        );
      })
      .finally(() => {
        setLoadingVMChanging(false);
        setSelectedVMToChange("Nenhuma VM selecionada");
      });
  };

  const handleWhisperBox = () => {
    setWhisperBox(!whisperBox);
  };

  return (
    <Grid
      container
      justifyContent="start"
      sx={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        display: "flex",
        // border: "1px solid black",
      }}
    >
      <Grid item>
        <Tooltip title="Jornada do Cliente">
          <span>
            <IconButton
              size="medium"
              // disabled={filterName.length === 0}
              onClick={() => setOpenPathDialog(true)}
            >
              <DirectionsRailwayIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      <Grid>
        <Tooltip title="Informações do Contato">
          <span>
            <IconButton
              size="medium"
              // disabled={filterName.length === 0}
              onClick={() => setOpenInfoDialog(true)}
            >
              <ContactMailIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      {isChatOpen && (
        <Grid item>
          <Tooltip
            title={whisperBox ? "Desligar sussurro" : "Ligar sussurro"}
            placement="top-end"
          >
            <span>
              <IconButton size="medium" onClick={handleWhisperBox}>
                {whisperBox ? (
                  <HeadsetOffIcon fontSize="inherit" />
                ) : (
                  <HeadsetMicIcon fontSize="inherit" />
                )}
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      )}
      {!isChatBlocked && (
        <Grid item>
          <Tooltip title="Encaminhar Conversa" placement="top-end">
            <span>
              <IconButton
                size="medium"
                onClick={() => setNextOperatorDialog(true)}
              >
                <ForwardIcon fontSize="inherit" />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      )}
      {_appSupervision?.activeVMS?.filter((vm) => vm !== currentVM)?.length >
        0 &&
        (loadingVMChanging ? (
          <Grid item>
            <CircularProgress size={24} />
          </Grid>
        ) : (
          <Grid item>
            <InputLabel id="demo-simple-select-label">Alterar VM</InputLabel>
            <Select
              value={selectedVMToChange}
              onChange={handleVMChange}
              inputProps={{
                name: "vm",
                id: "vm-select",
              }}
              label="Alterar VM"
            >
              <MenuItem value="Nenhuma VM selecionada">
                Nenhuma VM selecionada
              </MenuItem>
              {_appSupervision?.activeVMS
                ?.filter((vm) => vm !== currentVM)
                .map((vm, index) => (
                  <MenuItem key={index} value={vm}>
                    {vm}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        ))}
      {isChatBlocked ? (
        <Grid item>
          <Tooltip title="Desbloquear Chat" placement="top-end">
            <span>
              <IconButton
                size="medium"
                onClick={() => handleBlockChat(phoneNumber, isChatBlocked)}
              >
                <CheckCircleOutlineIcon color="success" fontSize="inherit" />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      ) : (
        <Grid item>
          <Tooltip title="Bloquear Chat" placement="top-end">
            <span>
              <IconButton
                size="medium"
                onClick={() => handleBlockChat(phoneNumber, isChatBlocked)}
              >
                <BlockIcon fontSize="inherit" color="error" />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      )}
      {isChatOpen && (
        <Grid item>
          <Tooltip title="Encerrar Conversa" placement="top-end">
            <span>
              <IconButton
                size="medium"
                onClick={() => setCloseConfirmDialog(true)}
              >
                <CloseIcon fontSize="inherit" color="error" />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      )}
      <Grid item>
        {currentVM} | {phoneNumber}
      </Grid>
    </Grid>
  );
}
