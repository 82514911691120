// React
import React, { useState } from 'react';

// Design
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// Internal

// Third-party


export default function EditContactDialog({
  open,
  setOpen,
  contactName,
  contactEmail,
  contactCpfCnpj,
  handlePutContactName,
  phone_number,
}) {
  const [name, setName] = useState(contactName);
  const [cpfCnpj, setCpfCnpj] = useState(contactCpfCnpj);
  const [email, setEmail] = useState(contactEmail);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Editar dados do Cliente</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nome"
            type="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
          <TextField
            margin="dense"
            id="cpfCnpj"
            label="CPF/CNPJ"
            type="name"
            value={cpfCnpj}
            onChange={(e) => setCpfCnpj(e.target.value)}
            fullWidth
          />
          <TextField
            margin="dense"
            id="email"
            label="E-mail"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={() => handlePutContactName(name, cpfCnpj, email, phone_number)}
            color="primary"
            disabled={!name}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}