// React
import React, { useState, useEffect } from "react";

// Design
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { green } from "@mui/material/colors";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ForwardIcon from "@mui/icons-material/Forward";
import DirectionsRailwayIcon from "@mui/icons-material/DirectionsRailway";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import HeadsetOffIcon from "@mui/icons-material/HeadsetOff";
import ContactMailIcon from "@mui/icons-material/ContactMail";

// Internal
import { appUser } from "../../store/app/slice";
import ChatCard from "../Cards/chatCard";
import MessageCard from "../Cards/messageCard";
import API from "../../utils/api";
import createAccess from "../../utils/functions/createAccess";
import openURI from "../../utils/functions/openURI";
import downloadURI from "../../utils/functions/downloadURI";
import b64ToBlob from "../../utils/functions/b64toBlob";
import PathDialog from "./pathDialog";
import CloseConfirmDialog from "./closeConfirmDialog";
import NextOperatorDialog from "./nextOperatorDialog";
import SendWhisperBox from "../Boxes/sendWhisperBox";
import InfoContactDialog from "./infoContactDialog";

// Third-Party
import { useSelector } from "react-redux";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

const useStyles = makeStyles((theme) => ({
  appBar: {
    // position: 'relative',
    // marginBottom: '5rem'
    overflow: "hidden",
    "@media print": {
      display: "block !important",
      width: "auto !important",
      height: "auto !important",
      overflow: "visible !important",
      marginLeft: "0 !important",
      // 'pageBreakAfter': 'always !important',
    },
  },
  title: {
    // marginLeft: theme.spacing(2),
    flex: 1,
  },
  path: {
    marginTop: "4.5rem",
    width: "calc(100% - 30rem)",
    height: "100%",
    display: "flex",
    backgroundColor: "blue",
    flexDirection: "column",
    overflowY: "auto",
    "@media print": {
      display: "block !important",
      width: "auto !important",
      height: "auto !important",
      overflow: "visible !important",
      marginLeft: "0 !important",
      pageBreakAfter: "avoid !important",
    },
  },
  buttons: {
    "@media print": {
      display: "hide !important",
    },
  },
  childrenPath: {
    display: "flex",
    width: "100%",
    // height: '50rem',
    alignItems: "center",
    border: "0.09rem solid black",
    borderRadius: "1%",
    marginBottom: "0.5rem",
    "@media print": {
      display: "block !important",
      width: "auto !important",
      height: "auto !important",
      overflow: "visible !important",
      marginLeft: "0 !important",
      pageBreakAfter: "always !important",
    },
  },
}));

function FilteredChatMenu({
  setOpenPathDialog,
  currentVM,
  phoneNumber,
  operatorData,
  setCloseConfirmDialog,
  setNextOperatorDialog,
  whisperBox,
  setWhisperBox,
  loggedUser,
  setOpenInfoDialog,
}) {
  const [numberCopied, setNumberCopied] = useState(false);
  const [vmCopied, setVMCopied] = useState(false);

  const copyText = (text, icon) => {
    navigator.clipboard.writeText("");
    navigator.clipboard.writeText(text);

    if (icon === "vm") {
      setTimeout(() => {
        setVMCopied(true);
      }, 0);
      setTimeout(() => {
        setVMCopied(false);
      }, 3000);
    } else if (icon === "phone") {
      setTimeout(() => {
        setNumberCopied(true);
      }, 0);
      setTimeout(() => {
        setNumberCopied(false);
      }, 3000);
    }
  };

  const handleWhisperBox = () => {
    setWhisperBox(!whisperBox);
  };
  return (
    <Grid container justifyContent="start" gap={1}>
      <Grid item>
        <Tooltip title="Jornada do Cliente">
          <span>
            <IconButton
              size="medium"
              // disabled={filterName.length === 0}
              onClick={() => setOpenPathDialog(true)}
            >
              <DirectionsRailwayIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      <Grid>
        <Tooltip title="Informações do Contato">
          <span>
            <IconButton
              size="medium"
              // disabled={filterName.length === 0}
              onClick={() => setOpenInfoDialog(true)}
            >
              <ContactMailIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      <Grid item>
        {vmCopied ? (
          <Tooltip title="Código VM copiado" placement="bottom-start">
            <span>
              <IconButton size="small">
                <WhatsAppIcon style={{ color: green[500] }} />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <Tooltip
            title={`Copiar Código da VM: ${currentVM}`}
            placement="bottom-start"
          >
            <span>
              <IconButton
                size="small"
                onClick={() => copyText(currentVM, "vm")}
              >
                <WhatsAppIcon fontSize="inherit" />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Grid>
      <Grid item>
        {numberCopied ? (
          <Tooltip title="Número Copiado" placement="bottom-start">
            <span>
              <IconButton size="small">
                <LocalPhoneIcon style={{ color: green[500] }} />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <Tooltip
            title={`Copiar Número: ${phoneNumber.replace(/^55/, "")}`}
            placement="bottom-start"
          >
            <span>
              <IconButton
                size="small"
                onClick={() =>
                  copyText(phoneNumber.replace(/^55/, ""), "phone")
                }
              >
                <LocalPhoneIcon fontSize="inherit" />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Grid>
      <Grid item>
        {operatorData?.username
          ? "Atendido por: " +
            operatorData?.username +
            "(" +
            String(operatorData?.teamName)
              ?.replace("default", "Padrão")
              ?.replace("undefined", "Supervisor") +
            ")"
          : ""}
      </Grid>

      {["operator", "supervisor", "robot"].includes(operatorData?.type) && (
        <Grid item>
          <Tooltip title="Fechar Conversa">
            <span>
              <IconButton
                size="medium"
                onClick={() => setCloseConfirmDialog(true)}
              >
                <CloseIcon style={{ color: "red" }} />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      )}
      <Grid item>
        <Tooltip title="Encaminhar Conversa">
          <span>
            <IconButton
              size="medium"
              onClick={() => setNextOperatorDialog(true)}
            >
              <ForwardIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      {["operator", "supervisor"].includes(operatorData?.type) &&
        loggedUser?.user_id !== operatorData?.id && (
          <Grid item>
            <Grid item>
              <Tooltip
                title={whisperBox ? "Desligar sussurro" : "Ligar sussurro"}
                placement="top-end"
              >
                <span>
                  <IconButton size="medium" onClick={handleWhisperBox}>
                    {whisperBox ? (
                      <HeadsetOffIcon fontSize="inherit" />
                    ) : (
                      <HeadsetMicIcon fontSize="inherit" />
                    )}
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        )}
    </Grid>
  );
}

function FilteredChatMessages({
  messages = [],
  worker,
  chatId,
  phoneNumber,
  handleSendMessage,
  handleSendFile,
  handleLazyDownload,
  handleLazyOpen,
  files,
  whisperBox,
  handleSendWhisper,
}) {
  useEffect(() => {
    if (messages.length > 0) {
      const theElement = document.getElementsByClassName("msgBox")[0];
      const scrollToBottom = (node) => {
        node.scrollTop = node.scrollHeight;
      };
      //   if (!noEmojiFlag) {
      //     scrollToBottom(theElement);
      //   }
      //   blobize();
      scrollToBottom(theElement);
      //   setLastMessageStatus(renderSelectedChatMsgs[renderSelectedChatMsgs.length -1].ack === 'line')
    }
    // eslint-disable-next-line
  }, [messages]);

  return (
    <Box
      display="flex"
      sx={{
        // height: '30rem',
        height: "100%",
        width: "100%",
        background: "#ECEFF1",
        overflowY: "hidden",
        overflowX: "hidden",
        flexDirection: "column",
        // justifyContent: "flex-end",
      }}
    >
      <Box
        display="flex"
        sx={{
          // height: '30rem',
          height: "100%",
          width: "100%",
          background: "#ECEFF1",
          overflowY: "scroll",
          overflowX: "hidden",
          flexDirection: "column",
          // justifyContent: "flex-end",
        }}
        className="msgBox"
      >
        {Array.isArray(messages) &&
          messages.map((message, index) => {
            return (
              <MessageCard
                message={message}
                index={index}
                // user={user}
                key={`message-card${index}`}
                chatId={chatId}
                phoneNumber={phoneNumber}
                handleSendMessage={handleSendMessage}
                handleSendFile={handleSendFile}
                files={files}
                handleLazyDownload={handleLazyDownload}
                worker={worker}
                handleLazyOpen={handleLazyOpen}
              />
            );
          })}
      </Box>
      {whisperBox && chatId && (
        <SendWhisperBox handleSendWhisper={handleSendWhisper} chatId={chatId} />
      )}
    </Box>
  );
}

function FilteredList({ selectedChat, setSelectedChat, filteredChatsList }) {
  const Row = ({ index, style }) => (
    <div style={style} key={`interaction_list-${index}`}>
      <ChatCard
        index={index}
        selectedChatID={selectedChat && selectedChat.chat_id}
        listToUse={filteredChatsList}
        setSelectedChat={setSelectedChat}
      />
    </div>
  );

  return (
    <main style={{ height: "100%", width: "100%" }}>
      {filteredChatsList?.length > 0 ? (
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="List"
              height={height}
              itemCount={filteredChatsList?.length}
              itemSize={110}
              width={width}
            >
              {Row}
            </List>
          )}
        </AutoSizer>
      ) : (
        <Box
          display="flex"
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          Nenhuma conversa encontrada
        </Box>
      )}
    </main>
  );
}

export default function StatusChatDialog({ open, setOpen }) {
  const classes = useStyles();

  const [chatsList, setChatsList] = useState([]);
  const [openPathDialog, setOpenPathDialog] = useState(false);
  const [path, setPath] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("Todos");
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [currentVM, setCurrentVM] = useState("");
  const [operatorData, setOperatorData] = useState({});
  const [closeConfirmDialog, setCloseConfirmDialog] = useState(false);
  const [closeReasonsList, setCloseReasonsList] = useState([]);
  const [nextOperatorDialog, setNextOperatorDialog] = useState(false);
  const [currentOperator, setCurrentOperator] = useState("");
  const [whisperBox, setWhisperBox] = useState(false);

  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const timeToUpdate = 2000;

  const filterTypes = {
    Todos: "all",
    Atendidos: "no",
    Encaminhados: "forwarded",
    Agendados: "scheduled",
    "Não Atendidos (última é mensagem do cliente/veio da fila/veio do robô)": [
      "yes",
      "robot",
      "queue",
    ],
    Robô: "robot",
    Fila: "queue",
  };

  const user = useSelector(appUser);

  useEffect(() => {
    // dispatch(getChats(user));
    const interval = setInterval(() => {
      if (selectedChat) {
        let access_click2chat = createAccess(user);
        API.get
          .chat(access_click2chat, selectedChat.chat_id)
          .then((response) => {
            if (response.ok) {
              let info = response?.data?.info;
              let closeReasons = info?.closed_reasons_list.filter(
                (el) => el.active
              );
              setCloseReasonsList(closeReasons);
              setOperatorData(info?.operatorData ?? {});
              setCurrentVM(info?.vm ?? "");
              setMessages(info?.chat ?? []);
              setCurrentOperator(info?.operatorData?.id ?? "");
              // setChatsList(response?.data?.info?.chats ?? [])
            } else {
              // setChatsList([])
              setMessages([]);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {});
      }
    }, timeToUpdate);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [selectedChat]);

  const handleSendWhisper = (chat_id, msg) => {
    let access_click2chat = createAccess(user);
    API.post.whisper(access_click2chat, chat_id, msg);
    let chat = chatsList.find((el) => el.chat_id === selectedChat?.chat_id);
    setSelectedChat(chat);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectChat = (chat) => {
    setPath([]);
    setMessages([]);
    setCurrentVM("");
    setOperatorData({});
    setCurrentOperator("");
    setWhisperBox(false);
    setSelectedChat(chat);
  };

  const handleLazyDownload = async (media_id) => {
    let access_click2chat = createAccess(user);
    let file = await API.get.file(access_click2chat, media_id);
    if (file.ok && file.data && file.data.data) {
      let url = b64ToBlob(file.data.data.file, file.data.data.mime_type);
      let filename = file.data.data.filename;
      downloadURI(url, filename);
    }
  };

  const handleLazyOpen = async (media_id) => {
    let access_click2chat = createAccess(user);
    let file = await API.get.file(access_click2chat, media_id);
    if (file.ok && file.data && file.data.data) {
      let url = b64ToBlob(file.data.data.file, file.data.data.mime_type);
      openURI(url);
    }
  };

  const handleSearch = (searchField) => {
    let access_click2chat = createAccess(user);
    API.get
      .chatsOpenByFilter(access_click2chat, {
        status: filterTypes[selectedFilter],
      })
      .then((response) => {
        if (response.ok) {
          setChatsList(response?.data?.info?.chats ?? []);
        } else {
          setChatsList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const handlePutNextOperator = (operator_id) => {
    let access_click2chat = createAccess(user);
    API.put
      .nextOperatorSupervision(
        access_click2chat,
        operator_id,
        currentOperator,
        selectedChat.phone_number
      )
      .then(() => {
        // if (response.status === 200) {
        //   setCurrentList(response.data.info.chats)
        // }
        // else {
        //   setCurrentList([])
        // }
      })
      .finally(() => {
        // setOperatorEditDialogShow(false);
      });
  };

  const handlePutNextSupervisor = (supervisor_id) => {
    let access_click2chat = createAccess(user);
    API.put
      .nextSupervisorSupervision(
        access_click2chat,
        supervisor_id,
        currentOperator,
        selectedChat.phone_number
      )
      .then(() => {
        // if (response.status === 200) {
        //   setCurrentList(response.data.info.chats)
        // }
        // else {
        //   setCurrentList([])
        // }
      })
      .finally(() => {
        // setOperatorEditDialogShow(false);
      });
  };

  useEffect(() => {
    if (!open) {
      setSelectedChat(null);
      setChatsList([]);
      setMessages([]);
      setPath([]);
      setCurrentVM("");
      setOperatorData({});
      setCurrentOperator("");
      setWhisperBox(false);
    } else {
      handleSearch(selectedFilter);
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (selectedFilter || !closeConfirmDialog || !nextOperatorDialog) {
      handleSearch(selectedFilter);
    } // eslint-disable-next-line
  }, [selectedFilter, closeConfirmDialog, nextOperatorDialog]);

  useEffect(() => {
    if (openPathDialog) {
      let access_click2chat = createAccess(user);
      API.get
        .chatHistory(access_click2chat, selectedChat?.chat_id)
        .then((response) => {
          if (response.ok) {
            setPath(response?.data?.info?.path ?? []);
          } else {
            setPath([]);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    } else {
      setPath([]);
    }
    // eslint-disable-next-line
  }, [openPathDialog]);

  useEffect(() => {
    if (selectedChat) {
      let access_click2chat = createAccess(user);
      API.get
        .chat(access_click2chat, selectedChat.chat_id)
        .then((response) => {
          if (response.ok) {
            let info = response?.data?.info;
            let closeReasons = info?.closed_reasons_list.filter(
              (el) => el.active
            );
            setCloseReasonsList(closeReasons);
            setOperatorData(info?.operatorData ?? {});
            setCurrentVM(info?.vm ?? "");
            setMessages(info?.chat ?? []);
            setCurrentOperator(info?.operatorData?.id ?? "");
            // setChatsList(response?.data?.info?.chats ?? [])
          } else {
            // setChatsList([])
            setMessages([]);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    }
    // eslint-disable-next-line
  }, [selectedChat]);

  useEffect(() => {
    let chat = chatsList.find((el) => el.chat_id === selectedChat?.chat_id);

    let chatInList = Boolean(chat);
    if (!chatInList) {
      setSelectedChat(null);
      setMessages([]);
      setPath([]);
      setCurrentVM("");
      setOperatorData({});
    } else {
      handleSelectChat(chat);
    }
  }, [chatsList]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      fullScreen
      open={open}
      className={classes.appBar}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Conversas Abertas
          </Typography>

          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            className={classes.buttons}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        display="flex"
        sx={{
          flexDirection: "row",
          width: "100%",
          height: "calc(100vh - 64px)",
          marginTop: "64px",
          // marginTop: '6vh'
        }}
      >
        <Box
          display="flex"
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "26rem",
            height: "calc(100%)",
          }}
        >
          <Box
            display="flex"
            sx={{
              // flexDirection: 'row',
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "5rem",
            }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedFilter}
              label="Filtrar"
              onChange={(e) => setSelectedFilter(e.target.value)}
              sx={{
                width: "10rem",
                height: "3rem",
                marginRight: "1rem",
              }}
            >
              {Object.keys(filterTypes).map((key, index) => {
                return (
                  <MenuItem value={key} key={`filter-type-${index}`}>
                    {key}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box
            display="flex"
            sx={{
              width: "100%",
              height: "calc(100% - 5rem)",
            }}
          >
            <FilteredList
              setSelectedChat={handleSelectChat}
              selectedChat={selectedChat}
              filteredChatsList={chatsList}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          sx={{
            flexDirection: "column",
            width: "calc(100% - 26rem)",
            height: "calc(100vh - 64px)",
            // marginTop: '64px',
            // marginTop: '6vh'
          }}
        >
          {selectedChat ? (
            <>
              <Box
                display="flex"
                sx={{
                  flexDirection: "column",
                  width: "100%",
                  height: "3rem",
                  // marginTop: '64px',
                  // marginTop: '6vh'
                }}
              >
                <FilteredChatMenu
                  setOpenPathDialog={setOpenPathDialog}
                  phoneNumber={selectedChat?.phone_number}
                  currentVM={currentVM}
                  operatorData={operatorData}
                  setCloseConfirmDialog={setCloseConfirmDialog}
                  setNextOperatorDialog={setNextOperatorDialog}
                  setWhisperBox={setWhisperBox}
                  whisperBox={whisperBox}
                  loggedUser={user}
                  setOpenInfoDialog={setOpenInfoDialog}
                />
              </Box>
              <FilteredChatMessages
                messages={messages}
                worker={user?.worker}
                chatId={selectedChat?.chat_id}
                phoneNumber={selectedChat?.phone_number}
                handleLazyDownload={handleLazyDownload}
                whisperBox={whisperBox}
                handleLazyOpen={handleLazyOpen}
                handleSendWhisper={handleSendWhisper}
              />
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Nenhum chat selecionado
            </Box>
          )}
        </Box>
      </Box>
      {selectedChat?.chat_id && path && openPathDialog && (
        <PathDialog
          open={openPathDialog}
          setOpen={setOpenPathDialog}
          path={path}
          phoneNumber={selectedChat.phone_number}
        />
      )}
      {selectedChat?.chat_id && closeConfirmDialog && (
        <CloseConfirmDialog
          open={closeConfirmDialog}
          setOpen={setCloseConfirmDialog}
          phoneNumber={selectedChat.phone_number}
          closeReasonList={closeReasonsList}
        />
      )}
      {selectedChat?.chat_id && nextOperatorDialog && (
        <NextOperatorDialog
          open={nextOperatorDialog}
          setOpen={setNextOperatorDialog}
          phone_number={selectedChat.phone_number}
          _handlePutNextOperator={handlePutNextOperator}
          _handlePutNextSupervisor={handlePutNextSupervisor}
        />
      )}
      {selectedChat?.chat_id && openInfoDialog && (
        <InfoContactDialog
          open={openInfoDialog}
          setOpen={setOpenInfoDialog}
          info={selectedChat?.user_info}
        />
      )}
    </Dialog>
  );
}
