// React
import React from 'react';

// Design
import { ThemeProvider } from '@mui/styles';
import CssBaseline from "@mui/material/CssBaseline";
import theme from './theme.js';


// Internal
import { persistor } from './store';
import { appUser, companyURL } from './store/app/slice';
import SignIn from './pages/SignIn';
import Operation from './pages/Operation';
import Monitor from './pages/Supervision/SubPages/Monitor';
import ChatsMonitoring from './pages/Supervision/SubPages/ChatsMonitoring';
import Reports from './pages/Supervision/SubPages/Reports';
import Massive from './pages/Supervision/SubPages/Massive';
import QR from './pages/QR';
import Home from './pages/Home';

// Thirdy-Party
import { useSelector } from 'react-redux';
import { Route, Navigate, BrowserRouter, Routes } from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react';
// import { useParams } from 'react-router-dom';

function App() {
  const user = useSelector(appUser);
  const _companyURL = useSelector(companyURL);
  const userIsAllowed = user ? true : false;
  // const params = useParams();

  const companyName = _companyURL;
  const backToLogin = `/${companyName}`;

  return (
    // <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route
              path={"/qr-code"}
              element={<QR />}
            />
            <Route
              path={"/:company_name/supervision/monitor"}
              element={!userIsAllowed && (!user || (user && !user.worker === 'supervisor')) ? <Navigate to={backToLogin} /> : <Monitor />}
            />
            <Route
              path={"/:company_name/supervision/chats"}
              element={!userIsAllowed && (!user || (user && !user.worker === 'supervisor')) ? <Navigate to={backToLogin} /> : <ChatsMonitoring />}
            />
            <Route
              path={"/:company_name/supervision/reports"}
              element={!userIsAllowed && (!user || (user && !user.worker === 'supervisor')) ? <Navigate to={backToLogin} /> : <Reports />}
            />
            <Route
              path={"/:company_name/supervision/massive"}
              element={!userIsAllowed && (!user || (user && !user.worker === 'supervisor')) ? <Navigate to={backToLogin} /> : <Massive />}
            />
            <Route
              path={"/:company_name/operation"}
              element={!userIsAllowed && (!user || (user && !user.worker === 'operator')) ? <Navigate to={backToLogin} /> : <Operation />}
            />
            <Route
              path={'/:company_name'}
              element={userIsAllowed && user.worker === 'supervisor' ? <Navigate to={`/${companyName}/supervision/monitor`} /> : (userIsAllowed && user.worker === 'operator' ? <Navigate to={`/${companyName}/operation`} /> : <SignIn />)}
            />
            <Route
              path={"/"}
              element={<Home />}
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </PersistGate>
    // </Provider>
  );
}

export default App;
