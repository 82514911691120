// React
import React, { useEffect, useState, useRef } from "react";

// Design
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// Internal
import API from "../../utils/api";
import createAccess from "../../utils/functions/createAccess";

// Third-party

export default function CountHoldLineGraph({ user }) {
  const timeToUpdate = 60000 * 1 * 3;

  // eslint-disable-next-line
  const [labels, setLabels] = useState(["Total"]);
  const [totalCount, _setTotalCount] = useState(0);
  const [teams, _setTeams] = useState({});
  const totalCountRef = useRef([]);
  const setTotalCount = (newValue) => {
    _setTotalCount(newValue);
    totalCountRef.current = newValue;
  };
  const teamsRef = useRef([]);
  const setTeams = (newValue) => {
    _setTeams(newValue);
    teamsRef.current = newValue;
  };

  const handleGetInfo = (totalCount) => {
    let access_click2chat = createAccess(user);
    let filterLess = new Date();
    let filter = `${filterLess.getFullYear()}-${
      String(filterLess.getMonth() + 1).length === 1 ? "0" : ""
    }${filterLess.getMonth() + 1}-${
      String(filterLess.getDate()).length === 1 ? "0" : ""
    }${filterLess.getDate()}`;

    API.get
      .holdLineConsolidation(access_click2chat, filter)
      .then((response) => {
        if (response.status === 200) {
          let temp = response?.data?.info?.qtt ?? 0;
          let teamsTemp = response?.data?.info?.teams ?? {};
          // let _totalCount = Object.keys(temp).map(el => {
          //   return temp[el]
          // })
          setTotalCount(temp);
          setTeams(teamsTemp);
          // setLabels(Object.keys(temp));
        } else {
          //   setChatsListRequest([])
        }
      });
  };

  useEffect(() => {
    handleGetInfo(totalCount);
    const interval = setInterval(() => {
      handleGetInfo(totalCountRef.current);
    }, timeToUpdate);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Card>
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          textAlign={"center"}
        >
          Clientes em fila
        </Typography>
        <Typography variant="h3" color="text.secondary" textAlign={"center"}>
          {totalCount}
        </Typography>
        {Object.keys(teams).map((team, index) => {
          return (
            <Box
              key={index}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="h6" color="text.secondary">
                {teams[team].name}
              </Typography>
              <Typography variant="h6" color="text.secondary">
                {teams[team].qtt}
              </Typography>
            </Box>
          );
        })}
      </CardContent>
    </Card>
  );
}
