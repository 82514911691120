import packageJson from '../../../package.json';
const identifier = packageJson.identifier;
const public_key = packageJson.public_key;

const createAccess = (access_click2chat) => {
  return JSON.stringify({
    token: access_click2chat.token,
    expiration_date: access_click2chat.expiration_date,
    url: access_click2chat.url,
    files_url: access_click2chat.files_url,
    app_identifier: identifier,
    app_public_key: public_key,
  });
};

export default createAccess;
