// React
import React, { useState, useEffect } from "react";

// Design
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import {
  InputLabel,
  OutlinedInput,
  MenuItem,
  ListItemText,
} from "@mui/material";

// Internal
import API from "../../utils/api";
import createAccess from "../../utils/functions/createAccess";

// Third-party
import { SwatchesPicker } from "react-color";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function EditReasonDialog({
  open,
  setOpen,
  setReasons,
  reason,
  user,
}) {
  const [_reason, _setReason] = useState(reason.reason);
  const [_active, _setActive] = useState(reason.active);
  const [_evaluation, _setEvaluation] = useState(reason.evaluation);
  const [_color, _setColor] = useState(reason.color);
  const [_endingMessage, _setEndingMessage] = useState(reason.endingMessage);
  const [_internalCode, _setInternalCode] = useState(reason.internalCode);

  const [teamsList, setTeamsList] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handlePutReason = () => {
    let access_click2chat = createAccess(user);
    let teams = [];
    teamsList.forEach((team) => {
      if (
        (selectedTeams?.includes(team.name) ||
          selectedTeams.includes("Todos")) &&
        team.id !== "todos"
      ) {
        teams.push(team.id);
      }
    });
    API.put
      .reason(
        access_click2chat,
        _reason,
        reason.id,
        _active,
        _evaluation,
        _color,
        _endingMessage,
        _internalCode,
        teams
      )
      .then((response) => {
        if (response.status === 202) {
          setReasons(response.data.info);
          setOpen(false);
        } else {
        }
      });
  };

  const handleGetTeams = () => {
    let access_click2chat = createAccess(user);
    API.get
      .teams(access_click2chat)
      .then((response) => {
        if (response.status === 200) {
          let _teams = response?.data?.info ?? null;
          if (_teams) {
            let teams = [
              {
                id: "todos",
                name: "Todos",
              },
            ];
            _teams = Object.entries(_teams).map(([key, value]) => {
              return {
                id: key,
                name: value.name === "default" ? "Padrão" : value.name,
              };
            });
            teams = teams.concat(_teams);
            setTeamsList(teams);
          }
        } else {
          // setCurrentList([])
        }
      })
      .finally(() => {});
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value?.includes("Todos")) {
      let teams = teamsList.map((team) => team.name);
      setSelectedTeams(teams);
    } else {
      setSelectedTeams(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }
  };

  useEffect(() => {
    if (open) {
      handleGetTeams();
    }
  }, [open]);

  useEffect(() => {
    let _teams = [];
    teamsList.forEach((team) => {
      if (reason?.teams?.includes(team.id)) {
        _teams.push(team.name);
      }
    });
    setSelectedTeams(_teams);
  }, [teamsList]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Editar Tabulação</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="text"
            label="Razão"
            type="name"
            value={_reason}
            onChange={(e) => _setReason(e.target.value)}
            fullWidth
            multiline
            rows={6}
            required
          />
          <TextField
            margin="dense"
            id="text"
            label="Mensagem de Encerramento"
            type="name"
            value={_endingMessage}
            onChange={(e) => _setEndingMessage(e.target.value)}
            fullWidth
            multiline
            rows={4}
            required
          />
          <TextField
            margin="dense"
            id="text"
            label="Código Interno"
            type="text"
            value={_internalCode}
            onChange={(e) => _setInternalCode(e.target.value)}
            fullWidth
            // multiline
            rows={1}
            required
          />
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">Time</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={selectedTeams}
              onChange={handleChange}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) =>
                selected.join(", ").replace("Todos,", "")
              }
              MenuProps={MenuProps}
            >
              {teamsList.map((team) => (
                <MenuItem key={team.id} value={team.name}>
                  <Checkbox
                    checked={
                      selectedTeams.filter((teamName) => teamName === team.name)
                        .length
                    }
                    disabled={
                      selectedTeams.filter((teamName) => teamName === "Todos")
                        .length && team.name !== "Todos"
                    }
                  />
                  <ListItemText primary={team.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={_active}
                onChange={(e) => _setActive(e.target.checked)}
                name="active"
              />
            }
            label="Ativo"
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">Avaliação</FormLabel>
            <RadioGroup
              aria-label="evaluation"
              name="evaluation"
              value={_evaluation}
              onChange={(e) => _setEvaluation(e.target.value)}
            >
              <FormControlLabel
                value="good"
                control={<Radio color="primary" />}
                label="😊 - Bom"
              />
              <FormControlLabel
                value="neutral"
                control={<Radio color="secondary" />}
                label="😐 - Neutro"
              />
              <FormControlLabel
                value="bad"
                control={<Radio color="error" />}
                label="☹️ - Ruim "
              />
            </RadioGroup>
          </FormControl>
          <SwatchesPicker onChangeComplete={(color) => _setColor(color.hex)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handlePutReason} color="primary" disabled={!_reason}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
