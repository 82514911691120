// React
import React, { useEffect, useState } from "react";

// Design
import { Box, Grid } from "@mui/material";

// Internal
import Supervision from "../..";
import StatusVMSTable from "../../../../components/Tables/statusVMSTable";
import StatusOperatorsTable from "../../../../components/Tables/statusOperatorsTable";
import OperatorEditDialog from "../../../../components/Dialogs/operatorEditDialog";
import VMEditDialog from "../../../../components/Dialogs/vmEditDialog";
import { appUser } from "../../../../store/app/slice";
import createAccess from "../../../../utils/functions/createAccess";
import API from "../../../../utils/api";
import CountMessagesGraph from "../../../../components/Charts/countMessagesGraph";
import CountFirstMessageGraph from "../../../../components/Charts/countFirstMessageGraph";
import CountReturnsGraph from "../../../../components/Charts/countReturnsGraph";
import CountHoldLineGraph from "../../../../components/Charts/countHoldLineGraph";
import ClosedReasonsTable from "../../../../components/Tables/closedReasonsTable";
import CurrentStatusChatsGraph from "../../../../components/Charts/currentStatusChatsGraph";
import FunnelMassiveGraph from "../../../../components/Charts/funnelMassiveGraph";
import LastFunnelMassiveGraph from "../../../../components/Charts/last_funnelMassiveGraph";
import CountPerTeamGraph from "../../../../components/Charts/countPerTeamGraph";

// Third-party
import { useSelector } from "react-redux";
import StatusChatDialog from "../../../../components/Dialogs/statusChatDialog";

export default function Monitor() {
  const user = useSelector(appUser);

  const [vmEditDialogShow, setVMEditDialogShow] = useState(false);
  const [selectedVM, setSelectedVM] = useState(null);
  const [selectedVMTeamIn, setSelectedVMTeamIn] = useState(null);
  const [selectedVMTeamOut, setSelectedVMTeamOut] = useState(null);

  const [operatorEditDialogShow, setOperatorEditDialogShow] = useState(false);
  const [selectedOperatorId, setSelectedOperatorId] = useState("");
  const [selectedOperatorTeam, setSelectedOperatorTeam] = useState(null);
  const [selectedUsername, setSelectedUsername] = useState("");
  const [operatorInLine, setOperatorInLine] = useState(false);
  const [operatorOnLine, setOperatorOnLine] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [operatorBlocked, setOperatorBlocked] = useState(false);
  const [vmsList, setVMSList] = useState([]);

  const [statusChatDialog, setStatusChatDialog] = useState(false);

  const handlePullFromLine = () => {
    let access_click2chat = createAccess(user);
    let id = selectedOperatorId;
    let username = selectedUsername;
    API.put
      .pullFromLine(access_click2chat, id, username)
      .then(() => {
        //   if (response.status === 200) {
        //     // setOperatorsListRequest(response.data.info)
        //   }
        //   else {
        //   //   setChatsListRequest([])
        //   }
      })
      .finally(() => {
        setOperatorEditDialogShow(false);
      });
  };

  const handlePutInLine = () => {
    let access_click2chat = createAccess(user);
    let id = selectedOperatorId;
    let username = selectedUsername;
    API.put
      .putInLine(access_click2chat, id, username)
      .then(() => {
        //   if (response.status === 200) {
        //     // setOperatorsListRequest(response.data.info)
        //   }
        //   else {
        //   //   setChatsListRequest([])
        //   }
      })
      .finally(() => {
        setOperatorEditDialogShow(false);
      });
  };

  const handleGetSchedule = () => {
    let access_click2chat = createAccess(user);
    let id = selectedOperatorId;
    API.get
      .scheduleOperatorSupervision(access_click2chat, id)
      .then((response) => {
        if (response.status === 200) {
          setSchedule(response.data.info);
        } else {
          setSchedule([]);
        }
      })
      .finally(() => {
        // setOperatorEditDialogShow(false);
      });
  };

  const handleUnblockOperator = () => {
    let access_click2chat = createAccess(user);
    let id = selectedOperatorId;
    API.put
      .unblockOperator(access_click2chat, id)
      .then((response) => {
        //   if (response.status === 200) {
        //     // setOperatorsListRequest(response.data.info)
        //   }
        //   else {
        //   //   setChatsListRequest([])
        //   }
      })
      .finally(() => {
        setOperatorEditDialogShow(false);
      });
  };

  const handleBlockOperator = () => {
    let access_click2chat = createAccess(user);
    let id = selectedOperatorId;
    API.put
      .blockOperator(access_click2chat, id)
      .then(() => {
        handlePullFromLine();
      })
      .finally(() => {
        setOperatorEditDialogShow(false);
      });
  };

  const handleSelectOperator = (operator) => {
    operator = operator.row;
    setSelectedOperatorId(operator.operator_id);
    setSelectedUsername(operator.username);
    setOperatorInLine(operator._line);
    setOperatorOnLine(operator._online);
    setOperatorBlocked(operator._blocked);
    setSelectedOperatorTeam({ name: operator.team_name, id: operator.team_id });
    setOperatorEditDialogShow(true);
  };

  const handleSelectVM = (_vm, teamIn, teamOut) => {
    setSelectedVM(_vm);
    setSelectedVMTeamIn(teamIn);
    setSelectedVMTeamOut(teamOut);
    setVMEditDialogShow(true);
  };

  const handleStatusChatDialog = () => {
    setStatusChatDialog(!statusChatDialog);
  };

  useEffect(() => {
    if (!operatorEditDialogShow) {
      setSelectedOperatorId("");
      setSelectedUsername("");
      setSelectedOperatorTeam(null);
      setOperatorInLine(false);
      setOperatorOnLine(false);
      setOperatorBlocked(false);
      setOperatorEditDialogShow(false);
      setSchedule([]);
    } else {
      handleGetSchedule();
    }
    // eslint-disable-next-line
  }, [operatorEditDialogShow]);

  useEffect(() => {
    if (!vmEditDialogShow) {
      setSelectedVM(false);
      setSelectedVMTeamIn(null);
      setSelectedVMTeamOut(null);
    }
  }, [vmEditDialogShow]);

  return (
    <Box>
      <Supervision />
      <Box
        sx={{
          display: "flex",
          margin: 0,
          width: "100%",
          height: "calc(100vh - 4.063rem)",
          overflowY: "scroll",
          overflowX: "hidden",
          flexFlow: "column",
        }}
      >
        <Box
          sx={{
            width: "inherit",
          }}
        >
          <StatusVMSTable
            user={user}
            handleSelectVM={handleSelectVM}
            setVMSList={setVMSList}
          />
        </Box>
        <Box
          sx={{
            width: "inherit",
          }}
        >
          <StatusOperatorsTable
            handleSelectOperator={handleSelectOperator}
            user={user}
          />
        </Box>
        <Box
          sx={{
            width: "inherit",
            height: "calc(inherit / 3)",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xl={6} md={6} sm={12} xs={12}>
              <CountMessagesGraph user={user} />
            </Grid>
            <Grid item xl={6} md={6} sm={12} xs={12}>
              <ClosedReasonsTable user={user} />
            </Grid>
            <Grid item xl={3} md={3} sm={12} xs={12}>
              <CountFirstMessageGraph user={user} />
            </Grid>
            <Grid item xl={3} md={3} sm={12} xs={12}>
              <CountReturnsGraph user={user} />
            </Grid>
            <Grid
              item
              xl={3}
              md={3}
              sm={12}
              xs={12}
              sx={{
                cursor: "pointer",
              }}
              onClick={handleStatusChatDialog}
            >
              <CurrentStatusChatsGraph user={user} />
            </Grid>
            <Grid item xl={3} md={3} sm={12} xs={12}>
              <CountHoldLineGraph user={user} />
            </Grid>
            <Grid item xl={3} md={3} sm={12} xs={12}>
              <FunnelMassiveGraph user={user} />
            </Grid>
            <Grid>
              <LastFunnelMassiveGraph user={user} />
            </Grid>
            <Grid item xl={3} md={3} sm={12} xs={12}>
              <CountPerTeamGraph user={user} />
            </Grid>
          </Grid>
        </Box>
      </Box>

      {operatorEditDialogShow && selectedOperatorId && selectedUsername && (
        <OperatorEditDialog
          open={operatorEditDialogShow}
          setOpen={setOperatorEditDialogShow}
          username={selectedUsername}
          operatorId={selectedOperatorId}
          inLine={operatorInLine}
          onLine={operatorOnLine}
          handlePullFromLine={handlePullFromLine}
          handlePutInLine={handlePutInLine}
          schedule={schedule}
          blocked={operatorBlocked}
          handleBlockOperator={handleBlockOperator}
          handleUnblockOperator={handleUnblockOperator}
          user={user}
          selectedOperatorTeam={selectedOperatorTeam}
        />
      )}
      {vmEditDialogShow && selectedVM && (
        <VMEditDialog
          open={vmEditDialogShow}
          setOpen={setVMEditDialogShow}
          vm_id={selectedVM}
          user={user}
          selectedVMTeamIn={selectedVMTeamIn}
          selectedVMTeamOut={selectedVMTeamOut}
          vmsList={vmsList}
        />
      )}

      <StatusChatDialog
        open={statusChatDialog}
        setOpen={setStatusChatDialog}
        user={user}
      />
    </Box>
  );
}
