// React
import React, { useEffect, useState } from 'react';

// Design
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// Internal
import AddReasonDialog from './addReasonDialog';
import EditReasonDialog from './editReasonDialog';
import API from '../../utils/api';
import createAccess from '../../utils/functions/createAccess';

// Third-Party

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    flex: 1,
  },
  root: {
    width: 800,
  },
  container: {
    maxHeight: 600,
  },
}));


const columns = [
  { id: 'reason', label: 'Tabulação', minWidth: 170 }
];

function StickyHeadTable({ reasons, setSelectedReason }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {reasons.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <Grid
                            container
                          >
                            <Grid item>
                              <FiberManualRecordIcon style={{color: row.color}} />
                            </Grid>
                            <Grid
                              item
                              xs={8}
                            >
                              {column.format && typeof value === 'number' ? column.format(value) : value} 
                            </Grid>
                            <Grid
                              item
                            >
                              {
                                row.active ?
                                <VisibilityIcon color="primary"/>
                                :
                                <VisibilityOffIcon color="error"/>
                              }
                            </Grid>
                            <Grid
                              item
                            >
                              <Typography variant="h5">
                                {row.evaluation === 'good' ? '😊' : row.evaluation === 'neutral' ? '😐' : '☹️'}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                            >
                              <IconButton 
                                size="medium"
                                onClick={() => setSelectedReason(row['id'])}
                                disabled={!row['id']}
                              >
                                <EditIcon fontSize="inherit" />
                              </IconButton>
                            </Grid>
                          </Grid>

                        </TableCell>
                      );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={reasons.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default function CloseReasonDialogList({ open, setOpen, user }) {

  const classes = useStyles();
  const [reasons, setReasons] = useState([]);
  const [addReasonDialogShow, setAddReasonDialogShow] = useState(false);
  const [editReasonDialogShow, setEditReasonDialogShow] = useState(false);
  const [selectedReason, setSelectedReason] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const handleGetReasons = () => {
    let access_click2chat = createAccess(user);

    API.get.closeReasonsList(access_click2chat)
      .then((response) => {
          if (response.data.msg === 'close reasons found' && response.data.info.list.length > 0) {
            setReasons(response.data.info.list);
          }
          else {

          }
      })
  }

  useEffect(() => {
    if (open) {
      handleGetReasons();
      setSelectedReason('');
    } 
    else {
      setReasons([]);
    }
  // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (selectedReason) {
      setEditReasonDialogShow(true);
    } else {
      setEditReasonDialogShow(false);
    }
  }, [selectedReason])

  useEffect(() => {
    if(!editReasonDialogShow) {
      setSelectedReason('');
    }
  }, [editReasonDialogShow])

  return (
    <div>
      <Dialog open={open} maxWidth='xl' onClose={handleClose} >
        <AppBar className={classes.appBar}>
          <Toolbar>            
            <IconButton 
              size="medium"
              onClick={() => setAddReasonDialogShow(true)} 
              edge="start"
            >
              <AddIcon fontSize="inherit" />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Tabulação
            </Typography>  
            <IconButton edge="end" color="default" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <StickyHeadTable 
          reasons={reasons}
          setSelectedReason={setSelectedReason}
        />
        
      </Dialog>
      {
        addReasonDialogShow && 
          <AddReasonDialog 
            open={addReasonDialogShow}
            setOpen={setAddReasonDialogShow}
            setReasons={setReasons}
            user={user}
          />
      }

      {
        editReasonDialogShow && selectedReason &&
          <EditReasonDialog 
            open={editReasonDialogShow}
            setOpen={setEditReasonDialogShow}
            setReasons={setReasons}
            reason={reasons.find(el => el.id === selectedReason)}
            user={user}
          />
        }
    </div>
  );
}

