// React
import React, { useEffect, useState } from "react";

// Design
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import { Tooltip } from "@mui/material";

// Internal
// import AddNoteDialog from './addNoteDialog';
// import EditNoteDialog from './editNoteDialog';

// Third-Party

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    width: 800,
  },
  container: {
    maxHeight: 600,
  },
}));

const columns = [
  { id: "phone", label: "Telefone", minWidth: 170 },
  { id: "text", label: "Mensagem", minWidth: 170 },
];

function StickyHeadTable({ messages }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {messages
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <Grid container>
                            <Grid item xs={9}>
                              {value}
                            </Grid>
                          </Grid>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        // rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={messages.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default function MessagesLineDialog({ open, setOpen, vm_id, vmsList }) {
  const classes = useStyles();
  const [messages, setMessages] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open && vmsList) {
      let vm = vmsList.find((el) => el.vm_code === vm_id);
      if (vm) {
        let _vms = vm.messages_line.map((el) => {
          let text = el.text?.msg?.interaction ?? "";
          let phone = el.text?.phone;
          if (text.includes("b61a1a33-cd75-4bb4-8bb8-87e84ccc1375")) {
            text = "MENSAGEM DE MATURAÇÃO";
            phone = "NÚMERO DE MATURAÇÃO";
          }
          return {
            phone,
            text,
          };
        });
        setMessages(_vms);
      }
    } else {
      setMessages([]);
    }
    // eslint-disable-next-line
  }, [open, vmsList]);

  return (
    <div>
      <Dialog open={open} maxWidth="xl" onClose={handleClose}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Mensagens na Fila
          </Typography>
          <Tooltip title="Fechar Janela" placement="left-start">
            <IconButton
              edge="end"
              color="default"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <StickyHeadTable messages={messages} />
      </Dialog>
      {/* {
        addNoteDialogShow && 
          <AddNoteDialog 
            open={addNoteDialogShow}
            setOpen={setAddNoteDialogShow}
          />
        }

      {
        editNoteDialogShow && selectedNote &&
          <EditNoteDialog 
            open={editNoteDialogShow}
            setOpen={setEditNoteDialogShow}
            note={notes.find(el => el.id === selectedNote)}
          />
        } */}
    </div>
  );
}
