// React
import React from 'react';

// Design
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

// Internal
import ChatsList from './chatsList';
import Chat from './chat';

// Third-party

export default function ChatsStructure({
    chatsList,
    selectedChat,
    setSelectedChat,
    handleSendMessage,
    handleSendFile,
    files,
    notes,
    acceptedFiles,
    handleFavorite,
    filesToUpload,
    setFilesToUpload,
    uploadFilesVisible,
    setUploadFilesVisible,
    onDropFile,
    onChangeFile,
    setPathDialogOpen,
    setEditContactDialogOpen,
    setReminderDialogWithNumberShow,
    setNextOperatorDialog,
    setCloseConfirmDialog,
    handleLazyDownload,
    handleLazyOpen,
    setScheduleChatDialogShow,
    setRecordAudioDialogOpen,
    setCloudFilesVisible,
    cloudFilesVisible,
    setProfilePicOpen,
    operation,
}) {

    return (
        <main
            style={{
                overflowX: 'hidden',
                overflowY: 'hidden'
            }}
        >
            <Box
                sx={{
                    // p: 15,
                    width: '25rem',
                    height: 'calc(100vh - 64px)',
                    overflowY: 'hidden',
                    overflowX: 'hidden',
                    position: 'absolute'
                }}
            >
                <ChatsList
                    chatsList={chatsList}
                    selectedChat={selectedChat}
                    setSelectedChat={setSelectedChat}
                />
            </Box>
            {
                selectedChat && selectedChat.id ?
                    <Box
                        sx={{
                            marginLeft: '25rem',
                            position: 'relative',
                            height: 'calc(100vh - 64px)',
                            overflowX: 'hidden',
                            overflowY: 'hidden'
                        }}
                    >
                        <Chat
                            selectedChat={selectedChat}
                            handleSendMessage={handleSendMessage}
                            handleSendFile={handleSendFile}
                            files={files}
                            notes={notes}
                            acceptedFiles={acceptedFiles}
                            handleFavorite={handleFavorite}
                            filesToUpload={filesToUpload}
                            setFilesToUpload={setFilesToUpload}
                            uploadFilesVisible={uploadFilesVisible}
                            setUploadFilesVisible={setUploadFilesVisible}
                            onDropFile={onDropFile}
                            onChangeFile={onChangeFile}
                            setPathDialogOpen={setPathDialogOpen}
                            setEditContactDialogOpen={setEditContactDialogOpen}
                            setReminderDialogWithNumberShow={setReminderDialogWithNumberShow}
                            setNextOperatorDialog={setNextOperatorDialog}
                            setCloseConfirmDialog={setCloseConfirmDialog}
                            handleLazyDownload={handleLazyDownload}
                            handleLazyOpen={handleLazyOpen}
                            setScheduleChatDialogShow={setScheduleChatDialogShow}
                            setRecordAudioDialogOpen={setRecordAudioDialogOpen}
                            setCloudFilesVisible={setCloudFilesVisible}
                            cloudFilesVisible={cloudFilesVisible}
                            setProfilePicOpen={setProfilePicOpen}
                            operation={operation}
                        />
                    </Box>
                    :
                    <Box
                        style={{
                            backgroundImage: 'url("/images/background-whatsapp-7.jpg")',
                            display: 'flex',
                            height: 'calc(100vh - 64px)',
                            width: '100%',
                            overflow: 'hidden',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Typography color='#d3d3d3' variant="h2" marginLeft='25rem'>
                            Nenhuma conversa selecionada
                        </Typography>
                    </Box>
            }
        </main>

    );
}