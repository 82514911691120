// React
import React, { useState, useEffect } from 'react';

// Design
import { Box } from '@mui/system';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import NotesIcon from '@mui/icons-material/Notes';
import IconButton from '@mui/material/IconButton';
import { Tooltip, FormControl, InputAdornment, Input, Stack } from '@mui/material';
import SendIcon from '@mui/icons-material//Send';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MicIcon from '@mui/icons-material/Mic';
import CloudCircleIcon from '@mui/icons-material/CloudCircle';

// Internal
import EmojiDialog from '../Interactions/emojiDialog';
import { appOperation } from '../../store/app/slice';

// Third-party
import { useSelector } from 'react-redux';

export default function SendMessageBox({
    handleSendMessage,
    chatId,
    notes,
    uploadFilesVisible,
    setUploadFilesVisible,
    setRecordAudioDialogOpen,
    setCloudFilesVisible,
    cloudFilesVisible,
    messageType = 'external',
    showNotesButton = true,
    showAttachFilesButton = true,
    showCloudFilesButton = true,
    showEmojiButton = true,
    showRecordButton = true,

}) {
    const [textToSend, setTextToSend] = useState('');
    const [textToSendFlag, setTextToSendFlag] = useState(false);
    const [emojiDialogShow, setEmojiDialogShow] = useState(false);
    const [anchorGetNotes, setAnchorGetNotes] = useState(null);
    const operation = useSelector(appOperation);


    const handleCloseGetNotes = () => {
        setAnchorGetNotes(null);
    };

    const handleSendText = () => {
        if (messageType === 'external') {
            handleSendMessage(chatId, textToSend, false, '', operation?.team?.teamId, operation?.team?.teamName);
        }
        else if (messageType === 'internal') {
            handleSendMessage(chatId, textToSend);
        }
        setTextToSend('');
    };

    const handleSendNote = (e, note) => {
        setAnchorGetNotes(null);
        setTextToSend(note.text);
    };

    const handleEnterPress = (e) => {
        if (e.which === 13 && e.shiftKey) {

        }
        else if (e.which === 13 && !textToSendFlag) {
            e.preventDefault(); //Stops enter from creating a new line
            handleSendText();
        }
    };

    useEffect(() => {
        setTextToSendFlag(Array.from(textToSend).every(el => el === '\n'));
    }, [textToSend]);

    useEffect(() => {
        setTextToSend('');
    }, [chatId]);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    direction: 'column',
                    height: '12rem',
                    width: '100%',
                    background: '#BDBDBD',
                    // paddingTop: '1rem'
                }}
            >
                <Box
                    sx={{
                        width: '3rem',
                        background: '#BDBDBD',
                    }}
                >
                    {
                        showNotesButton &&
                        <Tooltip title="Notas" placement="left">
                            <IconButton onClick={(e) => setAnchorGetNotes(e.currentTarget)}>
                                <NotesIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    }

                    <Menu
                        id="simple-menu"
                        anchorEl={anchorGetNotes}
                        keepMounted
                        open={Boolean(anchorGetNotes)}
                        onClose={handleCloseGetNotes}
                        style={{ zIndex: 999999999 }}
                    >
                        {
                            notes &&
                            notes.map((note, index) => {
                                return (
                                    <MenuItem
                                        onClick={(e) => handleSendNote(e, note)}
                                        key={`note-${index}`}
                                    >
                                        {note.title}
                                    </MenuItem>
                                )
                            })
                        }
                    </Menu>
                    {
                        showAttachFilesButton &&
                        <Tooltip title="Anexar arquivo" placement="left">
                            <IconButton
                                onClick={() => setUploadFilesVisible(!uploadFilesVisible)}
                            >
                                <AttachFileIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    }
                    {
                        showCloudFilesButton &&
                        <Tooltip title="Banco de arquivos" placement="left">
                            <IconButton
                                onClick={() => setCloudFilesVisible(!cloudFilesVisible)}
                            >
                                <CloudCircleIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    }
                    {
                        showEmojiButton &&
                        <Tooltip title="Emoji" placement="left">
                            <IconButton
                                onClick={() => setEmojiDialogShow(!emojiDialogShow)}
                            >
                                <EmojiEmotionsIcon
                                    fontSize="small"
                                />
                            </IconButton>
                        </Tooltip>
                    }
                    {
                        showRecordButton &&
                        <Tooltip title="Gravar áudio" placement="top-end">
                            <span>
                                <IconButton
                                    onClick={() => setRecordAudioDialogOpen(true)}
                                    disabled={!textToSendFlag || operation?.blockAudio}
                                // disabled={true} 
                                >
                                    <MicIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    }


                </Box>
                <Box
                    sx={{
                        width: '97vw',
                        background: '#BDBDBD',
                    }}
                >
                    <FormControl fullWidth>
                        <Input
                            required
                            autoFocus
                            multiline
                            rows={8}
                            // row={2}
                            id="input-with-icon-adornment-search"
                            endAdornment={
                                <InputAdornment position="start">
                                    <Stack>
                                        <Tooltip title="Enviar Mensagem" placement="top-end">
                                            <span>
                                                <IconButton
                                                    onClick={() => handleSendText()}
                                                    disabled={textToSendFlag}
                                                >
                                                    <SendIcon />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </Stack>
                                </InputAdornment>
                            }
                            value={textToSend}
                            onChange={(e) => setTextToSend(e.target.value)}
                            onKeyPress={(e) => handleEnterPress(e)}
                        />
                    </FormControl>
                </Box>
            </Box>
            {
                emojiDialogShow &&
                <EmojiDialog
                    setTextToSend={setTextToSend}
                    textToSend={textToSend}
                    open={emojiDialogShow}
                    setOpen={setEmojiDialogShow}
                />
            }
        </>
    );
}