// React
import React, { useEffect } from 'react';

// Design
import { Box } from '@mui/system';

// Internal
import MessageCard from '../../../components/Cards/messageCard';

// Third-party

export default function ChatMessages({
    messages,
    user,
    chatId,
    phoneNumber,
    handleSendMessage,
    handleSendFile,
    files,
    handleLazyDownload,
    handleLazyOpen,
}) {

    useEffect(() => {
    
        if (messages.length > 0) {
          const theElement = document.getElementsByClassName('msgBox')[0];
          const scrollToBottom = (node) => {
            node.scrollTop = node.scrollHeight;
          }
        //   if (!noEmojiFlag) {
        //     scrollToBottom(theElement);
        //   }
        //   blobize();
          scrollToBottom(theElement);
        //   setLastMessageStatus(renderSelectedChatMsgs[renderSelectedChatMsgs.length -1].ack === 'line')
        }
        // eslint-disable-next-line
    }, [messages]);

    return (
        <Box
            sx={{
                // height: '30rem',
                height: 'inherit',
                width: '100%',
                background: '#505050',
                overflowY: 'scroll',
                overflowX: 'hidden',
                paddingBottom: '0.313rem',
                backgroundImage: 'url("/images/background-whatsapp-7.jpg")',
            }}
            className='msgBox'
        >
            {
                Array.isArray(messages) &&
                messages.map((message, index) => {
                    return (
                        <MessageCard
                            message={message}
                            index={index}
                            user={user}
                            key={`message-card${index}`}
                            chatId={chatId}
                            phoneNumber={phoneNumber}
                            handleSendMessage={handleSendMessage}
                            handleSendFile={handleSendFile}
                            files={files}
                            handleLazyDownload={handleLazyDownload}
                            handleLazyOpen={handleLazyOpen}
                        />
                    )
                })
            }
        </Box>

    );
}