// React
import React from 'react';

// Design
// import { Typography } from '@mui/material';

// Internal
// import API from '../../utils/api';
// import createAccess from '../../utils/functions/createAccess';

// Third-party

export default function CountPerTeamGraph({ user }) {

  // const timeToUpdate = 60000 * 1 * 3;
  
  // eslint-disable-next-line

  // const handleGetInfo = (totalCount) => {
  // }
  


  return (
    <>
    Contagem por Time
    </>
  );
}