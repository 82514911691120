// React
import React from 'react';

// Design
import { Card, CardContent, IconButton } from '@mui/material';
import { Tooltip, Typography } from '@mui/material';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ErrorIcon from '@mui/icons-material/Error';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box } from '@mui/material';

// Internal
// import downloadURI from '../../utils/functions/downloadURI';

// Third-party

// eslint-disable-next-line
const manageStatus = (status) => {
  const _status = {
    'line': (
      <Tooltip title="Fila">
        <DirectionsRunIcon />
      </Tooltip>
    ),
    'error': (
      <Tooltip title="Não Enviado">
        <ErrorIcon color="error" />
      </Tooltip>
    ),
    'browser': (
      <Tooltip title="Está no browser">
        <OpenInBrowserIcon />
      </Tooltip>
    ),
    0: (
      <Tooltip title="Não Enviado">
        <QueryBuilderIcon />
      </Tooltip>
    ),
    1: (
      <Tooltip title="Enviado">
        <CheckIcon />
      </Tooltip>
    ),
    2: (
      <Tooltip title="Recebido">
        <DoneAllIcon />
      </Tooltip>
    ),
    // 3: <DoneAllIcon color="primary" />,
    3: (
      <Tooltip title="Visualizado">
        <DoneAllIcon
          style={{
            color: '#34B7F1'
          }}
        />
      </Tooltip>
    ),
    4: (
      <Tooltip title="Aberto">
        <DoneAllIcon
          style={{
            color: '#34B7F1'
          }}
        />
      </Tooltip>
    ),
  }
  if (!Object.keys(_status).includes(String(status))) return _status[0]
  return _status[status]
}


const options = {
  year: 'numeric', month: 'numeric', day: 'numeric',
  hour: 'numeric', minute: 'numeric',
  hour12: false,
  timeZone: 'America/Sao_Paulo'
};


export default function InternalMessageCard({
  message,
  user,
  index,
  users,
  chatId,
  worker
}) {

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: message?.sender === user.user_id ? 'flex-end' : 'flex-start',
        paddingRight: message?.sender === user.user_id ? '0.313rem' : '0',
        marginTop: '0.5vh',
      }}
    >
      <Card
        key={index}
        style={{
          width: '40rem',
          backgroundColor: message?.sender === user.user_id ? '#075E54' : '#283b3d',
          marginLeft: message?.sender === user.user_id ? '0' : '0.313rem',
        }}
      >
        {users.find(el => chatId === el._id) &&
          <Box
            display="flex"
            sx={{
              // flexDirection: 'column',
              width: '100%',
              height: '1rem',
              justifyContent: 'flex-start'
            }}
          >
            <Box display="flex" sx={{ marginLeft: '.5rem', marginTop: '.25rem' }}>
              <Typography variant="caption" color="white">
                {users.find(el => message.sender === el._id) && users.find(el => message.sender === el._id).username}
              </Typography>
            </Box>
          </Box>
        }

        <CardContent>

          {
            message?.hasMedia ?
              <Typography
                color="#D3D3D3"
              >
                {`${message?.message || 'Arquivo'}`}
                <Tooltip title={`Baixar ${message.message || 'Arquivo'}`}>
                  <IconButton
                  // onClick={() => handleLazyDownload(message.media_id)}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={`Abrir ${message.message || 'Arquivo'}`}>
                  <IconButton
                  // onClick={() => handleLazyOpen(message.media_id)}
                  >
                    <OpenInNewIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
              :
              <span>
                <Typography color="#D3D3D3">
                  {message.message || 'ARQUIVO ou STATUS'}
                </Typography>
              </span>
          }
          <Box
            display="flex"
            sx={{
              width: '100%',
              justifyContent: 'flex-end'
            }}
          >
            <Typography variant="caption" color="#D3D3D3">
              {Intl.DateTimeFormat('pt-BR', options).format(new Date(message?.event_time))}
            </Typography>

          </Box>

        </CardContent>
      </Card>
    </Box>
  );
}