// React
import React, { useEffect, useState } from 'react';

// Style
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ChatIcon from '@mui/icons-material/Chat';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';


// Internal
import { appUser, signout, companyURL, getInternalChatsCountSupervision, appInternal, appSupervision } from '../../../store/app/slice';
import API from '../../../utils/api';
import createAccess from '../../../utils/functions/createAccess';
import InternalChatDialog from '../../../components/Dialogs/internalChatDialog';


// Third-party
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

export default function SupervisionBar({ setProfileSupervisorDialogOpen }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const _appInternal = useSelector(appInternal);
    const _appSupervision = useSelector(appSupervision);
    const _companyURL = useSelector(companyURL);
    const timeToUpdate = 1500;

    const companyName = _companyURL;

    const user = useSelector(appUser);
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [internalChatDialogOpen, setInternalChatDialogOpen] = useState(false);
    const [usersInternalCommunication, setUsersInternalCommunication] = useState([]);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    function handleChangePage(page) {
        navigate(page, { replace: true });
    }

    const handleSignOut = async () => {
        let access_click2chat = createAccess(user);
        await API.post.clockPunchSupervisionOut(access_click2chat)
        dispatch(signout(user))
    }

    const handleOpenProfile = () => {
        handleMenuClose()
        setProfileSupervisorDialogOpen(true)
    };

    const handleInternalCommunicationOpen = () => {
        let access_click2chat = createAccess(user);
        API.get.internalCommunicationUsers(access_click2chat)
            .then((response) => {
                setUsersInternalCommunication(response?.data?.users ?? []);
            })
            .catch(console.log)
            .finally(() => {
                setInternalChatDialogOpen(true)

            })
    };

    useEffect(() => {
        dispatch(getInternalChatsCountSupervision(user))
        const interval = setInterval(() => {
            dispatch(getInternalChatsCountSupervision(user))
        }, timeToUpdate);

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line
    }, []);


    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleOpenProfile}>Perfil</MenuItem>
            <MenuItem onClick={handleSignOut}>Sair</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={() => handleChangePage(`/${companyName}/supervision/monitor`)}>
                <p>MONITOR</p>
            </MenuItem>
            <MenuItem onClick={() => handleChangePage(`/${companyName}/supervision/chats`)}>
                <p>CHATS</p>
            </MenuItem>
            <MenuItem onClick={() => handleChangePage(`/${companyName}/supervision/massive`)}>
                <p>MASSIVO</p>
            </MenuItem>
            <MenuItem
                onClick={() => handleChangePage(`/${companyName}/supervision/reports`)}
            >
                <p>RELATÓRIOS</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <p>+</p>
            </MenuItem>
        </Menu>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color="transparent">
                <Toolbar>
                    <Stack
                        direction="column"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                        <Button
                            // color="secondary" 
                            variant={window.location.pathname === '/supervision/monitor' ? 'contained' : 'outlined'}
                            disabled={window.location.pathname.includes('/supervision/monitor') ? true : false}
                            onClick={() => handleChangePage(`/${companyName}/supervision/monitor`)}
                        >
                            MONITOR
                        </Button>
                        <Button
                            // color="secondary" 
                            variant={window.location.pathname.includes('/supervision/chats') ? 'contained' : 'outlined'}
                            disabled={window.location.pathname.includes('/supervision/chats') ? true : false}
                            onClick={() => handleChangePage(`/${companyName}/supervision/chats`)}
                        >
                            CHATS
                        </Button>
                        <Button
                            // color="secondary" 
                            variant={window.location.pathname.includes('/supervision/massive') ? 'contained' : 'outlined'}
                            disabled={window.location.pathname.includes('/supervision/massive') ? true : false}
                            onClick={() => handleChangePage(`/${companyName}/supervision/massive`)}
                        >
                            MASSIVO
                        </Button>
                        <Button
                            // color="secondary" 
                            variant={window.location.pathname.includes('/supervision/reports') ? 'contained' : 'outlined'}
                            disabled={window.location.pathname.includes('/supervision/reports') ? true : false}
                            onClick={() => handleChangePage(`/${companyName}/supervision/reports`)}
                        >
                            RELATÓRIOS
                        </Button>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box>
                        <Tooltip title="Conversas Internas">
                            <IconButton
                                onClick={handleInternalCommunicationOpen}
                                disabled={_appSupervision?.blockInternalChat ?? false}
                            >
                                <Badge color="primary" badgeContent={_appInternal?.newChatsCounting ?? 0}>
                                    <ChatIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Box>
                            <Typography>
                                {user.username}
                            </Typography>
                            <Typography variant="caption">
                                {user.company}
                            </Typography>
                        </Box>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
            {
                internalChatDialogOpen &&
                <InternalChatDialog
                    open={internalChatDialogOpen}
                    setOpen={setInternalChatDialogOpen}
                    usersInternalCommunication={usersInternalCommunication}
                />
            }
        </Box>
    );
}