// React
import React, { useEffect, useState, useRef } from 'react';

// Design
import { Box, Typography } from '@mui/material';

// Internal
import API from '../../utils/api';
import createAccess from '../../utils/functions/createAccess';

// Third-party
import { ResponsivePie } from '@nivo/pie';

export default function CountReturnsGraph({ user }) {

  const timeToUpdate = 60000 * 1 * 3;

  // eslint-disable-next-line
  const [labels, setLabels] = useState(['Ativo', 'Receptivo']);
  const [totalCount, _setTotalCount] = useState([]);
  const totalCountRef = useRef([]);
  const setTotalCount = (newValue) => {
    _setTotalCount(newValue);
    totalCountRef.current = newValue;
  }
  const [newFromMeCount, setNewFromMeCount] = useState({
    id: 'Ativo',
    label: 'newFromMe',
    value: 0,
    color: '#ffea00'
  });
  const [newNotFromMeCount, setNewNotFromMeCount] = useState({
    id: 'Receptivo',
    label: 'newNotFromMe',
    value: 0,
    color: '#ff1744'
  });
  const [oldFromMeCount, setOldFromMeCount] = useState({
    id: 'Ativo',
    label: 'oldFromMe',
    value: 0,
    color: '#ffea00'
  });
  const [oldNotFromMeCount, setOldNotFromMeCount] = useState({
    id: 'Receptivo',
    label: 'oldNotFromMe',
    value: 0,
    color: '#ff1744'
  });

  const data = [oldFromMeCount, oldNotFromMeCount];

  const handleGetInfo = (totalCount) => {
    let access_click2chat = createAccess(user);
    let filterLess = new Date();
    let filter = `${filterLess.getFullYear()}-${String(filterLess.getMonth() + 1).length === 1 ? '0' : ''}${filterLess.getMonth() + 1}-${String(filterLess.getDate()).length === 1 ? '0' : ''}${filterLess.getDate()}`

    API.get.returnsConsolidation(access_click2chat, filter)
      .then((response) => {
        if (response.status === 200) {
          let _newFromMeCount = 0;
          let _oldFromMeCount = 0;
          let _newNotFromMeCount = 0;
          let _oldNotFromMeCount = 0;
          let _totalCount = 0;
          let temp = response.data.info.list_chats;

          _newFromMeCount += temp?.['newFromMe'] ?? 0;
          _oldFromMeCount += temp?.['oldFromMe'] ?? 0;
          _newNotFromMeCount += temp?.['newNotFromMe'] ?? 0;
          _oldNotFromMeCount += temp?.['oldNotFromMe'] ?? 0;
          _totalCount += temp?.['total'] ?? 0;
          if (_totalCount !== totalCountRef.current) {
            // setLabels(Object.keys(response.data.info.list_messages));
            setNewFromMeCount({ ...newFromMeCount, value: _newFromMeCount });
            setOldFromMeCount({ ...oldFromMeCount, value: _oldFromMeCount });
            setNewNotFromMeCount({ ...newNotFromMeCount, value: _newNotFromMeCount });
            setOldNotFromMeCount({ ...oldNotFromMeCount, value: _oldNotFromMeCount });
            setTotalCount(_totalCount);
          }
        }
        else {
          //   setChatsListRequest([])
        }
      })
  }

  useEffect(() => {
    handleGetInfo(totalCount);
    const interval = setInterval(() => {
      handleGetInfo(totalCountRef.current);
    }, timeToUpdate);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);


  return (
    // <>
    //   <Pie data={data} options={options} />
    //   <hr />
    //   {/* <Typography>
    //     Ativo Novo: {newFromMeCount}
    //   </Typography> */}
    //   <Typography>
    //     Ativo: {oldFromMeCount}
    //   </Typography>
    //   {/* <hr /> */}
    //   {/* <Typography>
    //     Receptivo Novo: {newNotFromMeCount}
    //   </Typography> */}
    //   <Typography>
    //     Receptivo: {oldNotFromMeCount}
    //   </Typography>
    //   <hr />
    //   <Typography>
    //     Total Retornos: {oldNotFromMeCount + oldFromMeCount}
    //   </Typography>
    //   <hr />
    //   <Typography>
    //     Total Ativo: {newFromMeCount + oldFromMeCount}
    //   </Typography>
    //   <Typography>
    //     Total Receptivo: {newNotFromMeCount + oldNotFromMeCount}
    //   </Typography>
    //   <hr />
    //   <Typography>
    //     Total Geral: {newFromMeCount + newNotFromMeCount + oldNotFromMeCount + oldFromMeCount}
    //   </Typography>
    // </>
    <Box
      display={'flex'}
      height={'450px'}
      width={'450px'}
      flexDirection={'column'}
    >
      <Typography variant={'h6'} textAlign={'center'}>
        Retornos
      </Typography>
      <ResponsivePie
        data={data}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              2
            ]
          ]
        }}
        colors={{ datum: 'data.color' }}
        margin={{ top: 40, right: 80, bottom: 80, left: 100 }}
      />
    </Box>
  );
}