// React
import React, { useEffect, useState } from 'react';

// Design
import {
  Box,
  //  Grid
} from '@mui/material';


// Internal
// import {
//   appUser,
// } from '../../../../../../store/app/slice';

// Third-party
// import { useSelector } from 'react-redux';


export default function Tables({ props }) {

  // startDate, endDate, search, setSearch,
  // const user = useSelector(appUser);
  // eslint-disable-next-line
  const [loadingGraphs, setLoadingGraphs] = useState([false, false, false, false]);


  useEffect(() => {
    if (props.search) {
      setLoadingGraphs([true, true, true, true]);
    }
  }, [props.search])


  return (
    <Box
      sx={{
        width: 'inherit',
        height: 'calc(inherit / 3)',
      }}
    >
    </Box>
  )
}