// React
import React, { useEffect, useState } from "react";

// Design
import {
  Box,
  //  Grid
} from "@mui/material";

// Internal
import { appUser } from "../../../../../../store/app/slice";
import createAccess from "../../../../../../utils/functions/createAccess";
import getQueryDateString from "../../../../../../utils/functions/getQueryDateString";
import API from "../../../../../../utils/api";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

// Third-party
import { useSelector } from "react-redux";

const columns = [
  { field: "id", headerName: "ID", width: 150, sortable: false },
  { field: "event_time", headerName: "Horário", width: 250, sortable: true },
  {
    field: "phone_number",
    headerName: "Telefone",
    width: 150,
  },
  {
    field: "event_type",
    headerName: "Evento",
    width: 150,
    editable: false,
    sortable: false,
  },
  {
    field: "username",
    headerName: "Usuário",
    width: 300,
    editable: false,
    sortable: false,
  },
  {
    field: "team",
    headerName: "Time",
    width: 300,
    editable: false,
    sortable: true,
  },
  {
    field: "closed_reason_id",
    headerName: "Motivo de Encerramento",
    width: 300,
    editable: false,
    sortable: true,
  },
  {
    field: "identifier",
    headerName: "Identificador",
    width: 100,
    editable: false,
    sortable: true,
  },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function ServiceTickets({ props }) {
  // startDate, endDate, search, setSearch,
  // const user = useSelector(appUser);
  // eslint-disable-next-line

  const [statusList, setStatusList] = useState([]);
  const user = useSelector(appUser);

  const handleGetInfo = (startDate, endDate) => {
    let access_click2chat = createAccess(user);
    let startDateLess = getQueryDateString(new Date(startDate), 1, "start");
    let endDateLess = getQueryDateString(new Date(endDate), 1, "end");

    let filter = JSON.stringify({ startDateLess, endDateLess });
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
      timeZone: "America/Sao_Paulo",
    };

    API.get
      .serviceTicketsSupervision(access_click2chat, filter)
      .then((response) => {
        // console.log(response)
        if (response.status === 200) {
          let STATUS = {
            fromLine: "Veio da Fila",
            closed: "Encerrado",
            forwarded: "Encaminhado",
            backToLineByInactivity: "Voltou para a Fila por Inatividade",
            scheduled: "Agendado",
            fromActiveVM: "Veio do Massivo",
          };
          let WORKER = {
            operator: "Operador",
            supervisor: "Supervisor",
          };
          let users = response?.data?.users ?? [];
          users.push({ _id: "system", username: "Sistema" });
          let closedReasonList = response?.data?.closeReasonList ?? [];
          closedReasonList.push({ id: "inactivity", reason: "Inatividade" });
          // console.log({users})
          let _statusList = response.data.info.map((el, count) => {
            let user =
              users.find(
                (user) => String(user._id) === String(el.operator_id)
              ) ?? {};
            let closedReason =
              closedReasonList.find(
                (cR) => String(cR.id) === String(el.closed_reason_id)
              ) ?? {};

            let allStatus = el;
            el.id = el._id;
            el.phone_number = el?.phone_number ?? "";
            // el.status = STATUS[el.status];
            el.username = user?.username;
            el.team = user?.team_name;
            el.event_type = STATUS?.[el.event_type]
              ? STATUS?.[el.event_type]
              : el?.event_type;
            el.closed_reason_id = closedReason?.reason ?? el?.closed_reason_id;
            // el.worker = user?.worker ? WORKER[user?.worker] : "";
            el.event_time = Intl.DateTimeFormat("pt-BR", options).format(
              new Date(el.event_time)
            );
            return allStatus;
          });
          setStatusList(_statusList);
        } else {
          setStatusList([]);
        }
      })
      .finally(() => {
        // setOperatorEditDialogShow(false);
        props?.setSearch(false);
      });
  };

  useEffect(() => {
    if (props?.startDate && props?.endDate && props?.search) {
      handleGetInfo(props?.startDate, props?.endDate);
    }
    // eslint-disable-next-line
  }, [props?.search]);
  return (
    <Box
      sx={{
        width: "inherit",
        height: "calc(inherit / 3)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          overflowY: "auto",
          width: "100%",
          height: "calc(100%)",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <div style={{ height: 850, width: "100%" }}>
          <DataGrid
            rows={statusList}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[30]}
            // checkboxSelection
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </div>
      </Box>
    </Box>
  );
}
