import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function ContactsList({ contacts }) {
  return (
    <Table sx={{ minWidth: 650 }}>
      <TableHead>
        <TableRow>
          <TableCell align="center">Time</TableCell>
          <TableCell align="center">Telefone</TableCell>
          <TableCell align="center">Mensagem</TableCell>
          <TableCell align="center">Status da Linha</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {contacts.map((contact, index) => (
          <TableRow
            key={`${contact.phone}-${index}`}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell align="center">{contact.team}</TableCell>
            <TableCell align="center">{contact.phone}</TableCell>
            <TableCell align="center">{contact.text}</TableCell>
            <TableCell align="center">{contact?.valid ? "✅" : "❌"}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
