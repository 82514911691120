// React
import React, { useState, useEffect } from 'react';

// Design
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { IconButton, Tooltip } from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';

// Internal
// Third-party

// https://codesandbox.io/s/81zkxw8qnl?file=/src/index.tsx:153-228

// const options = { mimeType: "audio/ogg; codecs=vorbis" }



const useRecorder = () => {
  const [audioURL, setAudioURL] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [base64, setBase64] = useState('');

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error);
      }
      return;
    }

    // Manage recorder state.
    if (isRecording) {
      recorder.start();
    } else {
      recorder.stop();
    }

    // Obtain the audio when ready.
    const handleData = e => {
      setAudioURL(URL.createObjectURL(e.data));
      const reader = new FileReader();
      reader.readAsDataURL(e.data); 
      reader.onloadend = function() {
        var base64data = reader.result;                
        setBase64(base64data)
      }
    };

    recorder.addEventListener("dataavailable", handleData);
    return () => recorder.removeEventListener("dataavailable", handleData);
  }, [recorder, isRecording]);

  useEffect(() => {
    if (!audioURL) {
      setBase64("")
    }
  }, [audioURL])

  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  return [audioURL, isRecording, startRecording, stopRecording, setAudioURL, base64];
};

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  let recorder = new MediaRecorder(stream);
  return recorder;
}

export default function RecordAudioDialog({
  open, 
  setOpen,
  handleSendAudio,
  blockSendingAudio,
}) {

  let [audioURL, isRecording, startRecording, stopRecording, setAudioURL, base64] = useRecorder();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Gravar áudio</DialogTitle>
        <DialogContent>
            { !isRecording && !audioURL &&
              <Tooltip title="Gravar áudio">
                <IconButton onClick={startRecording}>
                  <FiberManualRecordIcon color="error"/>
                </IconButton>
              </Tooltip>
            }
            { isRecording &&
              <Tooltip title="Parar gravação">
                <IconButton onClick={stopRecording}>
                  <StopIcon />
                </IconButton>
              </Tooltip>
            }
            {audioURL ?
              <>
                <audio controls>
                  <source src={audioURL} type="audio/ogg" />
                  Your browser does not support the audio element.
                </audio>
                <Tooltip title="Apagar gravação">
                  <IconButton onClick={() => setAudioURL("")}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
              : 
              ""
            }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          {audioURL && 
            <Button 
              onClick={() => handleSendAudio({base64})} 
              color="primary"
              disabled={blockSendingAudio}
            >
              <SendIcon />
            </Button>
          }

        </DialogActions>
      </Dialog>
    </div>
  );
}