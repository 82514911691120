// React
import React, { useEffect, useState } from 'react';

// Design
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

// Internal
import createAccess from '../../utils/functions/createAccess';
import API from '../../utils/api';

// Third-party


export default function PauseDialog({ 
  open, 
  setOpen,
  user,
  statusPause,
}) {
 
  const [pausesList, setPausesList] = useState([]);
  const [selectedPause, setSelectedPause] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const handleGetPauses = () => {
    let access_click2chat = createAccess(user);
    API.get.pausesList(access_click2chat)
      .then((response) => {
        if (response.status === 200) {
          let pauses = response?.data?.info ?? null;
          if (pauses) {
            setPausesList(pauses)
          }
        }
        else {
          // setCurrentList([])
        }
      })
      .finally(() => {
      })
  }

  const handleChangePause = (event) => {
    let pauseId = event.target.value;
    setSelectedPause(pauseId);
  }

  const handlePause = (pauseId) => {
    let access_click2chat = createAccess(user);
    API.put.pauseOperator(access_click2chat, true, pauseId)
    .then((response) => {
      if (response.status === 200) {
      }
      else {
        // setCurrentList([])
      }
    })
    .finally(() => {
      handleClose()
    })
  }

  useEffect(() => {
    if (open) {
      handleGetPauses();
    }
    else {
      setPausesList([]);
    }
    // eslint-disable-next-line
  }, [open])

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            sx={{
              display: 'flex',
              height: '2rem',
              width: '30rem',
              // alignItems: 'flex-start',
              justifyContent: 'flex-start',
              margin: '0 0 0 0',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifySelf: 'flex-start',
                justifyContent: 'space-between'
              }}
            >
              Pausa
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" sx={{flexDirection: 'column'}}>
            <Box
              display="flex"
              sx={{
                flexDirection: 'row'
              }}
            >
              <Box display="flex" sx={{ width: 280 }}>
                <Select
                  value={selectedPause}
                  sx={{ width: 200 }}
                  onChange={(event) => handleChangePause(event)}
                >
                  {
                    pausesList.map(el => {
                      return(
                        <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                      )
                    })
                  }
                </Select>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={() => handlePause(selectedPause)} color="primary" disabled={!selectedPause} autoFocus>
            Pausar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}