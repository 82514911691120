// React
import React, { useEffect, useState } from 'react';

// Design
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { Tooltip } from '@mui/material';

// Internal
import { 
  appUser, 
} from '../../store/app/slice';
import API from '../../utils/api';
import createAccess from '../../utils/functions/createAccess';

// Third-Party
import { useSelector } from 'react-redux';


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    // marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ScheduleChatDialog({ open, setOpen, phone_number }) {
  const classes = useStyles();
  const [schedule, setSchedule] = useState([]);

  const user = useSelector(appUser);

  const handleGetSchedule = () => {
    let access_click2chat = createAccess(user);
    API.get.scheduleChat(access_click2chat, phone_number)
      .then((response) => {
        if (response.status === 200) {
          setSchedule(response.data.info);
        } 
        else {
          setSchedule([]);
        }
      })
  };

  const handleDeleteReminder = (reminder_id) => {
    let access_click2chat = createAccess(user);
    API.delete.schedule(access_click2chat, reminder_id)
    .then(response => {
        if (response?.ok && response?.status === 202) {
          API.get.scheduleChat(access_click2chat, phone_number)
          .then((response) => {
            if (response.status === 200) {
              setSchedule(response.data.info);
            } 
            else {
              setSchedule([]);
            }
          })
        }
    })
    .finally(() => {
    })
  }

  const formatDate = (_date) => {
    _date = (String(_date)
            .replace(':00 GMT-0300 (Horário Padrão de Brasília)', '')
            .replace('Sun', 'Domingo,')
            .replace('Mon', 'Segunda-feira,')
            .replace('Tue', 'Terça-feira,')
            .replace('Wed', 'Quarta-feira,')
            .replace('Thu', 'Quinta-feira,')
            .replace('Fri', 'Sexta-feira,')
            .replace('Sat', 'Sábado,')
            .replace('Jan', 'Janeiro')
            .replace('Feb', 'Fevereiro')
            .replace('Mar', 'Março')
            .replace('Apr', 'Abril')
            .replace('May', 'Maio')
            .replace('Jun', 'Junho')
            .replace('Jul', 'Julho')
            .replace('Aug', 'Agosto')
            .replace('Sep', 'Setembro')
            .replace('Oct', 'Outubro')
            .replace('Nov', 'Novembro')
            .replace('Dec', 'Dezembro')
            )
    return _date
  }

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      handleGetSchedule()
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Agendamentos
            </Typography>
            <Tooltip title="Fechar Janela" placement="bottom-end">
              <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        {
          schedule.length > 0 &&
          <List>
            {
              schedule.map((el, index) => (
                <ListItem key={`schedule-${index}`}>
                  {
                    el.file_id ? 
                    <Tooltip title="Arquivo Anexado" placement="bottom-end">
                      <AttachmentIcon /> 
                    </Tooltip>
                    : 
                    ''
                  }
                  <ListItemText primary={`${formatDate(new Date(el.date))} - ${el.operator_id} ${el.owner ? '✅' : '❌'}`} secondary={el.msg} />
                  {
                    el.owner &&
                    <Tooltip title="Apagar Agendamento" placement="right-start">
                      <IconButton edge="end" color="inherit" onClick={() => handleDeleteReminder(String(el._id))} aria-label="close">
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </ListItem>
              ))
            }
          </List>
        }
      </Dialog>
    </div>
  );
}