// React
import React, { useState, useEffect } from "react";

// Design
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { green, red } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";

// Internal
import Dropzone from "../Surfaces/dropzone";

// Third-party
import PropTypes from "prop-types";
import { DialogContent } from "@mui/material";

function DialogRenderized(props) {
  const {
    onClose,
    selectedValue,
    open,
    removeFileFromUpload,
    loadingSending,
    handleSendFiles,
    // eslint-disable-next-line
    acceptedtFileTypes,
    onDropFile,
    onChangeFile,
  } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        Enviar arquivo(s)
        <Tooltip title="Fechar">
          <IconButton
            edge="end"
            sx={{
              position: "absolute",
              right: 15,
              top: 8,
              // color: (theme) => theme.palette.grey[500],
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <Dropzone
          onDrop={onDropFile}
          onChange={onChangeFile}
          acceptedtFileTypes={acceptedtFileTypes}
          //   openWindowToSelectFiles={true}
        >
          <Dropzone
            onDrop={onDropFile}
            onChange={onChangeFile}
            acceptedtFileTypes={acceptedtFileTypes}
            openWindowToSelectFiles={true}
          >
            <Tooltip title="Adicionar Arquivos" placement="left">
              <center>
                <IconButton>
                  <AddIcon />
                </IconButton>
              </center>
            </Tooltip>
          </Dropzone>

          <List sx={{ pt: 0 }}>
            {selectedValue &&
              selectedValue.acceptedFiles.length > 0 &&
              selectedValue.acceptedFiles.map((f, index) => (
                <ListItem key={`${index}-${f.name || f.path}`}>
                  <Tooltip title="Tipo de arquivo aceito">
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: green[100], color: green[600] }}>
                        <CheckIcon />
                      </Avatar>
                    </ListItemAvatar>
                  </Tooltip>
                  <ListItemText primary={f.name || f.path} />
                  {f.status && f.status === "loading" && (
                    <Tooltip title="Enviando arquivo">
                      <CircularProgress />
                    </Tooltip>
                  )}
                  {f.status && f.status === "done" && (
                    <Tooltip title="Arquivo enviado">
                      <CheckIcon color="success" />
                    </Tooltip>
                  )}
                  {f.status && f.status === "failed" && (
                    <Tooltip title="Falha no envio">
                      <CloseIcon color="error" />
                    </Tooltip>
                  )}
                  {!f.status && !loadingSending && (
                    <Tooltip title="Excluir arquivo">
                      <IconButton
                        onClick={() =>
                          removeFileFromUpload(index, "acceptedFiles")
                        }
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  )}
                </ListItem>
              ))}
            {selectedValue &&
              selectedValue.rejectedFiles.length > 0 &&
              selectedValue.rejectedFiles.map((f, index) => (
                <ListItem key={`${index}-${f.file.name || f.file.path}`}>
                  <Tooltip title="Tipo de arquivo não aceito">
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: red[100], color: red[600] }}>
                        <CloseIcon />
                      </Avatar>
                    </ListItemAvatar>
                  </Tooltip>
                  <ListItemText primary={f.file.name || f.file.path} />
                  <Tooltip title="Excluir arquivo">
                    <IconButton
                      onClick={() =>
                        removeFileFromUpload(index, "rejectedFiles")
                      }
                    >
                      <CloseIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </ListItem>
              ))}
            {/* <ListItem
                // button 
                // onClick={() => handleSendFiles()}
            >
                ok
            </ListItem> */}

            <ListItem
              autoFocus
              button
              onClick={() => handleSendFiles()}
              disabled={
                !selectedValue ||
                (Array.isArray(selectedValue.acceptedFiles) &&
                  selectedValue.acceptedFiles.length === 0) ||
                loadingSending ||
                (Array.isArray(selectedValue.acceptedFiles) &&
                  selectedValue.acceptedFiles.length > 0 &&
                  selectedValue.acceptedFiles.some((el) =>
                    ["loading", "done"].includes(el.status)
                  ))
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <SendIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Enviar arquivos" />
            </ListItem>
          </List>
        </Dropzone>
      </DialogContent>
    </Dialog>
  );
}

DialogRenderized.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  removeFileFromUpload: PropTypes.func.isRequired,
  handleSendFiles: PropTypes.func.isRequired,
  selectedValue: PropTypes.object,
  loadingSending: PropTypes.bool,
  acceptedtFileTypes: PropTypes.array,
  onDropFile: PropTypes.func.isRequired,
  onChangeFile: PropTypes.func.isRequired,
};

export default function FilesDialog({
  open,
  setOpen,
  filesToUpload,
  onDropFile,
  onChangeFile,
  acceptedtFileTypes,
  removeFileFromUpload,
  handleSendFile,
  selectedChatId,
}) {
  const [selectedValue, setSelectedValue] = useState(null);
  const [loadingSending, setLoadingSending] = useState(false);

  const handleSendFiles = async () => {
    setLoadingSending(true);
    for (let i = 0; i <= selectedValue.acceptedFiles.length - 1; i++) {
      handleSendFile({
        chat_id: selectedChatId,
        resend: false,
        fileIndex: i,
        sendMessage: true,
      });
    }
    setLoadingSending(false);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  useEffect(() => {
    if (open) {
      setSelectedValue(filesToUpload);
    } else {
      setSelectedValue(null);
      setLoadingSending(false);
    }
    // eslint-disable-next-line
  }, [open, filesToUpload]);

  return (
    <DialogRenderized
      selectedValue={selectedValue}
      open={open}
      onClose={handleClose}
      removeFileFromUpload={removeFileFromUpload}
      handleSendFiles={handleSendFiles}
      loadingSending={loadingSending}
      acceptedtFileTypes={acceptedtFileTypes}
      onDropFile={onDropFile}
      onChangeFile={onChangeFile}
    />
  );
}
