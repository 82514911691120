// React
import React from 'react';

// Design
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';

// Internal
import createAccess from '../../utils/functions/createAccess';
import API from '../../utils/api';

// Third-party


export default function LeavePauseDialog({ 
  open, 
  setOpen,
  user,
}) {

  const handleClose = () => {
    setOpen(false);
  };

  const handlePause = () => {
    let access_click2chat = createAccess(user);
    API.put.pauseOperator(access_click2chat, false)
    .then((response) => {
      if (response.status === 200) {
      }
      else {
        // setCurrentList([])
      }
    })
    .finally(() => {
      handleClose()
    })
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            sx={{
              display: 'flex',
              height: '2rem',
              width: '30rem',
              // alignItems: 'flex-start',
              justifyContent: 'flex-start',
              margin: '0 0 0 0',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifySelf: 'flex-start',
                justifyContent: 'space-between'
              }}
            >
              Em Pausa
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePause} color="secondary" autoFocus>
            Sair da Pausa
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}