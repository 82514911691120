// React
import React, { useState, useEffect } from 'react';

// Design
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import { Box, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
// import { TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import DirectionsRailwayIcon from '@mui/icons-material/DirectionsRailway';



// Internal
import {
    appUser,
} from '../../store/app/slice';
import ChatCard from '../Cards/chatCard';
import MessageCard from '../Cards/messageCard';
import API from '../../utils/api';
import createAccess from '../../utils/functions/createAccess';
import openURI from '../../utils/functions/openURI';
import downloadURI from '../../utils/functions/downloadURI';
import b64ToBlob from '../../utils/functions/b64toBlob';
import PathDialog from './pathDialog';


// Third-Party
import { useSelector } from 'react-redux';
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const useStyles = makeStyles((theme) => ({
    appBar: {
        // position: 'relative',
        // marginBottom: '5rem'
        overflow: 'hidden',
        '@media print': {
            'display': 'block !important',
            'width': 'auto !important',
            'height': 'auto !important',
            'overflow': 'visible !important',
            'marginLeft': '0 !important',
            // 'pageBreakAfter': 'always !important',
        }
    },
    title: {
        // marginLeft: theme.spacing(2),
        flex: 1,
    },
    path: {
        marginTop: '4.5rem',
        width: 'calc(100% - 30rem)',
        height: '100%',
        display: 'flex',
        backgroundColor: 'blue',
        flexDirection: 'column',
        overflowY: 'auto',
        '@media print': {
            'display': 'block !important',
            'width': 'auto !important',
            'height': 'auto !important',
            'overflow': 'visible !important',
            'marginLeft': '0 !important',
            'pageBreakAfter': 'avoid !important',
        }
    },
    buttons: {
        '@media print': {
            'display': 'hide !important',
        }
    },
    childrenPath: {
        display: 'flex',
        width: '100%',
        // height: '50rem',
        alignItems: 'center',
        border: '0.09rem solid black',
        borderRadius: '1%',
        marginBottom: '0.5rem',
        '@media print': {
            'display': 'block !important',
            'width': 'auto !important',
            'height': 'auto !important',
            'overflow': 'visible !important',
            'marginLeft': '0 !important',
            'pageBreakAfter': 'always !important',
        }
    }

}));

function FilteredChatMenu({
    setOpenPathDialog
    , currentVM
}) {

    return (
        <Grid container justifyContent="start">
            <Grid item>
                <Tooltip title="Jornada do Cliente">
                    <span>
                        <IconButton
                            size="medium"
                            // disabled={filterName.length === 0}
                            onClick={() => setOpenPathDialog(true)}
                        >
                            <DirectionsRailwayIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </Grid>
            <Grid item>
                {currentVM}
            </Grid>
        </Grid>
    )
}

function FilteredChatMessages({
    messages = [],
    worker,
    chatId,
    phoneNumber,
    handleSendMessage,
    handleSendFile,
    handleLazyDownload,
    handleLazyOpen,
    files,
}) {

    useEffect(() => {

        if (messages.length > 0) {
            const theElement = document.getElementsByClassName('msgBox')[0];
            const scrollToBottom = (node) => {
                node.scrollTop = node.scrollHeight;
            }
            //   if (!noEmojiFlag) {
            //     scrollToBottom(theElement);
            //   }
            //   blobize();
            scrollToBottom(theElement);
            //   setLastMessageStatus(renderSelectedChatMsgs[renderSelectedChatMsgs.length -1].ack === 'line')
        }
        // eslint-disable-next-line
    }, [messages]);

    return (
        <Box
            display="flex"
            sx={{
                // height: '30rem',
                height: '100%',
                width: '100%',
                background: '#ECEFF1',
                overflowY: 'scroll',
                overflowX: 'hidden',
                flexDirection: 'column'
            }}
            className='msgBox'
        >
            {
                Array.isArray(messages) &&
                messages.map((message, index) => {
                    return (
                        <MessageCard
                            message={message}
                            index={index}
                            // user={user}
                            key={`message-card${index}`}
                            chatId={chatId}
                            phoneNumber={phoneNumber}
                            handleSendMessage={handleSendMessage}
                            handleSendFile={handleSendFile}
                            files={files}
                            handleLazyDownload={handleLazyDownload}
                            worker={worker}
                            handleLazyOpen={handleLazyOpen}
                        />
                    )
                })
            }
        </Box>

    );
}

function FilteredList({
    selectedChat,
    setSelectedChat,
    filteredChatsList,
}) {

    const Row = ({ index, style }) => (
        <div style={style} key={`interaction_list-${index}`}>
            <ChatCard
                index={index}
                selectedChatID={selectedChat && selectedChat.chat_id}
                listToUse={filteredChatsList}
                setSelectedChat={setSelectedChat}
            />
        </div>
    );

    return (
        <main style={{ height: '100%', width: '100%' }}>
            {
                filteredChatsList?.length > 0 ?
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                className="List"
                                height={height}
                                itemCount={filteredChatsList?.length}
                                itemSize={110}
                                width={width}
                            >
                                {Row}
                            </List>
                        )}
                    </AutoSizer>
                    :
                    <Box
                        display="flex"
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        Nenhuma conversa encontrada
                    </Box>
            }
        </main>
    )
}


export default function ContactsPerDateDialog({ open, setOpen }) {
    const classes = useStyles();
    // console.log()
    const [searchField, setSearchField] = useState('');
    const [chatsList, setChatsList] = useState([]);
    const [openPathDialog, setOpenPathDialog] = useState(false);
    const [path, setPath] = useState([]);
    // console.log({ searchField, s: new Date(searchField.$d).getDate() })

    const user = useSelector(appUser);

    const [selectedChat, setSelectedChat] = useState(null);
    const [messages, setMessages] = useState([]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelectChat = (chat) => {
        setPath([]);
        setMessages([]);
        setSelectedChat(chat);
    }

    const handleLazyDownload = async (media_id) => {
        let access_click2chat = createAccess(user);
        let file = await API.get.file(access_click2chat, media_id);
        if (file.ok && file.data && file.data.data) {
            let url = b64ToBlob(file.data.data.file, file.data.data.mime_type);
            let filename = file.data.data.filename;
            downloadURI(url, filename);
        };
    }

    const handleLazyOpen = async (media_id) => {
        let access_click2chat = createAccess(user);
        let file = await API.get.file(access_click2chat, media_id);
        if (file.ok && file.data && file.data.data) {
            let url = b64ToBlob(file.data.data.file, file.data.data.mime_type);
            openURI(url);
        };
    };

    const handleSearch = () => {
        let access_click2chat = createAccess(user);
        const searchDate = new Date(searchField);
        const searchDateFormatted = searchDate.toISOString().split('T')[0];
        API.get.serviceTicketsChatsByDate(access_click2chat, searchDateFormatted)
            .then(response => {
                if (response.ok) {
                    console.log({ data: response.data })
                    setChatsList(response?.data?.info?.chats ?? [])
                }
                else {
                    setChatsList([])
                }
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {

            })
    }

    useEffect(() => {
        if (!open) {
            setSelectedChat(null);
            searchField('');
            setChatsList([]);
            setMessages([]);
            setPath([]);
        }
        // eslint-disable-next-line
    }, [open]);

    useEffect(() => {
        if (openPathDialog) {
            let access_click2chat = createAccess(user);
            API.get.chatHistory(access_click2chat, selectedChat?.chat_id)
                .then(response => {
                    if (response.ok) {
                        setPath(response?.data?.info?.path ?? []);
                    }
                    else {
                        setPath([]);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {

                })
        }
        else {
            setPath([]);
        }
        // eslint-disable-next-line 
    }, [openPathDialog])

    useEffect(() => {
        if (selectedChat) {
            let access_click2chat = createAccess(user);
            API.get.chat(access_click2chat, selectedChat.chat_id)
                .then(response => {
                    if (response.ok) {
                        // console.log({ x: response.data })
                        setMessages(response?.data?.info?.chat ?? []);
                        // setChatsList(response?.data?.info?.chats ?? [])
                    }
                    else {
                        // setChatsList([])
                        setMessages([]);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {

                })
        }
        // eslint-disable-next-line
    }, [selectedChat])

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            fullScreen
            open={open}
            className={classes.appBar}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        Conversas por Data
                    </Typography>

                    <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close" className={classes.buttons}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box
                display="flex"
                sx={{
                    flexDirection: 'row',
                    width: '100%',
                    height: 'calc(100vh - 64px)',
                    marginTop: '64px',
                    // marginTop: '6vh'
                }}
            >
                <Box
                    display="flex"
                    sx={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '26rem',
                        height: 'calc(100%)',
                    }}
                >
                    <Box
                        display="flex"
                        sx={{
                            // flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '5rem',
                        }}
                    >
                        <Box>
                            {/* <TextField
                                id="standard-basic"
                                label="Nome/Número"
                                variant="outlined"
                                style={{ width: '19rem' }}
                                value={searchField}
                                onChange={e => setSearchField(e.target.value)}
                            /> */}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={searchField}
                                    onChange={(newValue) => setSearchField(newValue)}
                                    format='DD/MM/YYYY'
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box>
                            <Tooltip title="Procurar">
                                <span>
                                    <IconButton
                                        size="small"
                                        // disabled={searchField.length < 3}
                                        disabled={!searchField}
                                        onClick={() => handleSearch()}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Box>
                    </Box>

                    <Box
                        display="flex"
                        sx={{
                            width: '100%',
                            height: 'calc(100% - 5rem)',
                        }}
                    >
                        <FilteredList
                            setSelectedChat={handleSelectChat}
                            selectedChat={selectedChat}
                            filteredChatsList={chatsList}
                        />
                    </Box>
                </Box>
                <Box
                    display="flex"
                    sx={{
                        flexDirection: 'column',
                        width: 'calc(100% - 26rem)',
                        height: 'calc(100vh - 64px)',
                        // marginTop: '64px',
                        // marginTop: '6vh'
                    }}
                >
                    {
                        selectedChat ?
                            <>
                                <Box
                                    display="flex"
                                    sx={{
                                        flexDirection: 'column',
                                        width: '100%',
                                        height: '3rem',
                                        // marginTop: '64px',
                                        // marginTop: '6vh'
                                    }}
                                >
                                    <FilteredChatMenu
                                        setOpenPathDialog={setOpenPathDialog}
                                    />
                                </Box>
                                <FilteredChatMessages
                                    messages={messages}
                                    worker={user?.worker}
                                    chatId={selectedChat?.chat_id}
                                    phoneNumber={selectedChat?.phone_number}
                                    handleLazyDownload={handleLazyDownload}
                                    handleLazyOpen={handleLazyOpen}
                                />
                            </>
                            :
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    height: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                Nenhum chat selecionado
                            </Box>
                    }

                </Box>
            </Box>
            {
                selectedChat?.chat_id && path && openPathDialog &&
                <PathDialog
                    open={openPathDialog}
                    setOpen={setOpenPathDialog}
                    path={path}
                    phoneNumber={selectedChat.phone_number}
                />
            }
        </Dialog>
    );

}