// React
import React, { useEffect, useState } from "react";

// Design
import {
  Box,
  //  Grid
} from "@mui/material";

// Internal
import { appUser } from "../../../../../../store/app/slice";
import createAccess from "../../../../../../utils/functions/createAccess";
import getQueryDateString from "../../../../../../utils/functions/getQueryDateString";
import API from "../../../../../../utils/api";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

// Third-party
import { useSelector } from "react-redux";

const columns = [
  { field: "id", headerName: "ID", width: 250, sortable: false },
  { field: "timestamp", headerName: "Horário", width: 250, sortable: true },
  {
    field: "app_id",
    headerName: "API_ID",
    width: 300,
    editable: false,
    sortable: false,
  },
  {
    field: "type",
    headerName: "Tipo",
    width: 300,
    editable: false,
    sortable: true,
  },
  {
    field: "info",
    headerName: "Info",
    width: 700,
    editable: false,
    sortable: true,
  },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function _API({ props }) {
  // startDate, endDate, search, setSearch,
  // const user = useSelector(appUser);
  // eslint-disable-next-line

  const [statusList, setStatusList] = useState([]);
  const user = useSelector(appUser);

  const handleGetInfo = (startDate, endDate) => {
    let access_click2chat = createAccess(user);
    let startDateLess = getQueryDateString(new Date(startDate), 1, "start");
    let endDateLess = getQueryDateString(new Date(endDate), 1, "end");

    let filter = JSON.stringify({ startDateLess, endDateLess });
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
      timeZone: "America/Sao_Paulo",
    };

    API.get
      .apiCountUsageSupervision(access_click2chat, filter)
      .then((response) => {
        // console.log(response)
        if (response.status === 200) {
          let _statusList = response.data.info.map((el, count) => {
            let allStatus = el;
            el.id = el._id;
            el.info = JSON.stringify(el.info);
            el.timestamp = Intl.DateTimeFormat("pt-BR", options).format(
              new Date(el.timestamp)
            );
            return allStatus;
          });
          setStatusList(_statusList);
        } else {
          setStatusList([]);
        }
      })
      .finally(() => {
        // setOperatorEditDialogShow(false);
        props?.setSearch(false);
      });
  };

  useEffect(() => {
    if (props?.startDate && props?.endDate && props?.search) {
      handleGetInfo(props?.startDate, props?.endDate);
    }
    // eslint-disable-next-line
  }, [props?.search]);
  return (
    <Box
      sx={{
        width: "inherit",
        height: "calc(inherit / 3)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          overflowY: "auto",
          width: "100%",
          height: "calc(100%)",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <div style={{ height: 850, width: "100%" }}>
          <DataGrid
            rows={statusList}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[30]}
            // checkboxSelection
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </div>
      </Box>
    </Box>
  );
}
