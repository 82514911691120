// React
import React, { useEffect, useState } from 'react';

// Design
import { Box, Typography } from '@mui/material';

// Internal
import API from '../../../../../../../utils/api';
import createAccess from '../../../../../../../utils/functions/createAccess';
import getQueryDateString from '../../../../../../../utils/functions/getQueryDateString';


// Third-party
import { ResponsivePie } from '@nivo/pie';

export default function CountReturnsGraph({
  user
  , startDate
  , endDate
  , search
  , setSearch
}) {


  // eslint-disable-next-line
  const [labels, setLabels] = useState(['Ativo', 'Receptivo']);
  // eslint-disable-next-line
  const [totalCount, setTotalCount] = useState([]);
  const [newFromMeCount, setNewFromMeCount] = useState({
    id: 'Ativo',
    label: 'newFromMe',
    value: 0,
    color: '#ffea00'
  });
  const [newNotFromMeCount, setNewNotFromMeCount] = useState({
    id: 'Receptivo',
    label: 'newNotFromMe',
    value: 0,
    color: '#ff1744'
  });
  const [oldFromMeCount, setOldFromMeCount] = useState({
    id: 'Ativo',
    label: 'oldFromMe',
    value: 0,
    color: '#ffea00'
  });
  const [oldNotFromMeCount, setOldNotFromMeCount] = useState({
    id: 'Receptivo',
    label: 'oldNotFromMe',
    value: 0,
    color: '#ff1744'
  });

  const data = [oldFromMeCount, oldNotFromMeCount];

  const handleGetInfo = (startDate, endDate) => {
    let access_click2chat = createAccess(user);
    let startDateLess = getQueryDateString(new Date(startDate), 1, 'start');
    let endDateLess = getQueryDateString(new Date(endDate), 1, 'end');
    let filter = JSON.stringify({ startDateLess, endDateLess });

    API.get.returnsConsolidation(access_click2chat, filter)
      .then((response) => {
        if (response.status === 200) {
          let _newFromMeCount = 0;
          let _oldFromMeCount = 0;
          let _newNotFromMeCount = 0;
          let _oldNotFromMeCount = 0;
          let _totalCount = 0;
          let temp = response.data.info.list_chats;

          _newFromMeCount += temp?.['newFromMe'] ?? 0;
          _oldFromMeCount += temp?.['oldFromMe'] ?? 0;
          _newNotFromMeCount += temp?.['newNotFromMe'] ?? 0;
          _oldNotFromMeCount += temp?.['oldNotFromMe'] ?? 0;
          _totalCount += temp?.['total'] ?? 0;
          // if (_totalCount !== totalCountRef.current) {
          // setLabels(Object.keys(response.data.info.list_messages));
          setNewFromMeCount({ ...newFromMeCount, value: _newFromMeCount });
          setOldFromMeCount({ ...oldFromMeCount, value: _oldFromMeCount });
          setNewNotFromMeCount({ ...newNotFromMeCount, value: _newNotFromMeCount });
          setOldNotFromMeCount({ ...oldNotFromMeCount, value: _oldNotFromMeCount });
          setTotalCount(_totalCount);
          // }
        }
        else {
          //   setChatsListRequest([])
        }
      })
  }

  useEffect(() => {
    if (startDate && endDate && search) {
      handleGetInfo(startDate, endDate)
    }
    // eslint-disable-next-line
  }, [search]);


  return (
    <Box
      display={'flex'}
      height={'400px'}
      width={'450px'}
      flexDirection={'column'}
    >
      <Typography variant={'h6'} textAlign={'center'}>
        Retornos
      </Typography>
      <ResponsivePie
        data={data}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              2
            ]
          ]
        }}
        colors={{ datum: 'data.color' }}
        margin={{ top: 40, right: 80, bottom: 80, left: 100 }}
      />
    </Box>
  );
}