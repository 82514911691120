// React
import React, { useEffect, useState, useRef } from "react";

// Design
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import green from "@mui/material/colors/green";

// Internal
import API from "../../utils/api";
import createAccess from "../../utils/functions/createAccess";
import { Tooltip } from "@mui/material";

// Third-party
import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

const columns = [
  { id: "phone", label: "Telefone", maxWidth: 50 },
  { id: "vm_code", label: "VM", maxWidth: 20 },
  // { id: 'battery', label: 'Bateria', minWidth: 20 },
  // { id: 'charging', label: 'Carregando?', minWidth: 100 },
  { id: "in_team", label: "Time de Entrada", minWidth: 100 },
  { id: "out_team", label: "Time de Saída", minWidth: 100 },
  { id: "tag", label: "Tag", minWidth: 100 },
  { id: "line", label: "Fila de saída", minWidth: 100 },
  { id: "time_connection", label: "Hora", minWidth: 80 },
  { id: "last_status", label: "Status", minWidth: 100 },
  { id: "type", label: "Tipo", minWidth: 100 },
  { id: "active", label: "Ativo", minWidth: 80 },
  { id: "contacts", label: "Contatos", minWidth: 80 },
];

const useStyles = makeStyles({
  root: {
    // width: 'inherit',
  },
  container: {
    height: 150,
  },
});

export default function StatusVMSTable({ user, handleSelectVM, setVMSList }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);

  // eslint-disable-next-line
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [vmsListRequest, _setVMSListRequest] = useState([]);
  const vmsListRequestRef = useRef([]);
  const setVMSListRequest = (newValue) => {
    _setVMSListRequest(newValue);
    setVMSList(newValue);
    vmsListRequestRef.current = newValue;
  };

  const timeToUpdate = 25000;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClickVM = (id, vmsList) => {
    let selectedVM = vmsList.find((el) => el.vm_code === id);
    let teamIn = {
      id: selectedVM.in_team_id,
      name: selectedVM.in_team,
    };
    let teamOut = {
      id: selectedVM.out_team_id,
      name: selectedVM.out_team,
    };
    handleSelectVM(selectedVM.vm_code, teamIn, teamOut);
  };

  const handleGetInfo = () => {
    let access_click2chat = createAccess(user);

    API.get.vmsStatus(access_click2chat).then((response) => {
      if (response.status === 200) {
        // eslint-disable-next-line
        let filteredVMS =
          response && response.data && response.data.info
            ? response.data.info
            : [];
        setVMSListRequest(filteredVMS || []);
      } else {
        //   setChatsListRequest([])
      }
    });
  };

  useEffect(() => {
    handleGetInfo();
    const interval = setInterval(() => {
      handleGetInfo();
    }, timeToUpdate);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <TableContainer className={classes.container}>
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {vmsListRequest
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    onClick={() =>
                      handleClickVM(row["vm_code"], vmsListRequest)
                    }
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      if (column.id === "time_connection") {
                        let _value = value;
                        try {
                          _value = moment(new Date(value)).fromNow();
                        } catch {
                          _value = "";
                        }
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {_value}
                          </TableCell>
                        );
                      } else if (column.id === "line") {
                        let title = row?.nextMessage?.message ?? "Sem mensagem";
                        if (
                          title.includes("b61a1a33-cd75-4bb4-8bb8-87e84ccc1375")
                        ) {
                          title = "MENSAGEM DE MATURAÇÃO";
                        }
                        return (
                          <Tooltip
                            title={title}
                            placement="right-start"
                            key={column.id}
                          >
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          </Tooltip>
                        );
                      } else {
                        return typeof value === "boolean" ? (
                          <TableCell key={column.id} align={column.align}>
                            {value ? (
                              <FiberManualRecordIcon
                                style={{ color: green[500] }}
                              />
                            ) : (
                              <FiberManualRecordIcon color="error" />
                            )}
                          </TableCell>
                        ) : (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={vmsListRequest.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </>
  );
}
