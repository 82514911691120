// React
import React from "react";

// Design
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PersonIcon from "@mui/icons-material/Person";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";
import InboxIcon from "@mui/icons-material/Inbox";
import GroupsIcon from "@mui/icons-material/Groups";
import Badge from "@mui/material/Badge";
import BlockIcon from "@mui/icons-material/Block";
import DirectionsRun from "@mui/icons-material/DirectionsRun";

// Internal
import { appSupervision } from "../../../../../store/app/slice";

// Third-party
import { useSelector } from "react-redux";

export default function Filters({ setFilterType }) {
  const _appSupervision = useSelector(appSupervision);

  const handleSelectFilterType = (fT) => {
    // dispatch(setFilterType(fT))
    setFilterType(fT);
  };

  return (
    <Grid
      container
      spacing={1}
      justifyContent="space-around"
      sx={{ marginTop: "50px" }}
    >
      <Grid item>
        <Tooltip title="Caixa de Entrada Supervisor">
          <span>
            <IconButton
              disabled={_appSupervision?.filterType === "inbox"}
              onClick={() => handleSelectFilterType("inbox")}
            >
              <Badge badgeContent={0} color="primary">
                <InboxIcon />
              </Badge>
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title="Pesquisar por número/nome">
          <span>
            <IconButton
              disabled={_appSupervision?.filterType === "name"}
              onClick={() => handleSelectFilterType("name")}
            >
              <FontDownloadIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title="Pesquisar por conversas abertas (por operador)">
          <span>
            <IconButton
              onClick={() => handleSelectFilterType("opened")}
              disabled={_appSupervision?.filterType === "opened"}
            >
              <PersonIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title="Pesquisar por conversas encerradas (tabulação/operador)">
          <span>
            <IconButton
              onClick={() => handleSelectFilterType("closedXoperator")}
              disabled={_appSupervision?.filterType === "closedXoperator"}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title="Pesquisar por conversas favoritadas">
          <span>
            <IconButton
              onClick={() => handleSelectFilterType("starred")}
              disabled={_appSupervision?.filterType === "starred"}
            >
              <StarIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title="Pesquisar por times">
          <span>
            <IconButton
              // disabled={_appSupervision?.filterType === 'teams'}
              disabled={_appSupervision?.filterType === "teams"}
              onClick={() => handleSelectFilterType("teams")}
            >
              <GroupsIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title="Pesquisar por números bloqueados">
          <span>
            <IconButton
              // disabled={_appSupervision?.filterType === 'teams'}
              disabled
              onClick={() => handleSelectFilterType("blocked")}
            >
              <BlockIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title="Pesquisar por números nas filas">
          <span>
            <IconButton
              // disabled={_appSupervision?.filterType === 'teams'}
              disabled={_appSupervision?.filterType === "lines"}
              onClick={() => handleSelectFilterType("lines")}
            >
              <DirectionsRun />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
