// React
import React from 'react';

// Design
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Internal

// Third-party

export default function UnblockOperatorDialog({ 
  open, 
  setOpen, 
  username, 
  handleUnblockOperator,
}) {

  const handleClose = () => {
    setOpen(false);
  };

  const handleUnblockAndClose = () => {
    handleUnblockOperator();
    setOpen(false);
  }


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Desbloquear operador?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {username}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Não Desbloquear
          </Button>
          <Button onClick={handleUnblockAndClose} color="primary" autoFocus>
            Desbloquear
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}