// React
import React, { useEffect, useState, useRef } from "react";

// Design
import { Box, Typography } from "@mui/material";

// Internal
import API from "../../utils/api";
import createAccess from "../../utils/functions/createAccess";

// Third-party
// import { Line } from 'react-chartjs-2';
import { ResponsiveLine } from "@nivo/line";

export default function CountMessagesGraph({ user }) {
  const timeToUpdate = 60000 * 1 * 3;
  const [totalCount, _setTotalCount] = useState({
    id: "total",
    color: "hsl(240, 100%, 50%)",
    data: [
      {
        x: "00:00",
        y: 0,
      },
      {
        x: "01:00",
        y: 0,
      },
      {
        x: "02:00",
        y: 0,
      },
      {
        x: "03:00",
        y: 0,
      },
      {
        x: "04:00",
        y: 0,
      },
      {
        x: "05:00",
        y: 0,
      },
      {
        x: "06:00",
        y: 0,
      },
      {
        x: "07:00",
        y: 0,
      },
      {
        x: "08:00",
        y: 0,
      },
      {
        x: "09:00",
        y: 0,
      },
      {
        x: "10:00",
        y: 0,
      },
      {
        x: "11:00",
        y: 0,
      },
      {
        x: "12:00",
        y: 0,
      },
      {
        x: "13:00",
        y: 0,
      },
      {
        x: "14:00",
        y: 0,
      },
      {
        x: "15:00",
        y: 0,
      },
      {
        x: "16:00",
        y: 0,
      },
      {
        x: "17:00",
        y: 0,
      },
      {
        x: "18:00",
        y: 0,
      },
      {
        x: "19:00",
        y: 0,
      },
      {
        x: "20:00",
        y: 0,
      },
      {
        x: "21:00",
        y: 0,
      },
      {
        x: "22:00",
        y: 0,
      },
      {
        x: "23:00",
        y: 0,
      },
    ],
  });
  const totalCountRef = useRef({
    id: "total",
    color: "hsl(240, 100%, 50%)",
    data: [
      {
        x: "00:00",
        y: 0,
      },
      {
        x: "01:00",
        y: 0,
      },
      {
        x: "02:00",
        y: 0,
      },
      {
        x: "03:00",
        y: 0,
      },
      {
        x: "04:00",
        y: 0,
      },
      {
        x: "05:00",
        y: 0,
      },
      {
        x: "06:00",
        y: 0,
      },
      {
        x: "07:00",
        y: 0,
      },
      {
        x: "08:00",
        y: 0,
      },
      {
        x: "09:00",
        y: 0,
      },
      {
        x: "10:00",
        y: 0,
      },
      {
        x: "11:00",
        y: 0,
      },
      {
        x: "12:00",
        y: 0,
      },
      {
        x: "13:00",
        y: 0,
      },
      {
        x: "14:00",
        y: 0,
      },
      {
        x: "15:00",
        y: 0,
      },
      {
        x: "16:00",
        y: 0,
      },
      {
        x: "17:00",
        y: 0,
      },
      {
        x: "18:00",
        y: 0,
      },
      {
        x: "19:00",
        y: 0,
      },
      {
        x: "20:00",
        y: 0,
      },
      {
        x: "21:00",
        y: 0,
      },
      {
        x: "22:00",
        y: 0,
      },
      {
        x: "23:00",
        y: 0,
      },
    ],
  });
  const setTotalCount = (newValue) => {
    _setTotalCount(newValue);
    totalCountRef.current = newValue;
  };
  const [fromMeCount, setFromMeCount] = useState({
    id: "sistema",
    color: "hsl(0, 100%, 50%)",
    data: [
      {
        x: "00:00",
        y: 0,
      },
      {
        x: "01:00",
        y: 0,
      },
      {
        x: "02:00",
        y: 0,
      },
      {
        x: "03:00",
        y: 0,
      },
      {
        x: "04:00",
        y: 0,
      },
      {
        x: "05:00",
        y: 0,
      },
      {
        x: "06:00",
        y: 0,
      },
      {
        x: "07:00",
        y: 0,
      },
      {
        x: "08:00",
        y: 0,
      },
      {
        x: "09:00",
        y: 0,
      },
      {
        x: "10:00",
        y: 0,
      },
      {
        x: "11:00",
        y: 0,
      },
      {
        x: "12:00",
        y: 0,
      },
      {
        x: "13:00",
        y: 0,
      },
      {
        x: "14:00",
        y: 0,
      },
      {
        x: "15:00",
        y: 0,
      },
      {
        x: "16:00",
        y: 0,
      },
      {
        x: "17:00",
        y: 0,
      },
      {
        x: "18:00",
        y: 0,
      },
      {
        x: "19:00",
        y: 0,
      },
      {
        x: "20:00",
        y: 0,
      },
      {
        x: "21:00",
        y: 0,
      },
      {
        x: "22:00",
        y: 0,
      },
      {
        x: "23:00",
        y: 0,
      },
    ],
  });
  const [notFromMeCount, setNotFromMeCount] = useState({
    id: "cliente",
    color: "hsl(147, 50%, 47%)",
    data: [
      {
        x: "00:00",
        y: 0,
      },
      {
        x: "01:00",
        y: 0,
      },
      {
        x: "02:00",
        y: 0,
      },
      {
        x: "03:00",
        y: 0,
      },
      {
        x: "04:00",
        y: 0,
      },
      {
        x: "05:00",
        y: 0,
      },
      {
        x: "06:00",
        y: 0,
      },
      {
        x: "07:00",
        y: 0,
      },
      {
        x: "08:00",
        y: 0,
      },
      {
        x: "09:00",
        y: 0,
      },
      {
        x: "10:00",
        y: 0,
      },
      {
        x: "11:00",
        y: 0,
      },
      {
        x: "12:00",
        y: 0,
      },
      {
        x: "13:00",
        y: 0,
      },
      {
        x: "14:00",
        y: 0,
      },
      {
        x: "15:00",
        y: 0,
      },
      {
        x: "16:00",
        y: 0,
      },
      {
        x: "17:00",
        y: 0,
      },
      {
        x: "18:00",
        y: 0,
      },
      {
        x: "19:00",
        y: 0,
      },
      {
        x: "20:00",
        y: 0,
      },
      {
        x: "21:00",
        y: 0,
      },
      {
        x: "22:00",
        y: 0,
      },
      {
        x: "23:00",
        y: 0,
      },
    ],
  });

  const data = [totalCount, fromMeCount, notFromMeCount];

  const handleGetInfo = (totalCount) => {
    let access_click2chat = createAccess(user);
    let filterLess = new Date();
    // filterLess.setHours(-3);
    let filter = `${filterLess.getFullYear()}-${
      String(filterLess.getMonth() + 1).length === 1 ? "0" : ""
    }${filterLess.getMonth() + 1}-${
      String(filterLess.getDate()).length === 1 ? "0" : ""
    }${filterLess.getDate()}`;
    API.get
      .messagesConsolidation(access_click2chat, filter)
      .then((response) => {
        if (response.status === 200) {
          let temp = response.data.info.list_messages;
          let _totalCount = totalCount;
          let _fromMeCount = fromMeCount;
          let _notFromMeCount = notFromMeCount;
          Object.keys(temp).forEach((_el) => {
            let reference = _el;
            console.log({ reference });
            _fromMeCount.data.forEach((el, index) => {
              if (`${el.x}:00` === reference) {
                _fromMeCount.data[index].y = temp[reference]?.fromMe ?? 0;
              }
            });
            _notFromMeCount.data.forEach((el, index) => {
              if (`${el.x}:00` === reference) {
                _notFromMeCount.data[index].y = temp[reference]?.notFromMe ?? 0;
              }
            });
            _totalCount.data.forEach((el, index) => {
              if (`${el.x}:00` === reference) {
                _totalCount.data[index].y =
                  temp[reference]?.fromMe + temp[reference]?.notFromMe;
              }
            });
          });
          // if (_totalReducer !== totalReducer) {

          //   setLabels(Object.keys(temp));
          setTotalCount(_totalCount);
          setFromMeCount(_fromMeCount);
          setNotFromMeCount(_notFromMeCount);
          // }
        } else {
        }
      });
  };

  useEffect(() => {
    handleGetInfo(totalCountRef.current);
    const interval = setInterval(() => {
      handleGetInfo(totalCountRef.current);
    }, timeToUpdate);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return (
    // <Line
    //   data={data}
    //   options={options}
    // />

    <Box
      display={"flex"}
      height={"450px"}
      width={"100%"}
      flexDirection={"column"}
    >
      <Typography variant={"h6"} textAlign={"center"}>
        Mensagens por hora
      </Typography>
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        pointSize={10}
        pointColor={{ from: "color" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        useMesh={true}
      />
    </Box>
  );
}
