// React
import React, { useState } from "react";

// Design
import Avatar from "@mui/material/Avatar";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import CardHeader from "@mui/material/CardHeader";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import AndroidIcon from "@mui/icons-material/Android";
import ForwardIcon from "@mui/icons-material/Forward";
import Card from "@mui/material/Card";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";
import StarIcon from "@mui/icons-material/Star";
import { Tooltip } from "@mui/material";
import { yellow, orange, red } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";

// Internal
import { appFetchingChat } from "../../store/app/slice";

// Third-party
import { useSelector } from "react-redux";
import Headset from "@mui/icons-material/Headset";

export default function ChatCard({
  index,
  selectedChatID,
  listToUse,
  setSelectedChat,
  picture,
}) {
  // const [profilePic, setProfilePic] = useState('');
  //   const [hasPicture, setHasPicture] = useState(true);

  const [mouseOverIndex, setMouseOverIndex] = useState(null);
  const fetchingChat = useSelector(appFetchingChat);

  const handleOverMouse = (index) => {
    setMouseOverIndex(index);
  };

  const handleLeaveMouse = () => {
    setMouseOverIndex(null);
  };

  const returnColor = (index, listToUse, selectedChatID, mouseOverIndex) => {
    if (selectedChatID === String(listToUse[index].chat_id)) {
      return "#283b3d";
    } else if (mouseOverIndex === index) {
      return "#075E54";
    } else {
      return "#128C7E";
    }
  };

  // useEffect(() => {
  //     if (picture) {
  //         if (!_appBlockedURLS.includes(picture)) {
  //             fetch(picture)
  //             .then(res => {
  //                 if (res.status !== 200) throw new Error('failed to fetch image resource');
  //                 return res.url;
  //             })
  //             .then(url => {
  //                 // setProfilePic(url)
  //                 setHasPicture(true);
  //             })
  //             .catch(err => {
  //                 // console.log({ err })
  //                 dispatch(setBlockedURLS(picture))
  //                 setHasPicture(false);
  //             })
  //         }
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [picture])

  return (
    <Tooltip
      title={
        listToUse[index]?.last_interaction?.body?.filename ??
        listToUse[index]?.last_interaction?.body
      }
      enterDelay={500}
      placement="right"
    >
      <Card
        key={index}
        style={{
          backgroundColor: returnColor(
            index,
            listToUse,
            selectedChatID,
            mouseOverIndex
          ),
          marginTop: "0.25rem",
          marginLeft: "0.25rem",
          height: "6.25rem",
          width: "calc(23.7rem)",
        }}
        onMouseOver={() => handleOverMouse(index)}
        onMouseLeave={() => handleLeaveMouse()}
        onClick={() => setSelectedChat(listToUse[index])}
      >
        <CardHeader
          avatar={
            <Avatar>
              <PersonIcon />
            </Avatar>
          }
          title={
            <div>
              <Typography style={{ position: "inherit" }} color="#d3d3d3">
                {listToUse[index]?.user_by_company ??
                  (listToUse[index].user || listToUse[index].phone_number)}
              </Typography>
              <Typography
                style={{ position: "inherit" }}
                variant="caption"
                color="#d3d3d3"
              >
                {listToUse[index].user ? listToUse[index].phone_number : ""}
              </Typography>
              {listToUse[index]?.new_interaction === "yes" ? (
                <NewReleasesIcon
                  style={{
                    color: red[500],
                  }}
                />
              ) : listToUse[index]?.new_interaction === "forwarded" ? (
                <ForwardIcon
                  style={{
                    color: orange[500],
                  }}
                />
              ) : listToUse[index]?.new_interaction === "robot" ? (
                <AndroidIcon
                  style={{
                    color: orange[500],
                  }}
                />
              ) : listToUse[index]?.new_interaction === "scheduled" ? (
                <EventAvailableIcon
                  style={{
                    color: orange[500],
                  }}
                />
              ) : listToUse[index]?.new_interaction === "queue" ? (
                <DirectionsRunIcon
                  style={{
                    color: red[500],
                  }}
                />
              ) : listToUse[index]?.new_interaction === "whisper" ? (
                <Headset
                  style={{
                    color: red[500],
                  }}
                />
              ) : (
                ""
              )}
              {listToUse[index]?.starred ? (
                <StarIcon style={{ color: yellow[500] }} />
              ) : (
                ""
              )}
              {fetchingChat === listToUse[index].chat_id && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    paddingRight: "2rem",
                    // right: '-50',
                    width: "100%",
                    height: "100%",
                    // backgroundColor: 'rgba(0,0,0,0.5)',
                    zIndex: "1",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </div>
          }
          subheader={
            <Box
              display="flex"
              sx={{
                width: "18rem",
                height: "1rem",
                overflow: "hidden",
              }}
            >
              <Typography variant="caption" color="#d3d3d3">
                {
                  // (listToUse[index]?.last_interaction?.body)?.toString().length >= 46
                  listToUse[index]?.last_interaction?.body?.filename
                    ? listToUse[index]?.last_interaction?.body?.filename
                        ?.length >= 46
                      ? listToUse[
                          index
                        ]?.last_interaction?.body?.filename?.substring(0, 45) +
                        "..."
                      : listToUse[index]?.last_interaction?.body?.filename
                    : listToUse[index]?.last_interaction?.body?.length >= 46
                    ? // false
                      listToUse[index]?.last_interaction?.body
                        ?.toString()
                        .substring(0, 45) + "..."
                    : listToUse[index]?.last_interaction?.body?.toString() || ""
                  // ''
                }
              </Typography>
            </Box>
          }
          style={{ cursor: "pointer" }}
        />
      </Card>
    </Tooltip>
  );
}
