// React
import React, { useState } from 'react';

// Design
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import StarIcon from '@mui/icons-material/Star';
import EventIcon from '@mui/icons-material/Event';
// import HistoryIcon from '@mui/icons-material/History';
import AddAlertOutlinedIcon from '@mui/icons-material/AddAlertOutlined';
import ForwardIcon from '@mui/icons-material/Forward';
import CloseIcon from '@mui/icons-material/Close';
import { green, yellow, red } from '@mui/material/colors';
import DirectionsRailwayIcon from '@mui/icons-material/DirectionsRailway';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import FaceIcon from '@mui/icons-material/Face';

// Internal

// Third-party

export default function ChatMenu({
    selectedChat,
    handleFavorite,
    setPathDialogOpen,
    setEditContactDialogOpen,
    setReminderDialogWithNumberShow,
    setNextOperatorDialog,
    lastMessageStatus,
    setCloseConfirmDialog,
    setScheduleChatDialogShow,
    setProfilePicOpen,
    operation,
}) {

    const [numberCopied, setNumberCopied] = useState(false);
    const [vmCopied, setVMCopied] = useState(false);

    const manageStatus = (status) => {
        const _status = {
            'not-verified': (
                <Tooltip title="Não verificado">
                    <IconButton size="small">
                        <WarningIcon
                            style={{
                                color: yellow[500],
                            }}
                        />
                    </IconButton>
                </Tooltip>
            ),
            'verified': (
                <Tooltip title="Verificado">
                    <IconButton size="small">
                        <CheckCircleIcon
                            style={{
                                color: green[500],
                            }}
                        />
                    </IconButton>
                </Tooltip>
            ),
            // 3: <DoneAllIcon color="primary" />,
            'does-not-exist': (
                <Tooltip title="Não existe">
                    <IconButton size="small">
                        <ErrorIcon
                            style={{
                                color: red[500],
                                fontSize: '20px'
                            }}
                        />
                    </IconButton>
                </Tooltip>
            ),
        }
        if (!Object.keys(_status).includes(String(status))) return _status[0]
        return _status[status]
    }
    const copyText = (text, icon) => {
        navigator.clipboard.writeText('');
        navigator.clipboard.writeText(text);

        if (icon === 'vm') {
            setTimeout(() => {
                setVMCopied(true);
            }, 0);
            setTimeout(() => {
                setVMCopied(false);
            }, 3000);
        }
        else if (icon === 'phone') {
            setTimeout(() => {
                setNumberCopied(true);
            }, 0);
            setTimeout(() => {
                setNumberCopied(false);
            }, 3000);
        }

    }

    return (
        <Box
            sx={{
                height: '5rem',
                width: '100%',
                background: '#BDBDBD',
                // color: 'black',
            }}
        >
            <Grid
                container
                justifyContent="flex-end"
            >
                <Grid item>
                    {vmCopied ?
                        <Tooltip title="Código VM copiado" placement="bottom-start">
                            <span>
                                <IconButton
                                    size="small"
                                >
                                    <WhatsAppIcon
                                        style={{ color: green[500] }}
                                    />
                                </IconButton>
                            </span>
                        </Tooltip>
                        :
                        <Tooltip title="Copiar Código VM" placement="bottom-start">
                            <span>
                                <IconButton
                                    size="small"
                                    onClick={() => copyText(selectedChat.last_interaction.vm_identifier, 'vm')}
                                >
                                    <WhatsAppIcon
                                        fontSize="inherit"
                                    />
                                </IconButton>
                            </span>
                        </Tooltip>
                    }
                    <Typography variant="body">
                        {selectedChat.last_interaction.vm_identifier}
                    </Typography>
                    <Typography variant="body">
                        {selectedChat?.vmTag ? `(${selectedChat?.vmTag})` : ''}
                    </Typography>
                </Grid>
                <Grid item>
                    <Tooltip title="Agendamentos da Conversa" placement="top-end">
                        <span>
                            <IconButton
                                size="medium"
                                onClick={() => setScheduleChatDialogShow(true)}
                            >
                                <EventIcon fontSize="inherit" />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Favoritar Conversa" placement="top-end">
                        <span>
                            <IconButton
                                size="medium"
                                onClick={() => handleFavorite(!selectedChat.last_interaction.starred, selectedChat.last_interaction.phone_number)}
                                disabled={operation?.chatsLists?.star?.length > operation?.maxStars ?? false}
                            >
                                <StarIcon
                                    style={{
                                        color: selectedChat.last_interaction.starred ? yellow[500] : 'inherit'
                                    }}
                                />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Fazer agendamento para conversa" placement="top-end">
                        <span>
                            <IconButton
                                size="medium"
                                onClick={() => setReminderDialogWithNumberShow(true)}
                            >
                                <AddAlertOutlinedIcon
                                    fontSize="inherit"
                                />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
                {
                    lastMessageStatus &&
                    <>
                        <Grid item>
                            <Tooltip title="Encaminhar Conversa" placement="top-end">
                                <span>
                                    <IconButton
                                        size="medium"
                                        onClick={() => setNextOperatorDialog(true)}
                                    >
                                        <ForwardIcon fontSize="inherit" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            {/* {
                            !lastMessageStatus && */}
                            <Tooltip title="Encerrar Conversa" placement="top-end">
                                <span>
                                    <IconButton
                                        size="medium"
                                        onClick={() => setCloseConfirmDialog(true)}
                                    >
                                        <CloseIcon fontSize="inherit" color="error" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Grid>
                    </>
                }
            </Grid>
            <Grid container>
                <Grid item>
                    <Tooltip title="Jornada do Cliente" placement="right-start">
                        <span>
                            <IconButton
                                size="small"
                                onClick={() => setPathDialogOpen(true)}
                            >
                                <DirectionsRailwayIcon fontSize="inherit" />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Editar Nome do Cliente" placement="bottom-start">
                        <span>
                            <IconButton
                                size="small"
                                onClick={() => setEditContactDialogOpen(true)}
                            >
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Typography variant="body">
                        {selectedChat.last_interaction.user}
                        {/* {selectedChat && selectedChat.phone_number.replace(/^55/, '')}&ensp;&ensp; */}
                    </Typography>
                </Grid>

                {selectedChat?.profile_pic_url &&
                    <Grid item>
                        <Tooltip title="Ver foto" placement="bottom-start">
                            <span>
                                <IconButton
                                    size="small"
                                    onClick={() => setProfilePicOpen(true)}
                                >
                                    <FaceIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>

                }

                <Grid item>
                    {numberCopied ?
                        <Tooltip title="Número Copiado" placement="bottom-start">
                            <span>
                                <IconButton
                                    size="small"
                                >
                                    <LocalPhoneIcon
                                        style={{ color: green[500] }}
                                    />
                                </IconButton>
                            </span>
                        </Tooltip>
                        :
                        <Tooltip title="Copiar Número" placement="bottom-start">
                            <span>
                                <IconButton
                                    size="small"
                                    onClick={() => copyText(selectedChat && selectedChat.last_interaction && selectedChat.last_interaction.phone_number && selectedChat.last_interaction.phone_number.replace(/^55/, ''), 'phone')}
                                >
                                    <LocalPhoneIcon
                                        fontSize="inherit"
                                    />
                                </IconButton>
                            </span>
                        </Tooltip>
                    }
                    <Typography variant="body">
                        {selectedChat && selectedChat.last_interaction && selectedChat.last_interaction.phone_number && selectedChat.last_interaction.phone_number.replace(/^55/, '')}
                        {
                            selectedChat?.last_interaction && Object.keys(selectedChat?.last_interaction).includes('contactExists') && selectedChat?.last_interaction.contactExists ?
                                manageStatus('verified') :
                                (
                                    selectedChat?.last_interaction && Object.keys(selectedChat?.last_interaction).includes('contactExists') && !selectedChat?.last_interaction.contactExists ?
                                        manageStatus('does-not-exist') :
                                        manageStatus('not-verified')
                                )
                        }
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}