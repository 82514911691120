// React
import React from 'react';

// Design

// Internal
import ChatMessages from './chatMessages';
import ChatMenu from './chatMenu';
import SendMessageBox from '../../../components/Boxes/sendMessageBox';
import { Box } from '@mui/system';
import Dropzone from '../../../components/Surfaces/dropzone';
// Third-party

export default function Chat({
    selectedChat,
    handleSendMessage,
    handleSendFile,
    notes,
    handleFavorite,
    uploadFilesVisible,
    setUploadFilesVisible,
    acceptedFiles,
    files,
    onDropFile,
    onChangeFile,
    setPathDialogOpen,
    setEditContactDialogOpen,
    setReminderDialogWithNumberShow,
    setNextOperatorDialog,
    setCloseConfirmDialog,
    handleLazyDownload,
    handleLazyOpen,
    setScheduleChatDialogShow,
    setRecordAudioDialogOpen,
    setCloudFilesVisible,
    cloudFilesVisible,
    setProfilePicOpen,
    operation,
}) {

    return (
        <Dropzone
            onDrop={onDropFile}
            onChange={onChangeFile}
            acceptedtFileTypes={acceptedFiles}
            changeBackground={true}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                }}
            >
                <ChatMenu
                    selectedChat={selectedChat}
                    handleFavorite={handleFavorite}
                    setPathDialogOpen={setPathDialogOpen}
                    setEditContactDialogOpen={setEditContactDialogOpen}
                    setReminderDialogWithNumberShow={setReminderDialogWithNumberShow}
                    setNextOperatorDialog={setNextOperatorDialog}
                    lastMessageStatus={selectedChat && Array.isArray(selectedChat.messages) && selectedChat.messages.length > 0 && ['line'].includes(selectedChat.messages[selectedChat.messages.length - 1].ack) ? false : true}
                    setCloseConfirmDialog={setCloseConfirmDialog}
                    setScheduleChatDialogShow={setScheduleChatDialogShow}
                    setProfilePicOpen={setProfilePicOpen}
                    operation={operation}
                />
                <ChatMessages
                    messages={selectedChat && selectedChat.messages ? selectedChat.messages : []}
                    user={selectedChat && selectedChat.last_interaction ? selectedChat.last_interaction.user : ''}
                    chatId={selectedChat && selectedChat.last_interaction ? selectedChat.last_interaction.chat_id : ''}
                    phoneNumber={selectedChat && selectedChat.last_interaction ? selectedChat.last_interaction.phone_number : ''}
                    handleSendMessage={handleSendMessage}
                    handleSendFile={handleSendFile}
                    files={files}
                    handleLazyDownload={handleLazyDownload}
                    handleLazyOpen={handleLazyOpen}
                />
                <SendMessageBox
                    handleSendMessage={handleSendMessage}
                    chatId={selectedChat && selectedChat.last_interaction ? selectedChat.last_interaction.chat_id : ''}
                    handleSendFile={handleSendFile}
                    notes={notes}
                    uploadFilesVisible={uploadFilesVisible}
                    setUploadFilesVisible={setUploadFilesVisible}
                    setRecordAudioDialogOpen={setRecordAudioDialogOpen}
                    setCloudFilesVisible={setCloudFilesVisible}
                    cloudFilesVisible={cloudFilesVisible}
                />

            </Box>
        </Dropzone>
    );
}

// {
//     selectedChat?.last_interaction?.contactExists ?

//         <SendMessageBox
//             handleSendMessage={handleSendMessage}
//             chatId={selectedChat && selectedChat.last_interaction ? selectedChat.last_interaction.chat_id : ''}
//             handleSendFile={handleSendFile}
//             notes={notes}
//             uploadFilesVisible={uploadFilesVisible}
//             setUploadFilesVisible={setUploadFilesVisible}
//             setRecordAudioDialogOpen={setRecordAudioDialogOpen}
//             setCloudFilesVisible={setCloudFilesVisible}
//             cloudFilesVisible={cloudFilesVisible}
//         />
//         :
//         <Box
//             sx={{
//                 display: 'flex',
//                 direction: 'column',
//                 height: '12rem',
//                 width: '100%',
//                 background: '#BDBDBD',
//                 // paddingTop: '1rem',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 fontSize: '1.5rem',
//             }}
//         >
//             <Box
//                 sx={{
//                     display: 'flex',
//                     direction: 'column',
//                     height: '100%',
//                     width: '80%',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                 }}
//             >
//                 NÚMERO CONSTA COMO NÃO EXISTENTE.<br />
//                 Você pode tentar agendar uma mensagem para que o sistema se auto corrija. <br />Caso volte a se repetir, contatar o suporte.
//             </Box>
//         </Box>
// }