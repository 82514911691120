// React
import React, { useEffect, useState } from 'react';

// Design
import { Box, Grid } from '@mui/material';


// Internal
import ClosedReasonsTable from './components/closedReasonsTable';
import CountFirstMessageGraph from './components/countFirstMessageGraph';
import CountReturnsGraph from './components/countReturnsGraph';
import CountMessagesGraph from './components/countMessagesGraph';
import {
  appUser,
} from '../../../../../../store/app/slice';

// Third-party
import { useSelector } from 'react-redux';


export default function Monitor({ props }) {

  // startDate, endDate, search, setSearch,
  const user = useSelector(appUser);
  // eslint-disable-next-line
  const [loadingGraphs, setLoadingGraphs] = useState([false, false, false, false]);


  useEffect(() => {
    if (props.search) {
      setLoadingGraphs([true, true, true, true]);
    }
  }, [props.search])


  return (
    <Box
      sx={{
        width: 'inherit',
        height: 'calc(inherit / 3)',
      }}
    >
      <Grid
        container
        // spacing={1}
        direction={'row'}
      >
        <Grid
          item
          xl={12}
          md={12}
          sm={12}
          xs={12}
        >
          <CountMessagesGraph
            user={user}
            startDate={props.startDate}
            endDate={props.endDate}
            search={props.search}
            setSearch={props.setSearch}
          />
        </Grid>
        <Grid
          item
          xl={4}
          md={4}
          sm={4}
          xs={4}
        >
          <ClosedReasonsTable
            user={user}
            startDate={props.startDate}
            endDate={props.endDate}
            search={props.search}
            setSearch={props.setSearch}
          />
        </Grid>
        <Grid
          item
          xl={4}
          md={4}
          sm={4}
          xs={4}
        >
          <CountFirstMessageGraph
            user={user}
            startDate={props.startDate}
            endDate={props.endDate}
            search={props.search}
            setSearch={props.setSearch}
          />
        </Grid>
        <Grid
          item
          xl={4}
          md={4}
          sm={4}
          xs={4}
        >
          <CountReturnsGraph
            user={user}
            startDate={props.startDate}
            endDate={props.endDate}
            search={props.search}
          />
        </Grid>
      </Grid>
    </Box>
  )
}