// React
import React, { useState, useEffect } from "react";

// Design
import { Box } from "@mui/system";
import { IconButton, Tooltip, Typography } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SendIcon from "@mui/icons-material/Send";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { green, red } from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Internal
import Supervision from "../..";
import Dropzone from "../../../../components/Surfaces/dropzone";
import ContactsList from "./Components/contactsList";
import { appUser, setNotificationBar } from "../../../../store/app/slice";
import createAccess from "../../../../utils/functions/createAccess";
import API from "../../../../utils/api";
import DateAndTimePickers from "./Components/dateAndTimePicker";
import fileTob64 from "../../../../utils/functions/filetob64";

// Third-party
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

function FilesList(props) {
  const { selectedValue, removeFileFromUpload, loadingSending } = props;

  return (
    <List sx={{ pt: 0 }}>
      {selectedValue &&
        selectedValue.acceptedFiles.length > 0 &&
        selectedValue.acceptedFiles.map((f, index) => (
          <ListItem key={`${index}-${f.name || f.path}`}>
            <Tooltip title="Tipo de arquivo aceito">
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: green[100], color: green[600] }}>
                  <CheckIcon />
                </Avatar>
              </ListItemAvatar>
            </Tooltip>
            <ListItemText primary={f.name || f.path} />
            {f.status && f.status === "loading" && (
              <Tooltip title="Enviando arquivo">
                <CircularProgress />
              </Tooltip>
            )}
            {f.status && f.status === "done" && (
              <Tooltip title="Arquivo enviado">
                <CheckIcon />
              </Tooltip>
            )}
            {!f.status && !loadingSending && (
              <Tooltip title="Excluir arquivo">
                <IconButton
                  onClick={() => removeFileFromUpload(index, "acceptedFiles")}
                >
                  <CloseIcon color="error" />
                </IconButton>
              </Tooltip>
            )}
          </ListItem>
        ))}
      {selectedValue &&
        selectedValue.rejectedFiles.length > 0 &&
        selectedValue.rejectedFiles.map((f, index) => (
          <ListItem key={`${index}-${f.file.name || f.file.path}`}>
            <Tooltip title="Tipo de arquivo não aceito">
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: red[100], color: red[600] }}>
                  <CloseIcon />
                </Avatar>
              </ListItemAvatar>
            </Tooltip>
            <ListItemText primary={f.file.name || f.file.path} />
            <Tooltip title="Excluir arquivo">
              <IconButton
                onClick={() => removeFileFromUpload(index, "rejectedFiles")}
              >
                <CloseIcon color="error" />
              </IconButton>
            </Tooltip>
          </ListItem>
        ))}
    </List>
  );
}

FilesList.propTypes = {
  selectedValue: PropTypes.object,
  removeFileFromUpload: PropTypes.func.isRequired,
  loadingSending: PropTypes.bool,
};

export default function Massive() {
  const user = useSelector(appUser);
  const dispatch = useDispatch();

  const [contactsList, setContactsList] = useState([]);
  // eslint-disable-next-line
  const [numberList, setNumberList] = useState([]);
  let cD = new Date();
  let _hour = cD.getHours();
  cD.setHours(_hour - 3);
  const [hourSelectedReminder, setHourSelectedReminder] = useState(
    cD.toISOString().slice(0, 16)
  );

  const [filesToUpload, setFilesToUpload] = useState(null);
  const [loadingSending, setLoadingSending] = useState(false);
  // eslint-disable-next-line
  const [loadingSendingFiles, setLoadingSendingFiles] = useState(false);
  const [teamsList, setTeamsList] = useState([{ id: "todos", name: "Todos" }]);
  const [teamId, setTeamId] = useState("todos");

  const handleGetTeams = () => {
    let access_click2chat = createAccess(user);
    API.get
      .teams(access_click2chat)
      .then((response) => {
        if (response.status === 200) {
          let teams = response?.data?.info ?? null;
          if (teams) {
            teams = Object.entries(teams).map(([key, value]) => {
              return {
                id: key,
                name: value.name === "default" ? "Padrão" : value.name,
              };
            });
            teams.push({
              id: "todos",
              name: "Todos",
            });
            setTeamsList(teams);
          }
        } else {
          // setCurrentList([])
        }
      })
      .finally(() => {});
  };

  const handleChangeTeam = (event, _teamsList) => {
    // let access_click2chat = createAccess(user);
    // let teamName = _teamsList.find(el => el.id === event.target.value).name;
    // teamName = teamName === 'Padrão' ? 'default' : teamName;
    let teamId = event.target.value;
    teamId = teamId === "Padrão" ? "default" : teamId;
    setTeamId(teamId);
  };
  const handleSendFile = async ({ resend = false, fileIndex = 0 }) => {
    let access_click2chat = createAccess(user);

    if (resend) {
      // API.post.fileResend(access_click2chat, chat_id, filename, resend, file_id);
    } else {
      let FTU = filesToUpload;
      FTU.rejectedFiles = [];
      if (
        !FTU.acceptedFiles[fileIndex]["status"] ||
        (FTU.acceptedFiles[fileIndex]["status"] &&
          FTU.acceptedFiles[fileIndex]["status"] !== "done")
      ) {
        FTU.acceptedFiles[fileIndex]["status"] = "loading";
        setFilesToUpload(FTU);
        let { id, filename } = await FTU.acceptedFiles[fileIndex].filePromise
          .then((result) => {
            // dispatch or do whatever you need with result
            let _file = {
              mediaKey: "",
              filename: FTU.acceptedFiles[fileIndex].name,
              mime_type: result.split(";")[0].split(":")[1],
              metadata_message: {},
              file_size: `${(result.split(",")[1].length / 1e6).toFixed(2)} mb`,
              timestamp: new Date(),
              file: result.split(",")[1],
            };
            return API.post
              .file(access_click2chat, _file)
              .then((response) => {
                if (response.ok) {
                  FTU.acceptedFiles[fileIndex]["status"] = "done";
                  setFilesToUpload(FTU);
                  return {
                    id: response.data.info,
                    filename: FTU.acceptedFiles[fileIndex].name,
                  };
                } else {
                  throw new Error("Failed");
                }
              })
              .catch(() => {
                FTU.acceptedFiles[fileIndex]["status"] = "failed";
                setFilesToUpload(FTU);
              });
          })
          .catch(() => {
            FTU.acceptedFiles[fileIndex]["status"] = "failed";
            setFilesToUpload(FTU);
          });
        return { id, filename };
      }
    }
  };

  const sendMassive = async () => {
    setLoadingSending(true);
    let access_click2chat = createAccess(user);
    // let fileId = '';
    // let fileName = '';
    let FTU = filesToUpload;
    let hasFile = false;
    let files = [];

    if (FTU?.acceptedFiles?.length > 0) {
      let { id } = await handleSendFile({});
      // fileId = id;
      // fileName = filename;
      hasFile = true;
      files.push({ ...id });
    }
    let dateToSend = new Date(hourSelectedReminder);
    dateToSend = dateToSend.getTime();

    let _contactsList = contactsList.filter((el) => el.valid);

    let teamsDict = {};
    _contactsList.forEach((el) => {
      if (!teamsDict[el.team_id]) {
        teamsDict[el.team_id] = {
          team_id: el.team_id,
          team_name: el.team,
          list: [],
          dateToSend,
          files,
          hasFile,
        };
      }
      teamsDict[el.team_id].list.push(el);
    });
    let massiveLists = [];
    Object.keys(teamsDict).forEach((key) => {
      massiveLists.push({
        ...teamsDict[key],
      });
    });

    API.post
      .massiveV2(
        access_click2chat,
        massiveLists
        // dateToSend,
        // hasFile,
        // files
        // _teamId,
        // _teamName
      )
      .then((response) => {
        if (response.status === 201) {
          dispatch(
            setNotificationBar({
              status: true,
              message: "Massivo Enviado",
              type: "success",
            })
          );
        } else if (response?.status === 403) {
          dispatch(
            setNotificationBar({
              status: true,
              message: response.data.msg,
              type: "warning",
            })
          );
        } else {
          let err = new Error("not possible");
          err.response = response;
          throw err;
        }
      })
      .catch((err) => {
        dispatch(
          setNotificationBar({
            status: true,
            message: err?.response?.data?.msg ?? "",
            type: "error",
          })
        );
      })
      .finally(() => {
        // setOperatorEditDialogShow(false);
        setContactsList([]);
        setNumberList([]);
        setFilesToUpload(null);
        setLoadingSending(false);
      });
  };

  const onDropFile = (acceptedFiles, rejectedFiles) => {
    console.log("onDropFile", { acceptedFiles, rejectedFiles });

    acceptedFiles.forEach((file) => {
      let reader = new FileReader();
      reader.onload = function (e) {
        let contents = e.target.result;
        let _contents = [];
        let numbers = [];
        // console.log({ contents });
        if (contents.includes("\r\n")) {
          contents.split("\r\n").forEach((row, index) => {
            // console.log({ index, row });
            console.log("rn");
            let rowContent = row.split(";");
            // console.log({ rowContent });
            let currentTeam = rowContent[0];
            let flagTeam = false;
            let flagPhone = false;
            let reg = /^\d{10,}$/;
            flagTeam = teamsList.find((el) => el.name === currentTeam);
            if (typeof rowContent[1] === "string" && reg.test(rowContent[1])) {
              flagPhone = true;
            }

            if (Boolean(rowContent?.[1]?.replace("\r", ""))) {
              if (!numbers.includes(rowContent[1])) {
                numbers.push(rowContent[1]);
              }
              if (![0].includes(index) && rowContent.length % 3 === 0) {
                for (let i = 2; i <= rowContent.length - 1; i++) {
                  let test = rowContent[i].replace("\r", "");
                  let newText = "";
                  let makeNewText = (oldText) => {
                    let nT = oldText.split('"');
                    if (nT.length > 1) {
                      nT.pop();
                      nT.shift();
                    }
                    nT = nT.join("");
                    return nT;
                  };
                  newText = makeNewText(rowContent[i]);
                  if (test) {
                    let team_id = flagTeam?.id;
                    if (team_id === "todos") {
                      team_id = "all";
                    } else if (team_id === "padrão") {
                      team_id = "default";
                    }
                    let _info = {
                      phone: rowContent[1],
                      text: newText,
                      team: currentTeam,
                      team_id,
                      valid: Boolean(flagTeam) && flagPhone,
                      // text: rowContent[i].replace(/\r/g, '').replace(/\n/g, '\\n')
                    };
                    _contents.push(_info);
                  } else {
                    _contents.push(null);
                  }
                }
              } else {
                _contents.push(null);
              }
            }
          });
        } else if (contents.includes('"\n')) {
          // console.log({contents})
          contents.split('"\n').forEach((row, index) => {
            console.log('"n');
            // console.log({row})
            let rowContent = row.split(";");
            let currentTeam = rowContent[0];
            let flagTeam = false;
            let flagPhone = false;
            let reg = /^\d{10,}$/;
            flagTeam = teamsList.find((el) => el.name === currentTeam);

            if (typeof rowContent[1] === "string" && reg.test(rowContent[1])) {
              flagPhone = true;
            }
            if (!numbers.includes(rowContent[1])) {
              numbers.push(rowContent[1]);
            }
            if (![0].includes(index) && rowContent.length % 3 === 0) {
              for (let i = 2; i <= rowContent.length - 1; i++) {
                let test = rowContent[i];
                if (test) {
                  let team_id = flagTeam?.id;
                  if (team_id === "todos") {
                    team_id = "all";
                  } else if (team_id === "padrão") {
                    team_id = "default";
                  }
                  let _info = {
                    phone: rowContent[1],
                    team_id,
                    valid: Boolean(flagTeam) && flagPhone,
                    team: currentTeam,
                    // text: newText,
                    text: rowContent[i],
                  };
                  _contents.push(_info);
                } else {
                  _contents.push(null);
                }
              }
            } else {
              _contents.push(null);
            }
          });
        } else {
          contents.split("\n").forEach((row, index) => {
            console.log("n");
            let rowContent = row.split(";");
            let currentTeam = rowContent[0];
            let flagPhone = false;
            let flagTeam = false;
            let reg = /^\d{10,}$/;
            flagTeam = teamsList.find((el) => el.name === currentTeam);

            if (typeof rowContent[1] === "string" && reg.test(rowContent[1])) {
              flagPhone = true;
            }
            if (!numbers.includes(rowContent[1])) {
              numbers.push(rowContent[1]);
            }
            if (![0].includes(index) && rowContent.length % 3 === 0) {
              for (let i = 2; i <= rowContent.length - 1; i++) {
                let test = rowContent[i];
                if (test) {
                  let team_id = flagTeam?.id;
                  if (team_id === "todos") {
                    team_id = "all";
                  } else if (team_id === "padrão") {
                    team_id = "default";
                  }
                  let _info = {
                    phone: rowContent[1],
                    // text: newText,
                    team_id,
                    valid: Boolean(flagTeam) && flagPhone,
                    text: rowContent[i],
                    team: currentTeam,
                  };
                  _contents.push(_info);
                } else {
                  _contents.push(null);
                }
              }
            } else {
              _contents.push(null);
            }
          });
        }

        _contents = _contents.filter((c) => c !== null);
        // sort by team
        _contents = _contents.sort((a, b) => {
          // to lower case
          let _a = a.team.toLowerCase();
          let _b = b.team.toLowerCase();
          if (_a < _b) {
            return -1;
          }
          if (_a > _b) {
            return 1;
          }
          return 0;
        });
        setContactsList(_contents);
        setNumberList(numbers);
      };
      reader.readAsText(file);
    });
  };

  const onDropAttachedFile = (acceptedFiles, rejectedFiles) => {
    console.log("onDropFile", { acceptedFiles, rejectedFiles });
    if (filesToUpload) {
      let FTU = filesToUpload;
      setFilesToUpload(null);
      let filesToAccept = [];
      if (Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
        for (let i = 0; i <= acceptedFiles.length - 1; i++) {
          let _file = acceptedFiles[i];
          _file.filePromise = fileTob64(_file);
          filesToAccept.push(_file);
        }
      }
      FTU.acceptedFiles = FTU.acceptedFiles.concat(filesToAccept);
      FTU.rejectedFiles = FTU.rejectedFiles.concat(rejectedFiles);
      FTU.acceptedFiles = [FTU.acceptedFiles[0]];
      setFilesToUpload(FTU);
    } else {
      let filesToAccept = [];
      if (Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
        for (let i = 0; i <= acceptedFiles.length - 1; i++) {
          let _file = acceptedFiles[i];
          _file.filePromise = fileTob64(_file);
          filesToAccept.push(_file);
        }
        filesToAccept = [filesToAccept[0]];
      }
      setFilesToUpload({ acceptedFiles: filesToAccept, rejectedFiles });
    }
  };

  const removeFileFromUpload = (index, list) => {
    let FTU = filesToUpload;
    setFilesToUpload(null);
    setTimeout(() => {
      if (list === "rejectedFiles") {
        delete FTU.rejectedFiles[index];
        FTU.rejectedFiles = FTU.rejectedFiles.filter((el) => el !== null);
        setFilesToUpload(FTU);
      } else if (list === "acceptedFiles") {
        delete FTU.acceptedFiles[index];
        FTU.acceptedFiles = FTU.acceptedFiles.filter((el) => el !== null);
        setFilesToUpload(FTU);
      }
    });
  };

  const onChangeFile = (e) => {
    // console.log('onChangeFile', e);
  };

  useEffect(() => {
    handleGetTeams();
    // eslint-disable-next-line
  }, []);

  // console.log({contactsList})
  return (
    <Box>
      <Supervision />
      <Box
        sx={{
          display: "flex",
          margin: 0,
          width: "100%",
          height: "calc(100vh - 4.063rem)",
        }}
      >
        <Box
          sx={{
            width: "20rem",
            height: "inherit",
          }}
        >
          <Box
            sx={{
              width: "inherit",
              height: "3rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="caption">
              Solte a base de contatos abaixo (csv, separado por ;):
            </Typography>
          </Box>
          {contactsList.length === 0 ? (
            <Box
              sx={{
                width: "inherit",
                height: "5rem",
                borderStyle: "dashed",
              }}
            >
              <Dropzone
                onDrop={onDropFile}
                onChange={onChangeFile}
                acceptedtFileTypes={[
                  "csv, application/vnd.ms-excel, text/csv",
                  // "json, application/json",
                ]}
              />
            </Box>
          ) : (
            <Box
              sx={{
                width: "inherit",
                height: "5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#d32f2f",
              }}
            >
              <Tooltip title="Apagar arquivo">
                <IconButton
                  onClick={() => {
                    setContactsList([]);
                    setNumberList([]);
                  }}
                >
                  <DeleteForeverIcon sx={{ fontSize: "3rem" }} />
                </IconButton>
              </Tooltip>
            </Box>
          )}

          <Box
            sx={{
              width: "inherit",
              height: "calc(2 * 5rem)",
              overflowX: "hidden",
              backgroundColor: contactsList.length !== 0 ? "white" : "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DateAndTimePickers
              currentDate={hourSelectedReminder}
              handleChangeDate={setHourSelectedReminder}
            />
          </Box>
          <Box
            sx={{
              width: "inherit",
              height: "5rem",
              overflowX: "hidden",
              backgroundColor: contactsList.length !== 0 ? "white" : "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "inherit",
                height: "3rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="caption">
                Verifique os times disponíveis:
              </Typography>
            </Box>
            <Select
              value={teamId}
              sx={{ width: 220 }}
              onChange={(event) => handleChangeTeam(event, teamsList)}
            >
              {teamsList.map((el) => {
                return (
                  <MenuItem key={el.id} value={el.id}>
                    {el.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Box
            sx={{
              width: "inherit",
              height: "3rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="caption">
              Lista de arquivos a serem anexados:
            </Typography>
          </Box>
          <Box
            sx={{
              width: "inherit",
              height: "10rem",
              overflowY: "auto",
              borderStyle: "dashed",
            }}
          >
            <Dropzone
              onDrop={onDropAttachedFile}
              onChange={onChangeFile}
              supervision={true}
              changeBackground={false}
            >
              <FilesList
                selectedValue={filesToUpload}
                removeFileFromUpload={removeFileFromUpload}
                loadingSending={loadingSendingFiles}
              />
            </Dropzone>
          </Box>

          <Box
            sx={{
              width: "inherit",
              height: "calc(100% - 36rem)",
              overflowY: "hidden",
              overflowX: "hidden",
              backgroundColor: contactsList.length !== 0 ? "#2e7d32" : "",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Tooltip title="Enviar arquivo">
              <span>
                <IconButton
                  onClick={sendMassive}
                  disabled={contactsList.length === 0 || loadingSending}
                >
                  <SendIcon
                    sx={{
                      fontSize: "8rem",
                      paddingLeft: "1.5rem",
                      marginLeft: "10px",
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Box>
        <Box
          sx={{
            width: "calc(100%)",
            height: "inherit",
          }}
        >
          <Box
            sx={{
              width: "inherit",
              height: "calc(100%)",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <ContactsList contacts={contactsList} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
