// React
import React, { useEffect, useState, useRef } from "react";

// Design
import Box from '@mui/material/Box';

// Internal
import API from '../../utils/api';

// Third-party
import { useSearchParams } from 'react-router-dom';



export default function QR () {
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const [image64, setImage64] = useState('');
    const currentImage64 = useRef('');
    const [tick, setTick] = useState(0);
    const getImage = useRef(true);
    const TIMER = 1 * 2000;

    const handleImage = async (image) => {
        if (getImage.current) {
            getImage.current = false;
            let response = await API.get.qrCode(searchParams.get("vm"), searchParams.get("company_id"));
            try {
                if (response?.data?.b64 && response.data.b64 !== currentImage64.current) {
                    let b64 = response.data.b64;
                    currentImage64.current = response.data.b64;
                    return b64;                       
                }
                else if (response?.data?.msg === 'ok' && !response?.data?.b64) {
                    currentImage64.current = '';
                    return 'no image';
                }
            }
            finally {
                getImage.current = true;
            }
        }
    }
    
    useEffect(() => {
        const interval =  setInterval(() => setTick(oldValue => oldValue + 1), TIMER);
        
        return () => {
          clearInterval(interval);
        };
    // eslint-disable-next-line
    }, [])

    useEffect(() => {
        handleImage(image64)
        .then(_img => {
            let oldImg = image64;
            if (_img && _img === 'no image') {
                setImage64('');
            }
            else if (_img && oldImg !== _img) {
                setImage64(_img);
            }
        });
        // console.log({_img})
    // eslint-disable-next-line
    }, [tick])

    return (
        <>
            {
                searchParams.get("vm") && searchParams.get("company_id") ?
                <Box
                    display='flex'
                    sx={{
                        justifyContent: 'center',
                        width: '100%',
                        height: '100vh',
                        alignItems: 'center'
                    }}
                >
                    {
                        image64 ? 
                        <img 
                            src={`data:image/png;base64, ${image64}` || ''}
                            alt={'qrCode'}
                        />:
                        ''
                    }

                    {searchParams.get("vm")}
                </Box>
                :
                <>
                </>
            }
        </>
    )
}