// React
import React, { useEffect, useState } from "react";

// Design
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import ListAltIcon from "@mui/icons-material/ListAlt";

// Internal
import createAccess from "../../utils/functions/createAccess";
import API from "../../utils/api";
import { IconButton, Typography } from "@mui/material";
import MessagesLineDialog from "./messagesLineDialog";

// Third-party

export default function VMEditDialog({
  open,
  setOpen,
  vm_id,
  user,
  selectedVMTeamIn,
  selectedVMTeamOut,
  selectedTag,
  vmsList,
}) {
  const [teamsList, setTeamsList] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [inTeamId, setInTeamId] = useState("");
  const [outTeamId, setOutTeamId] = useState("");
  const [tagId, setTagId] = useState("");
  const [openMessagesLine, setOpenMessagesLine] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenMessagesLine = () => {
    // console.log({ vm_id })
    let vm = vmsList.find((el) => el.vm_code === vm_id);
    // console.log({ vm })
    if (vm) {
      setOpenMessagesLine(true);
    }
  };

  const handleGetTeams = () => {
    let access_click2chat = createAccess(user);
    API.get
      .teams(access_click2chat)
      .then((response) => {
        if (response.status === 200) {
          let _teams = response?.data?.info ?? null;
          if (_teams) {
            let teams = Object.entries(_teams).map(([key, value]) => {
              return {
                id: key,
                name: value.name === "default" ? "Padrão" : value.name,
                active: value?.active ?? true,
              };
            });
            teams.push({
              id: "Todos",
              name: "Todos",
              active: true,
            });
            teams = teams.filter((el) => el.active);
            setTeamsList(teams);
          }
        } else {
          // setCurrentList([])
        }
      })
      .finally(() => {});
  };

  const handleGetTags = () => {
    let access_click2chat = createAccess(user);
    API.get
      .tags(access_click2chat)
      .then((response) => {
        if (response.status === 200) {
          let tags = response?.data?.info ?? null;
          if (tags) {
            tags = Object.entries(tags).map(([key, value]) => {
              return {
                id: key,
                name: value.name === "default" ? "Padrão" : value.name,
              };
            });
            setTagsList(tags);
          }
        } else {
          // setCurrentList([])
        }
      })
      .finally(() => {});
  };

  const handleChangeTeam = (event, vmId, _teamsList, direction) => {
    let access_click2chat = createAccess(user);
    let teamName = _teamsList.find((el) => el.id === event.target.value).name;
    teamName =
      teamName === "Todos"
        ? "all"
        : teamName === "Padrão"
        ? "default"
        : teamName;
    let teamId = event.target.value;
    teamId =
      teamId === "Todos" ? "all" : teamId === "Padrão" ? "default" : teamId;
    API.put
      .teamVM(access_click2chat, vmId, teamId, teamName, direction)
      .then((response) => {
        if (response.status === 201) {
          teamId = teamId === "all" ? "Todos" : teamId;
          if (direction === "out") {
            setOutTeamId(teamId);
          } else if (direction === "in") {
            setInTeamId(teamId);
          }
        }
      })
      .finally(() => {});
  };

  const handleChangeTag = (event, vmId, _tagsList) => {
    let access_click2chat = createAccess(user);
    let tagName = _tagsList.find((el) => el.id === event.target.value).name;
    let tagId = event.target.value;
    API.put
      .tagVM(access_click2chat, vmId, tagId, tagName)
      .then((response) => {
        if (response.status === 201) {
          setTagId(tagId);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (open) {
      handleGetTeams();
      handleGetTags();
    } else {
      setTeamsList([]);
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (selectedVMTeamIn?.id) {
      setInTeamId(selectedVMTeamIn?.id);
    }
  }, [selectedVMTeamIn]);

  useEffect(() => {
    if (selectedVMTeamOut?.id) {
      setOutTeamId(selectedVMTeamOut?.id);
    }
  }, [selectedVMTeamOut]);

  useEffect(() => {
    if (selectedTag?.id) {
      setTagId(selectedTag?.id);
    }
  }, [selectedTag]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            sx={{
              display: "flex",
              height: "2rem",
              width: "48rem",
              // alignItems: 'flex-start',
              justifyContent: "flex-start",
              margin: "0 0 0 0",
              flexDirection: "row",
              // border: '1px solid #000000',
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifySelf: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                flexGrow: 1,
              }}
            >
              Informações da VM ({vm_id})
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                // width: '100%',
                flexGrow: 1,
                // border: '1px solid #000000',
              }}
            >
              <Typography title="Mensagens na fila">
                <IconButton onClick={handleOpenMessagesLine}>
                  <ListAltIcon />
                </IconButton>
              </Typography>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            sx={{ flexDirection: "column", width: "100%", height: "100%" }}
          >
            <Box display="flex" sx={{ marginBottom: "15px" }}>
              <Typography variant="h4">Times</Typography>
            </Box>
            <Box
              display="flex"
              sx={{
                flexDirection: "row",
              }}
            >
              <Box display="flex" sx={{ width: 280 }}>
                <InputLabel id="demo-simple-select-label">Entrada</InputLabel>
                <Select
                  value={inTeamId}
                  sx={{ width: 200 }}
                  onChange={(event) =>
                    handleChangeTeam(event, vm_id, teamsList, "in")
                  }
                >
                  {teamsList.map((el) => {
                    return (
                      <MenuItem key={el.id} value={el.id}>
                        {el.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>

              <Box display="flex" sx={{ width: 250 }}>
                <InputLabel id="demo-simple-select-label">Saída</InputLabel>
                <Select
                  value={outTeamId}
                  sx={{ width: 200 }}
                  onChange={(event) =>
                    handleChangeTeam(event, vm_id, teamsList, "out")
                  }
                >
                  {teamsList.map((el) => {
                    return (
                      <MenuItem key={el.id} value={el.id}>
                        {el.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>

              <Box display="flex" sx={{ width: 250 }}>
                <InputLabel id="demo-simple-select-label">Tags</InputLabel>
                <Select
                  value={tagId}
                  sx={{ width: 200 }}
                  onChange={(event) => handleChangeTag(event, vm_id, tagsList)}
                >
                  {tagsList.map((el) => {
                    return (
                      <MenuItem key={el.id} value={el.id}>
                        {el.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <MessagesLineDialog
        open={openMessagesLine}
        setOpen={setOpenMessagesLine}
        vmsList={vmsList}
        vm_id={vm_id}
      />
    </div>
  );
}
