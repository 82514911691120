// React
import React, { useState, useEffect, useRef } from 'react';

// Design
import Dialog from '@mui/material/Dialog';
import { Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import Card from '@mui/material/Card';
import PersonIcon from '@mui/icons-material/Person';
import { Tooltip } from '@mui/material';
import { red } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';

// Internal
import { appFetchingChat, appUser, appFetchingInternalChats, getInternalChats, appInternal } from '../../store/app/slice';
import API from '../../utils/api';
import createAccess from '../../utils/functions/createAccess';
import InternalMessageCard from '../Cards/internalMessageCard';

// Third-party
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useSelector, useDispatch } from 'react-redux';
import SendMessageBox from '../Boxes/sendMessageBox';


function ChatCard({
  index,
  selectedChatID,
  listToUse,
  setSelectedChat,
  userId,
}) {

  const [mouseOverIndex, setMouseOverIndex] = useState(null);
  const fetchingChat = useSelector(appFetchingChat);

  const handleOverMouse = (index) => {
    setMouseOverIndex(index)
  }

  const handleLeaveMouse = () => {
    setMouseOverIndex(null);
  }
  const returnColor = (index, listToUse, selectedChatID, mouseOverIndex) => {
    if (selectedChatID === String(listToUse[index]?._id)) {
      return '#71797E';
    }
    else if (mouseOverIndex === index) {
      return '#818589';
    }
    else {
      return '#808080';
    }
  }

  return (
    <Tooltip
      title={
        listToUse[index]?.message ?? ''
      }
      enterDelay={500}
      placement="right"
    >
      <Card
        key={index}
        style={{
          backgroundColor: returnColor(index, listToUse, selectedChatID, mouseOverIndex),
          marginTop: '0.25rem',
          marginLeft: '0.25rem',
          height: '6.25rem',
          width: 'calc(100% - 1rem)'
        }}
        onMouseOver={() => handleOverMouse(index)}
        onMouseLeave={() => handleLeaveMouse()}
        onClick={() => setSelectedChat(listToUse[index])}
      >
        <CardHeader
          avatar={
            fetchingChat === listToUse[index]?._id ?
              <CircularProgress />
              :
              <Avatar>
                <PersonIcon />
              </Avatar>
          }
          title={
            <div>
              <Typography style={{ position: "inherit" }} color="#d3d3d3">
                {listToUse[index]?.full_name ?? ''}
              </Typography>
              <Typography style={{ position: "inherit" }} variant="caption" color="#d3d3d3">
                {listToUse[index]?.username ?? ''}
              </Typography>
              {listToUse[index] && listToUse[index]?.lastMessage?.readBy && !listToUse[index]?.lastMessage?.readBy?.includes(userId) &&
                <NewReleasesIcon
                  style={{
                    color: red[500]
                  }}
                />
              }
            </div>
          }
          subheader={
            <Box
              display='flex'
              sx={{
                width: '18rem',
                height: '1rem',
                overflow: 'hidden',
              }}
            >
              <Typography variant="caption" color="#d3d3d3">
                {
                  listToUse[index]?.lastMessage?.message ?? ''
                }
              </Typography>
            </Box>
          }
          style={{ cursor: "pointer" }}
        />
      </Card>
    </Tooltip>
  )
}









function ChatsList({
  chatsList,
  setSelectedChat,
  selectedChat,
  internalChats,
  userId,
}) {

  const [listToUse, setListToUse] = useState([]);

  useEffect(() => {
    let lU = []
    chatsList.forEach(el => {
      let same = internalChats.find(_el =>
        (_el.recipient === el?._id)
        || (_el.recipient === userId && _el.sender === el._id));
      lU.push({
        ...el,
        lastMessage: { ...same }
      })
    })
    setListToUse(lU)
    // eslint-disable-next-line
  }, [chatsList, internalChats])


  const Row = ({ index, style }) => (
    <div style={style} key={`interaction_list-${index}`}>
      <ChatCard
        index={index}
        selectedChatID={selectedChat && selectedChat._id}
        listToUse={listToUse}
        setSelectedChat={setSelectedChat}
        userId={userId}
      />
    </div>
  );

  return (
    <main
      style={{
        height: 'inherit',
        width: '100%',
        backgroundColor: '#A9A9A9'
        // backgroundImage: 'url("/images/background-whatsapp-7.jpg")',
      }}>
      {
        chatsList.length > 0 ?
          <AutoSizer>
            {({ height, width }) => (
              <List
                className="List"
                height={height}
                itemCount={chatsList.length}
                itemSize={110}
                width={width}
              >
                {Row}
              </List>
            )}
          </AutoSizer>
          :
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography color='#d3d3d3'>
              Nenhuma conversa encontrada
            </Typography>
          </Box>
      }
    </main>
  )
}


export default function InternalChatDialog({
  open,
  setOpen,
  usersInternalCommunication,
}) {

  const dispatch = useDispatch();
  const user = useSelector(appUser);
  const fetchingChats = useSelector(appFetchingInternalChats);
  const _appInternal = useSelector(appInternal);
  const timeToUpdate = 1500;

  const [messages, setMessages] = useState([])

  const gettingNewData = useRef(fetchingChats);

  const handleClose = () => {
    setOpen(false);
  };
  const [selectedChat, setSelectedChat] = useState(null);
  const [lastMessageSelectedChat, setLastMessagesSelectedChats] = useState(null);

  const handleSendMessage = (id, msg) => {
    let access_click2chat = createAccess(user);
    API.post.internalMessage(access_click2chat, id, msg);
  }


  useEffect(() => {
    dispatch(getInternalChats(user))
    const interval = setInterval(() => {
      if (!gettingNewData.current) {
        dispatch(getInternalChats(user))
      }
    }, timeToUpdate);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {

    if (messages.length > 0) {
      const theElement = document.getElementsByClassName('msgBox2')[0];
      const scrollToBottom = (node) => {
        node.scrollTop = node.scrollHeight;
      }
      //   if (!noEmojiFlag) {
      //     scrollToBottom(theElement);
      //   }
      //   blobize();
      scrollToBottom(theElement);
      //   setLastMessageStatus(renderSelectedChatMsgs[renderSelectedChatMsgs.length -1].ack === 'line')
    }
    // eslint-disable-next-line
  }, [messages]);

  useEffect(() => {
    if (_appInternal?.chats) {
      let same = _appInternal.chats.find(el => el.recipient === selectedChat?._id || el.sender === selectedChat?._id);
      if (JSON.stringify(same) !== JSON.stringify(lastMessageSelectedChat)) {
        setLastMessagesSelectedChats(same);
      }
    }
    // eslint-disable-next-line
  }, [_appInternal]);

  useEffect(() => {
    if (lastMessageSelectedChat) {
      let access_click2chat = createAccess(user);

      API.get.internalChat(access_click2chat, selectedChat._id)
        .then(response => {
          if (response?.data?.data?.chat) {
            setMessages(response?.data?.data?.chat)
          }
        })
    }
    // eslint-disable-next-line
  }, [lastMessageSelectedChat])

  useEffect(() => {
    if (!open) {
      setLastMessagesSelectedChats(null);
      setMessages([]);
      setSelectedChat(null);
    }
  }, [open])

  useEffect(() => {
    setMessages([]);
    setLastMessagesSelectedChats(null);
  }, [selectedChat])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='xl'
    >
      <Box
        display="flex"
        sx={{
          width: '80rem',
          height: '60rem',
          overflowX: 'hidden',
          overflowY: 'hidden',
        }}
      >
        <Box
          display="flex"
          sx={{
            width: '25rem',
            height: '100%',
            backgroundColor: 'black',
            overflowX: 'hidden',
            overflowY: 'hidden',
          }}
        >
          <ChatsList
            chatsList={usersInternalCommunication}
            selectedChat={selectedChat}
            internalChats={_appInternal?.chats ?? []}
            setSelectedChat={setSelectedChat}
            userId={user.user_id}
          />
        </Box>
        {
          selectedChat ?
            <Box
              display="flex"
              sx={{
                width: 'calc(100% - 25rem)',
                height: '100%',
                flexDirection: 'column',
                overflowX: 'hidden',
                overflowY: 'hidden',

              }}
            >
              <Box
                sx={{
                  // height: '30rem',
                  width: 'calc(100%)',
                  height: 'calc(100% - 10rem)',
                  backgroundColor: '#A9A9A9',
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  paddingBottom: '0.313rem',
                }}
                className='msgBox2'
              >
                {
                  Array.isArray(messages) &&
                  messages.map((message, index) => {
                    return (
                      <InternalMessageCard
                        message={message}
                        index={index}
                        user={user}
                        key={`message-card${index}`}
                        chatId={selectedChat?._id}
                        users={usersInternalCommunication}
                      />
                    )
                  })
                }
              </Box>
              <Box
                display="flex"
                sx={{
                  width: 'calc(100%)',
                  height: 'calc(13rem)',
                  backgroundColor: '#808080'
                }}
              >

                <SendMessageBox
                  showNotesButton={false}
                  showAttachFilesButton={false}
                  showCloudFilesButton={false}
                  showRecordButton={false}
                  messageType='internal'
                  handleSendMessage={handleSendMessage}
                  chatId={selectedChat?._id}
                />

              </Box>
            </Box>
            :
            <Box
              display="flex"
              sx={{
                width: 'calc(100% - 25rem)',
                height: '100%',
                flexDirection: 'column',
                backgroundColor: '#A9A9A9',
                color: 'white',
                fontSize: '2rem',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              Nenhuma conversa selecionada
            </Box>
        }
      </Box>
    </Dialog>
  );
}