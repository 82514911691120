// React
import React, { Fragment, useEffect, useState } from "react";

// Design
import { makeStyles } from "@mui/styles";
// import Modal from './modal.js';

// Third-party
import DropZone from "react-dropzone";

const useStyles = makeStyles((theme) => ({
  dropzone: {
    height: "100%",
    width: "100%",
  },
  dropzoneActive: {
    height: "100%",
    width: "100%",
    borderStyle: "dashed",
  },
}));

export default function Dropzone({
  onDrop,
  onChange,
  children,
  acceptedtFileTypes,
  changeBackground,
  supervision,
  openWindowToSelectFiles = false,
}) {
  const classes = useStyles();
  const [accept, setAccept] = useState("");
  const [_supervision, _setSupervision] = useState(false);
  const maxSize = 1048576 * 15;

  useEffect(() => {
    if (Array.isArray(acceptedtFileTypes) && acceptedtFileTypes.length > 0) {
      let a = "";
      acceptedtFileTypes.forEach((el) => {
        a += `.${el}, `;
      });
      setAccept(a);
    }
  }, [acceptedtFileTypes]);

  useEffect(() => {
    if (supervision) {
      _setSupervision(true);
    } else {
      _setSupervision(false);
    }
    // eslint-disable-next-line
  }, [supervision]);

  return accept ? (
    <DropZone
      accept={accept}
      onDrop={onDrop}
      onChange={onChange}
      maxSize={maxSize}
    >
      {({ getRootProps, isDragActive, getInputProps }) => (
        <Fragment>
          <div
            className={isDragActive ? classes.dropzoneActive : classes.dropzone}
            {...getRootProps()}
          >
            {isDragActive && changeBackground ? (
              <div
                style={{
                  height: "100vh",
                  width: "100%",
                  background: "rgba(0, 0, 0, 0)",
                  opacity: "0.8",
                  textAlign: "center",
                  marginTop: "25%",
                }}
              >
                Solte seus arquivos aqui....
              </div>
            ) : (
              <>
                {openWindowToSelectFiles && (
                  <>
                    {" "}
                    <input {...getInputProps()} />
                  </>
                )}

                {children}
              </>
            )}
          </div>
          {/* <DragActiveModal open={isDragActive}/> */}
        </Fragment>
      )}
    </DropZone>
  ) : _supervision ? (
    <DropZone onDrop={onDrop} onChange={onChange} maxSize={maxSize}>
      {({ getRootProps, isDragActive, getInputProps }) => (
        <Fragment>
          <div
            className={isDragActive ? classes.dropzoneActive : classes.dropzone}
            {...getRootProps()}
          >
            {isDragActive && changeBackground ? (
              <div
                style={{
                  height: "100vh",
                  width: "100%",
                  background: "rgba(0, 0, 0, 0)",
                  opacity: "0.8",
                  textAlign: "center",
                  marginTop: "25%",
                }}
              >
                Solte seus arquivos aqui....
              </div>
            ) : (
              <>
                {openWindowToSelectFiles && <input {...getInputProps()} />}
                {children}
              </>
            )}
          </div>
          {/* <DragActiveModal open={isDragActive}/> */}
        </Fragment>
      )}
    </DropZone>
  ) : (
    <div className={classes.dropzone}>{children}</div>
  );
}
