// React
import * as React from 'react';


// Design
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { blue } from '@mui/material/colors';
import FileCopyIcon from '@mui/icons-material/FileCopy';

// Internal

// Third-Party
import PropTypes from 'prop-types';


export default function CloudFilesDialog(props) {
  const { setOpen, open, filesCloudList, handleSendCloudFile } = props;

  const handleClose = () => {
    setOpen(false);
  };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Banco de Arquivos</DialogTitle>
      {
        filesCloudList?.length > 0 ?
        <List sx={{ pt: 0 }}>
          {filesCloudList.map((file) => (
            <ListItem 
              button 
              onClick={() => handleSendCloudFile({fileId: file.id, filename: file.name})} 
              key={file.id}
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <FileCopyIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={file.name} />
            </ListItem>
          ))}
        </List>

        :
        <>
          Nenhum arquivo encontrado...
        </>
      }
    </Dialog>
  );
}

CloudFilesDialog.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  filesCloudList: PropTypes.array.isRequired,
  handleSendCloudFile: PropTypes.func.isRequired,
};

// export default function SimpleDialogDemo() {
//   const [open, setOpen] = React.useState(false);
//   const [selectedValue, setSelectedValue] = React.useState(emails[1]);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = (value) => {
//     setOpen(false);
//     setSelectedValue(value);
//   };

//   return (
//     <div>
//       <Typography variant="subtitle1" component="div">
//         Selected: {selectedValue}
//       </Typography>
//       <br />
//       <Button variant="outlined" onClick={handleClickOpen}>
//         Open simple dialog
//       </Button>
//       <SimpleDialog
//         selectedValue={selectedValue}
//         open={open}
//         onClose={handleClose}
//       />
//     </div>
//   );
// }
