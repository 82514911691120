// React
import React, { useEffect, useState, useRef } from "react";

// Design
import { Typography, Box } from "@mui/material";

// Internal
import API from "../../utils/api";
import createAccess from "../../utils/functions/createAccess";

// Third-party
import { ResponsivePie } from "@nivo/pie";

export default function CountFirstMessageGraph({ user }) {
  const timeToUpdate = 60000 * 1 * 3;
  const [totalCount, _setTotalCount] = useState([]);
  const totalCountRef = useRef([]);
  const setTotalCount = (newValue) => {
    _setTotalCount(newValue);
    totalCountRef.current = newValue;
  };
  const [fromMeCount, setFromMeCount] = useState({
    id: "Ativo",
    label: "fromMe",
    value: 10,
    color: "#ff1744",
  });
  const [notFromMeCount, setNotFromMeCount] = useState({
    id: "Receptivo",
    label: "notFromMe",
    value: 0,
    color: "#009688",
  });

  const data = [fromMeCount, notFromMeCount];
  const handleGetInfo = (totalCount) => {
    let access_click2chat = createAccess(user);
    let filterLess = new Date();
    let filter = `${filterLess.getFullYear()}-${
      String(filterLess.getMonth() + 1).length === 1 ? "0" : ""
    }${filterLess.getMonth() + 1}-${
      String(filterLess.getDate()).length === 1 ? "0" : ""
    }${filterLess.getDate()}`;

    API.get
      .firstMessageConsolidation(access_click2chat, filter)
      .then((response) => {
        if (response.status === 200) {
          let _fromMeCount = 0;
          let _notFromMeCount = 0;
          let _totalCount = 0;
          let temp = response.data.info.list_chats;
          _fromMeCount += temp?.["true"] ?? 0;
          _notFromMeCount += temp?.["false"] ?? 0;
          _totalCount += (temp?.["false"] ?? 0) + (temp?.["true"] ?? 0);
          if (_totalCount !== totalCountRef.current) {
            // setLabels(Object.keys(response.data.info.list_messages));
            setTotalCount(_totalCount);
            setFromMeCount({ ...fromMeCount, value: _fromMeCount });
            setNotFromMeCount({ ...notFromMeCount, value: _notFromMeCount });
          }
        } else {
          //   setChatsListRequest([])
        }
      });
  };

  useEffect(() => {
    handleGetInfo(totalCount);
    const interval = setInterval(() => {
      handleGetInfo(totalCountRef.current);
    }, timeToUpdate);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      display={"flex"}
      height={"450px"}
      width={"450px"}
      flexDirection={"column"}
    >
      <Typography variant={"h6"} textAlign={"center"}>
        Primeira mensagem
      </Typography>
      <ResponsivePie
        data={data}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
        colors={{ datum: "data.color" }}
        margin={{ top: 40, right: 80, bottom: 80, left: 100 }}
      />
    </Box>
  );
}
