// React
import React, { useEffect, useState } from 'react';

// Design
import {
  Box,
  //  Grid
} from '@mui/material';

// Internal
import { appUser } from '../../../../../../store/app/slice';
import createAccess from '../../../../../../utils/functions/createAccess';
import getQueryDateString from '../../../../../../utils/functions/getQueryDateString';
import API from '../../../../../../utils/api';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';

// Third-party
import { useSelector } from 'react-redux';

const columns = [
  { field: 'id', headerName: 'ID', width: 150, sortable: false },
  { field: 'timestamp', headerName: 'Horário', width: 250, sortable: true },
  {
    field: 'phone_number',
    headerName: 'Telefone',
    width: 150,
  },
  {
    field: 'type',
    headerName: 'Tipo',
    width: 150,
    editable: false,
    sortable: false,
  },
  {
    field: 'username',
    headerName: 'Usuário',
    width: 300,
    editable: false,
    sortable: false,
  },
  {
    field: 'team',
    headerName: 'Time',
    width: 300,
    editable: false,
    sortable: true,
  },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function WaitingInLineLogs({ props }) {
  // startDate, endDate, search, setSearch,
  // const user = useSelector(appUser);
  // eslint-disable-next-line

  const [statusList, setStatusList] = useState([]);
  const user = useSelector(appUser);

  const handleGetInfo = (startDate, endDate) => {
    let access_click2chat = createAccess(user);
    let startDateLess = getQueryDateString(new Date(startDate), 1, 'start');
    let endDateLess = getQueryDateString(new Date(endDate), 1, 'end');

    let filter = JSON.stringify({ startDateLess, endDateLess });
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
      timeZone: 'America/Sao_Paulo',
    };

    API.get
      .waitingInLineLogsSupervision(access_click2chat, filter)
      .then((response) => {
        // console.log(response)
        if (response.status === 200) {
          let users = response?.data?.users ?? [];
          let teams = response?.data?.teams ?? {};
          teams = Object.entries(teams).map(([key, value]) => {
            return { _id: key, name: value.name };
          });
          console.log({ teams });

          users.push({ _id: 'system', username: 'Sistema' });
          // console.log({users})
          let _statusList = response.data.info.map((el, count) => {
            let user =
              users.find(
                (user) => String(user._id) === String(el.operator_id)
              ) ?? {};
            let allStatus = el;
            let team =
              teams.find((team) => team._id === el.team_id)?.name ?? 'Todos';
            el.id = el._id;
            el.type = el?.type === 'in' ? 'Entrada' : 'Saída';
            el.phone_number = el?.phone_number ?? '';
            // el.status = STATUS[el.status];
            el.username = user?.username;
            el.team = team;
            // el.worker = user?.worker ? WORKER[user?.worker] : "";
            el.timestamp = Intl.DateTimeFormat('pt-BR', options).format(
              new Date(el.timestamp)
            );
            return allStatus;
          });
          setStatusList(_statusList);
        } else {
          setStatusList([]);
        }
      })
      .finally(() => {
        // setOperatorEditDialogShow(false);
        props?.setSearch(false);
      });
  };

  useEffect(() => {
    if (props?.startDate && props?.endDate && props?.search) {
      handleGetInfo(props?.startDate, props?.endDate);
    }
    // eslint-disable-next-line
  }, [props?.search]);
  return (
    <Box
      sx={{
        width: 'inherit',
        height: 'calc(inherit / 3)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          overflowY: 'auto',
          width: '100%',
          height: 'calc(100%)',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <div style={{ height: 850, width: '100%' }}>
          <DataGrid
            rows={statusList}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[30]}
            // checkboxSelection
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </div>
      </Box>
    </Box>
  );
}
