// React
import React, { useEffect, useState } from "react";

// Design
import Dialog from "@mui/material/Dialog";
import Avatar from "@mui/material/Avatar";
import DialogTitle from "@mui/material/DialogTitle";
import {
  TextField,
  FormControl,
  Typography,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  InputLabel,
  Input,
  InputAdornment,
  DialogContent,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import NotesIcon from "@mui/icons-material/Notes";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CloseIcon from "@mui/icons-material/Close";
import { green, red } from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";
import "react-phone-input-2/lib/style.css";
import AddIcon from "@mui/icons-material/Add";

// Internal
import EmojiDialog from "../Interactions/emojiDialog";
import Dropzone from "../Surfaces/dropzone";
import API from "../../utils/api";
import createAccess from "../../utils/functions/createAccess";
import { appUser } from "../../store/app/slice";
import { setNotificationBar } from "../../store/app/slice";

// Third-party
import PhoneInput from "react-phone-input-2";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: "1rem",
    marginRight: "5rem",
    width: 220,
  },
}));

function DateAndTimePickers({ currentDate, handleChangeDate }) {
  const classes = useStyles();

  return (
    <form className={classes.container} noValidate>
      <TextField
        id="datetime-local"
        // label="Agendar mensagem"
        type="datetime-local"
        // defaultValue={currentDate}
        value={currentDate}
        onChange={(e) => handleChangeDate(e)}
        className={classes.textField}
        // InputLabelProps={{
        //     shrink: true,
        // }}
      />
    </form>
  );
}

function FilesList(props) {
  const { selectedValue, removeFileFromUpload, loadingSending } = props;

  return (
    <List sx={{ pt: 0 }}>
      {selectedValue &&
        selectedValue.acceptedFiles.length > 0 &&
        selectedValue.acceptedFiles.map((f, index) => (
          <ListItem key={`${index}-${f.name || f.path}`}>
            <Tooltip title="Tipo de arquivo aceito">
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: green[100], color: green[600] }}>
                  <CheckIcon />
                </Avatar>
              </ListItemAvatar>
            </Tooltip>
            <ListItemText primary={f.name || f.path} />
            {f.status && f.status === "loading" && (
              <Tooltip title="Enviando arquivo">
                <CircularProgress />
              </Tooltip>
            )}
            {f.status && f.status === "done" && (
              <Tooltip title="Arquivo enviado">
                <CheckIcon />
              </Tooltip>
            )}
            {!f.status && !loadingSending && (
              <Tooltip title="Excluir arquivo">
                <IconButton
                  onClick={() => removeFileFromUpload(index, "acceptedFiles")}
                >
                  <CloseIcon color="error" />
                </IconButton>
              </Tooltip>
            )}
          </ListItem>
        ))}
      {selectedValue &&
        selectedValue.rejectedFiles.length > 0 &&
        selectedValue.rejectedFiles.map((f, index) => (
          <ListItem key={`${index}-${f.file.name || f.file.path}`}>
            <Tooltip title="Tipo de arquivo não aceito">
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: red[100], color: red[600] }}>
                  <CloseIcon />
                </Avatar>
              </ListItemAvatar>
            </Tooltip>
            <ListItemText primary={f.file.name || f.file.path} />
            <Tooltip title="Excluir arquivo">
              <IconButton
                onClick={() => removeFileFromUpload(index, "rejectedFiles")}
              >
                <CloseIcon color="error" />
              </IconButton>
            </Tooltip>
          </ListItem>
        ))}
    </List>
  );
}

FilesList.propTypes = {
  selectedValue: PropTypes.object,
  removeFileFromUpload: PropTypes.func.isRequired,
  loadingSending: PropTypes.bool,
};

export default function ReminderDialog({
  open,
  setOpen,
  phone_number,
  hourSelectedReminder,
  setHourSelectedReminder,
  chat_id,
  notes,
  acceptedtFileTypes,
  filesToUpload,
  onDropFile,
  onChangeFile,
  removeFileFromUpload,
  handleSendFile,
  setSchedule,
}) {
  const dispatch = useDispatch();
  const user = useSelector(appUser);
  const [phoneNumber, setPhoneNumber] = useState(phone_number || "55");
  const [ddi, setDDI] = useState("55");
  const [emojiDialogShow, setEmojiDialogShow] = useState(false);
  const [textToSend, setTextToSend] = useState("");
  const [textToSendFlag, setTextToSendFlag] = useState(false);
  const [currentDate, setCurrentDate] = useState(hourSelectedReminder);
  const [anchorGetNotes, setAnchorGetNotes] = useState(null);
  // eslint-disable-next-line
  const [dateToSend, setDateToSend] = useState(null);
  // eslint-disable-next-line
  const [loadingSending, setLoadingSending] = useState(false);

  const [name, setName] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [email, setEmail] = useState("");

  const handleCloseGetNotes = () => {
    setAnchorGetNotes(null);
  };

  const handleSelectNumber = (phone, countryData) => {
    if (!phone_number) {
      setPhoneNumber(phone);
      setDDI(countryData?.dialCode);
    } else {
      setPhoneNumber(phone_number);
      setDDI(countryData?.dialCode);
    }
  };

  const handleSendNote = (e, note) => {
    setAnchorGetNotes(null);
    setTextToSend(note.text);
  };

  const handleScheduleInteraction = async (_chat_id) => {
    setLoadingSending(true);
    setTextToSendFlag(false);
    let access_click2chat = createAccess(user);
    let hasFile = false;
    let filesSentIds = [];
    if (
      filesToUpload &&
      Array.isArray(filesToUpload.acceptedFiles) &&
      filesToUpload.acceptedFiles.length > 0
    ) {
      hasFile = true;
      for (let i = 0; i <= filesToUpload.acceptedFiles.length - 1; i++) {
        let _file = await handleSendFile({
          chat_id: _chat_id,
          resend: false,
          fileIndex: i,
          sendMessage: false,
        });
        filesSentIds.push({
          id: _file.id,
          filename: _file.filename,
        });
      }
    }
    API.post
      .schedule(
        access_click2chat,
        dateToSend,
        phoneNumber,
        textToSend,
        _chat_id,
        hasFile,
        filesSentIds,
        name,
        ddi,
        cpfCnpj,
        email
      )
      .then((response) => {
        if (response?.ok && response?.data?.info && response?.status === 201) {
          setSchedule(response.data.info);
        } else if (response?.status === 403) {
          dispatch(
            setNotificationBar({
              status: true,
              message: response.data.msg,
              type: "warning",
            })
          );
        } else {
          let err = new Error("not possible");
          err.response = response;
          throw err;
        }
      })
      .catch((err) => {
        dispatch(
          setNotificationBar({
            status: true,
            message: err?.response?.data?.msg ?? "",
            type: "error",
          })
        );
      })
      .finally(() => {
        setOpen(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeDate = (e) => {
    setCurrentDate(e.target.value);
    setHourSelectedReminder(e.target.value);
  };

  const handleEnterPress = (e) => {
    if (e.which === 13 && e.shiftKey) {
    } else if (e.which === 13 && textToSendFlag) {
      e.preventDefault(); //Stops enter from creating a new line
      handleScheduleInteraction(chat_id);
    }
  };

  useEffect(() => {
    let _currentDate = new Date(currentDate);
    setDateToSend(_currentDate.getTime());

    // eslint-disable-next-line
  }, [currentDate]);

  useEffect(() => {
    let reg = /^\d{10,}$/;
    if (
      typeof phoneNumber === "string" &&
      !Array.from(textToSend).every((el) => el === "\n") &&
      reg.test(phoneNumber)
    ) {
      setTextToSendFlag(true);
    } else {
      setTextToSendFlag(false);
    }
    // eslint-disable-next-line
  }, [textToSend, phoneNumber]);

  useEffect(() => {
    if (!open) {
      setTextToSend("");
      setPhoneNumber("");
    } else {
      // getAcceptedFiles();
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">
          Agendar Mensagem
          <Tooltip title="Fechar">
            <IconButton
              onClick={handleClose}
              style={{
                right: "-21rem",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          {phone_number ? (
            <Typography>{phoneNumber}</Typography>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDiretcion: "column",
              }}
            >
              <PhoneInput
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                  disabled: phone_number || false,
                }}
                onKeyDown={(e) => handleEnterPress(e)}
                country={"br"}
                value={phoneNumber}
                onChange={(phone, country) =>
                  handleSelectNumber(phone, country)
                }
                preferredCountries={["br", "pt"]}
              />
            </Box>
          )}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nome"
            type="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
          <TextField
            margin="dense"
            id="cpfCnpj"
            label="CPF/CNPJ"
            type="name"
            value={cpfCnpj}
            onChange={(e) => setCpfCnpj(e.target.value)}
            fullWidth
          />
          <TextField
            margin="dense"
            id="email"
            label="E-mail"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              direction: "column",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  height: "5rem",
                  width: "15rem",
                  // overflowX: 'hidden',
                  overflowY: "hidden",
                  position: "absolute",
                }}
              >
                <DateAndTimePickers
                  handleChangeDate={handleChangeDate}
                  currentDate={currentDate}
                />
              </Box>
              <Box
                sx={{
                  height: "5rem",
                  width: "20rem",
                  overflowX: "hidden",
                  overflowY: "scroll",
                  borderStyle: "dashed",
                  position: "absolute",
                  marginLeft: "14.5rem",
                }}
              >
                <Dropzone
                  onDrop={onDropFile}
                  onChange={onChangeFile}
                  acceptedtFileTypes={acceptedtFileTypes}
                  // openWindowToSelectFiles={true}
                >
                  <>
                    <FilesList
                      selectedValue={filesToUpload}
                      removeFileFromUpload={removeFileFromUpload}
                      loadingSending={loadingSending}
                    />
                    <Dropzone
                      onDrop={onDropFile}
                      onChange={onChangeFile}
                      acceptedtFileTypes={acceptedtFileTypes}
                      openWindowToSelectFiles={true}
                    >
                      <Tooltip title="Adicionar Arquivos" placement="left">
                        <IconButton>
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    </Dropzone>
                  </>
                </Dropzone>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                direction: "column",
                position: "relative",
                height: "10rem",
                width: "100%",
                background: "#BDBDBD",
                marginTop: "5rem",
              }}
            >
              <Box
                sx={{
                  width: "3rem",
                  background: "#BDBDBD",
                }}
              >
                <Tooltip title="Notas" placement="left">
                  <IconButton
                    onClick={(e) => setAnchorGetNotes(e.currentTarget)}
                  >
                    <NotesIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorGetNotes}
                  keepMounted
                  open={Boolean(anchorGetNotes)}
                  onClose={handleCloseGetNotes}
                  style={{ zIndex: 999999999 }}
                >
                  {notes &&
                    notes.map((note, index) => {
                      return (
                        <MenuItem
                          onClick={(e) => handleSendNote(e, note)}
                          key={`note-${index}`}
                        >
                          {note.title}
                        </MenuItem>
                      );
                    })}
                </Menu>
                <Tooltip title="Emoji" placement="left">
                  <IconButton
                    onClick={() => setEmojiDialogShow(!emojiDialogShow)}
                  >
                    <EmojiEmotionsIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box
                sx={{
                  width: "97vw",
                  background: "#BDBDBD",
                  paddingTop: "1rem",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel htmlFor="input-with-icon-adornment">
                    Enviar mensagem
                  </InputLabel>
                  <Input
                    required
                    autoFocus
                    multiline
                    rows={6}
                    // row={2}
                    id="input-with-icon-adornment-search"
                    endAdornment={
                      <InputAdornment position="end">
                        <Tooltip title="Enviar Mensagem" placement="top-end">
                          <IconButton
                            onClick={() => handleScheduleInteraction(chat_id)}
                            disabled={!textToSendFlag}
                          >
                            <ScheduleIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    }
                    value={textToSend}
                    onChange={(e) => setTextToSend(e.target.value)}
                    onKeyPress={(e) => handleEnterPress(e)}
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      {emojiDialogShow && (
        <EmojiDialog
          setTextToSend={setTextToSend}
          textToSend={textToSend}
          open={emojiDialogShow}
          setOpen={setEmojiDialogShow}
        />
      )}
    </div>
  );
}
