// React
import React, { useEffect, useState } from 'react';

// Design
import { Typography, Box } from '@mui/material';

// Internal
import API from '../../../../../../../utils/api';
import createAccess from '../../../../../../../utils/functions/createAccess';
import getQueryDateString from '../../../../../../../utils/functions/getQueryDateString';


// Third-party
import { ResponsivePie } from '@nivo/pie';


export default function CountFirstMessageGraph({
  user
  , startDate
  , endDate
  , search
  , setSearch
}) {
  // eslint-disable-next-line
  const [totalCount, setTotalCount] = useState([]);
  const [fromMeCount, setFromMeCount] = useState({
    id: 'Ativo',
    label: 'fromMe',
    value: 10,
    color: '#ff1744'
  });
  const [notFromMeCount, setNotFromMeCount] = useState({
    id: 'Receptivo',
    label: 'notFromMe',
    value: 0,
    color: '#009688'
  });

  const data = [fromMeCount, notFromMeCount];
  const handleGetInfo = (startDate, endDate) => {

    let access_click2chat = createAccess(user);
    let startDateLess = getQueryDateString(new Date(startDate), 1, 'start');
    let endDateLess = getQueryDateString(new Date(endDate), 1, 'end');
    let filter = JSON.stringify({ startDateLess, endDateLess });

    API.get.firstMessageConsolidation(access_click2chat, filter)
      .then((response) => {
        if (response.status === 200) {
          let _fromMeCount = 0;
          let _notFromMeCount = 0;
          let _totalCount = 0;
          let temp = response.data.info.list_chats;
          _fromMeCount += temp?.['true'] ?? 0;
          _notFromMeCount += temp?.['false'] ?? 0;
          _totalCount += (temp?.['false'] ?? 0) + (temp?.['true'] ?? 0);
          // if (_totalCount !== totalCountRef.current) {
          // setLabels(Object.keys(response.data.info.list_messages));
          setTotalCount(_totalCount);
          setFromMeCount({ ...fromMeCount, value: _fromMeCount });
          setNotFromMeCount({ ...notFromMeCount, value: _notFromMeCount });
          // }
        }
        else {
          //   setChatsListRequest([])
        }
      })
  }


  useEffect(() => {
    if (startDate && endDate && search) {
      handleGetInfo(startDate, endDate)
    }
    // eslint-disable-next-line
  }, [search]);


  return (
    <Box
      display={'flex'}
      height={'400px'}
      width={'450px'}
      flexDirection={'column'}
    >
      <Typography variant={'h6'} textAlign={'center'}>
        Primeira mensagem
      </Typography>
      <ResponsivePie
        data={data}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              2
            ]
          ]
        }}
        colors={{ datum: 'data.color' }}
        margin={{ top: 40, right: 80, bottom: 80, left: 100 }}
      />
    </Box>
  );
}