// React
import React, { useEffect, useState } from "react";

// Design
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import LogoutIcon from "@mui/icons-material/Logout";
import ChatIcon from "@mui/icons-material/Chat";

// Internal
import PullFromLineDialog from "./pullFromLineDialog";
import BlockOperatorDialog from "./blockOperatorDialog";
import UnblockOperatorDialog from "./unblockOperatorDialog";
import PutInLineDialog from "./putInLineDialog";
import ScheduleDialog from "./scheduleDialog";
import ClockPunchDialog from "./clockPunchDialog";
import createAccess from "../../utils/functions/createAccess";
import API from "../../utils/api";
import InternalChatOverviewDialog from "./internalChatOverviewDialog";

// Third-party

export default function OperatorEditDialog({
  open,
  setOpen,
  username,
  operatorId,
  inLine,
  onLine,
  blocked,
  handlePullFromLine,
  handlePutInLine,
  handleBlockOperator,
  handleUnblockOperator,
  schedule,
  user,
  selectedOperatorTeam,
}) {
  const [pullFromLineDialogShow, setPullFromLineDialogShow] = useState(false);
  const [putInLineDialogShow, setPutInLineDialogShow] = useState(false);
  const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
  const [openClockPunchDialog, setOpenClockPunchDialog] = useState(false);
  const [openBlockOperatorDialog, setOpenBlockOperatorDialog] = useState(false);
  const [openUnblockOperatorDialog, setOpenUnblockOperatorDialog] =
    useState(false);
  const [teamsList, setTeamsList] = useState([]);
  const [teamId, setTeamId] = useState("");

  const [internalChatDialogOpen, setInternalChatDialogOpen] = useState(false);
  const [usersInternalCommunication, setUsersInternalCommunication] = useState(
    []
  );

  // console.log({ user });

  const handleInternalCommunicationOpen = () => {
    let access_click2chat = createAccess(user);
    API.get
      .internalCommunicationUsersOverview(access_click2chat, operatorId)
      .then((response) => {
        setUsersInternalCommunication(response?.data?.users ?? []);
      })
      .catch(console.log)
      .finally(() => {
        setInternalChatDialogOpen(true);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGetTeams = () => {
    let access_click2chat = createAccess(user);
    API.get
      .teams(access_click2chat)
      .then((response) => {
        if (response.status === 200) {
          let teams = response?.data?.info ?? null;
          if (teams) {
            teams = Object.entries(teams).map(([key, value]) => {
              return {
                id: key,
                name: value.name === "default" ? "Padrão" : value.name,
                active: value.active,
              };
            });
            teams = teams.filter((el) => el.active);
            setTeamsList(teams);
          }
        } else {
          // setCurrentList([])
        }
      })
      .finally(() => {});
  };

  const handleChangeTeam = (event, _operatorId, _teamsList) => {
    let access_click2chat = createAccess(user);
    let teamName = _teamsList.find((el) => el.id === event.target.value).name;
    teamName = teamName === "Padrão" ? "default" : teamName;
    let teamId = event.target.value;
    teamId = teamId === "Padrão" ? "default" : teamId;
    API.put
      .teamUser(access_click2chat, _operatorId, teamId, teamName)
      .then((response) => {
        if (response.status === 201) {
          setTeamId(teamId);
        }
      })
      .finally(() => {});
  };

  const handleLougout = () => {
    let access_click2chat = createAccess(user);
    API.put
      .logoutOperator(access_click2chat, operatorId)
      .then((response) => {
        if (response.status === 201) {
        }
      })
      .finally(() => {
        setOpen(false);
      });
  };

  useEffect(() => {
    if (open) {
      handleGetTeams();
    } else {
      setTeamsList([]);
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (selectedOperatorTeam?.id) {
      setTeamId(selectedOperatorTeam?.id);
    }
  }, [selectedOperatorTeam]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            sx={{
              display: "flex",
              height: "2rem",
              width: "35rem",
              // alignItems: 'flex-start',
              justifyContent: "flex-start",
              margin: "0 0 2rem 0",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifySelf: "flex-start",
                justifyContent: "space-between",
              }}
            >
              Informações do Operador
              <Box display="flex" sx={{ width: 260 }}>
                <InputLabel id="demo-simple-select-label">Time</InputLabel>
                <Select
                  value={teamId}
                  sx={{ width: 220 }}
                  onChange={(event) =>
                    handleChangeTeam(event, operatorId, teamsList)
                  }
                >
                  {teamsList.map((el) => {
                    return (
                      <MenuItem key={el.id} value={el.id}>
                        {el.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifySelf: "flex-end",
              }}
            >
              <Stack direction="row">
                {onLine && (
                  <Tooltip title="Deslogar usuário" placement="top">
                    <span>
                      <IconButton
                        size="medium"
                        onClick={() => handleLougout()}
                        edge="start"
                      >
                        <LogoutIcon fontSize="inherit" color="error" />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                <Tooltip title="Agendamentos" placement="top">
                  <span>
                    <IconButton
                      size="medium"
                      onClick={() => setOpenScheduleDialog(true)}
                      // edge="start"
                      disabled={schedule.length === 0}
                    >
                      <EventIcon
                        fontSize="inherit"
                        color={schedule.length === 0 ? "disabled" : "primary"}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Conversas Internas" placement="top">
                  <span>
                    <IconButton
                      size="medium"
                      onClick={() => handleInternalCommunicationOpen()}
                      // edge="start"
                    >
                      <ChatIcon fontSize="inherit" color="primary" />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Horários" placement="top">
                  <span>
                    <IconButton
                      size="medium"
                      onClick={() => setOpenClockPunchDialog(true)}
                      edge="start"
                    >
                      <AccessTimeIcon fontSize="inherit" color="primary" />
                    </IconButton>
                  </span>
                </Tooltip>
                {!blocked ? (
                  <Tooltip title="Desbloquear usuário" placement="top">
                    <span>
                      <IconButton
                        size="medium"
                        onClick={() => setOpenUnblockOperatorDialog(true)}
                        edge="start"
                      >
                        <CheckCircleOutlineIcon
                          fontSize="inherit"
                          color="success"
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Bloquear usuário" placement="top">
                    <span>
                      <IconButton
                        size="medium"
                        onClick={() => setOpenBlockOperatorDialog(true)}
                        edge="start"
                      >
                        <DoDisturbIcon fontSize="inherit" color="error" />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </Stack>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            marginTop: "1rem",
          }}
        >
          <Box display="flex">{username}</Box>
        </DialogContent>
        <DialogActions>
          {onLine && inLine ? (
            <Button
              onClick={() => setPullFromLineDialogShow(true)}
              color="secondary"
              autoFocus
            >
              Retirar da Fila
            </Button>
          ) : onLine && !inLine ? (
            <Button
              onClick={() => setPutInLineDialogShow(true)}
              color="primary"
              autoFocus
            >
              Colocar na Fila
            </Button>
          ) : (
            ""
          )}
        </DialogActions>
      </Dialog>
      {pullFromLineDialogShow && operatorId && username && (
        <PullFromLineDialog
          open={pullFromLineDialogShow}
          setOpen={setPullFromLineDialogShow}
          username={username}
          handlePullFromLine={handlePullFromLine}
        />
      )}
      {putInLineDialogShow && operatorId && username && (
        <PutInLineDialog
          open={putInLineDialogShow}
          setOpen={setPutInLineDialogShow}
          username={username}
          handlePutInLine={handlePutInLine}
        />
      )}
      {openScheduleDialog && operatorId && username && (
        <ScheduleDialog
          open={openScheduleDialog}
          setOpen={setOpenScheduleDialog}
          username={username}
          operatorId={operatorId}
          schedule={schedule}
        />
      )}
      {openClockPunchDialog && operatorId && username && (
        <ClockPunchDialog
          open={openClockPunchDialog}
          setOpen={setOpenClockPunchDialog}
          username={username}
          operatorId={operatorId}
        />
      )}
      {openBlockOperatorDialog && operatorId && username && (
        <BlockOperatorDialog
          open={openBlockOperatorDialog}
          setOpen={setOpenBlockOperatorDialog}
          username={username}
          operatorId={operatorId}
          handleBlockOperator={handleBlockOperator}
        />
      )}
      {openUnblockOperatorDialog && operatorId && username && (
        <UnblockOperatorDialog
          open={openUnblockOperatorDialog}
          setOpen={setOpenUnblockOperatorDialog}
          username={username}
          operatorId={operatorId}
          handleUnblockOperator={handleUnblockOperator}
        />
      )}
      {internalChatDialogOpen && user && (
        <InternalChatOverviewDialog
          open={internalChatDialogOpen}
          setOpen={setInternalChatDialogOpen}
          usersInternalCommunication={usersInternalCommunication}
          username={username}
          operatorId={operatorId}
        />
      )}
    </div>
  );
}
