// React
import React from "react";

// Design
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tipography from "@mui/material/Typography";

// Internal

// Third-party

export default function InfoContactDialog({ open, setOpen, info }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Informações do Cliente</DialogTitle>
        <DialogContent>
          <Tipography>
            <b>Nome no whatsapp:</b> {info?.name ?? ""}
          </Tipography>
          <br />
          <Tipography>
            <b>Nome:</b> {info?.name_by_company ?? "Não cadastrado"}
          </Tipography>
          <br />
          <Tipography>
            <b>CPF/CNPJ:</b> {info?.cpf_cnpj ?? "Não cadastrado"}
          </Tipography>
          <br />
          <Tipography>
            <b>Email:</b> {info?.email ?? "Não cadastrado"}
          </Tipography>
          <br />
          <img
            src={info?.profile_pic_url}
            alt="profile_pic"
            style={{ width: "350px", height: "350px" }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
