// React
import React from 'react';

// Design
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Card, CardMedia } from '@mui/material';

// Internal


// Third-party

export default function ProfilePicOpenDialog({ open, setOpen, profilePicURL }) {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Foto do Perfil</DialogTitle>
        <DialogContent>
          <Card>
            <CardMedia
              component="img"
              height="320"
              image={profilePicURL}
              alt=""
            />
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  );
}