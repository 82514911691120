// React
import React from 'react';

// Design
import { TextField } from '@mui/material';
// Internal

// Third-party


export default function DateAndTimePickers({currentDate, handleChangeDate}) {

    return (
        <form noValidate>
            <TextField
                id="datetime-local"
                type="datetime-local"
                value={currentDate}
                onChange={(e) => handleChangeDate(e.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </form>
    );
}